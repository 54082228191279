import axios from '../../plugins/axios';

const state = {
    onboarding: null,
    errors: [],
};

const actions = {
    async fetch({ commit }) {
        commit('setOnboarding', null);
        try {
            const response = await axios.get(`/api/onboarding`);
            commit('setOnboarding', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async store({ commit }, onboarding) {
        commit('setErrors', []);
        try {
            await axios.post(`/api/onboarding`, onboarding);
        } catch (error) {
            if (error.response.status === 422) {
                commit('setErrors', error.response.data.errors);
            } else {
                console.log(error);
            }
            return false;
        }

        return true;
    },
};

const mutations = {
    setOnboarding(state, onboarding) {
        state.onboarding = onboarding;
    },
    setErrors(state, errors) {
        state.errors = errors;
    },
};

const getters = {
    onboarding: (state) => {
        return state.onboarding;
    },
    isOnboardingActive: (state) => {
        return state.onboarding.attributes?.is_active ?? true;
    },
    errors: (state) => {
        return state.errors;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
