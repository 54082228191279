<template>
<div>
  <app-breadcrumbs :breadcrumbs="breadcrumbs">
    <app-title-crumb :title="`${$t('welcome')}, ${userName}!` " :instructions="$t('onboarding_msg')">
    </app-title-crumb>
  </app-breadcrumbs>
  <v-container fluid>
    <v-skeleton-loader class="mx-auto" type="card-heading, card, actions" v-if="onboarding === null"></v-skeleton-loader>

    <v-stepper v-model="step" vertical v-else>
      <v-stepper-step :complete="step > 1" step="1">
        {{ $t('linkbridge_profile') }}
        <small>{{ $t('linkbridge_profile_msg') }}</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card flat class="mt-6 mb-12">
          <v-row>
            <v-col class="d-flex" cols="12" sm="6">
              <v-select :items="interests" :label="$t('main_interest')" v-on:change="onStore" v-model="onboarding.attributes.main_interest" :error-messages="errors['onboarding.attributes.main_interest']"></v-select>
            </v-col>
          </v-row>
        </v-card>
        <v-btn color="primary" @click="step = 2" :disabled="isProcessing">
          {{ $t('next') }}
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="step > 2" step="2">

        {{ $t('wallet') | capitalize }}
        <small>{{ $t('wallet_msg')}}</small>
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-card flat class="mt-6 mb-12">
          <v-row>
            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field v-model="onboarding.attributes.ethereum_address" counter="42" label="Ethereum address" :error-messages="errors['onboarding.attributes.ethereum_address']"></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-btn text @click="step = 1" class="mr-5" :disabled="isProcessing">
          {{ $t('previous') }}
        </v-btn>
        <v-btn color="primary" @click="onStore" :loading="isProcessing">
          {{ $t('finish') }}
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';
import {
  validationMixin
} from "vuelidate";
import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import {
  vueFrontErrors,
  vueRulesMessages
} from "../../plugins/vuelidate";

import AppBreadcrumbs from '@/components/organisms/Breadcrumbs';
import AppTitleCrumb from '@/components/molecules/Breadcrumbs/Title';

export default {
  components: {
    AppBreadcrumbs,
    AppTitleCrumb,
  },
  mixins: [validationMixin],
  validations: {
    onboarding: {
      attributes: {
        ethereum_address: {
          required: required,
          minLength: minLength(42),
          maxLength: maxLength(42),
        },
        main_interest: {
          required: required,
        }
      },
    }
  },
  data() {
    return {
      step: 1,
      errors: {
        'onboarding.attributes.ethereum_address': [],
        'onboarding.attributes.main_interest': [],
      },
      errorsApi: [],
      rules: {
        ...vueRulesMessages,
      },
      isValidationRequired: false,
      interests: [{
          value: 1,
          text: 'Soy Emprendedor, busco capital para mi proyecto',
        },
        {
          value: 2,
          text: 'Soy Inversionista, busco proyectos para invertir',
        },
        {
          value: 3,
          text: 'Soy Profesionista, busco oportunidades profesionales',
        },
      ],
      breadcrumbs: [{
          text: this.$t('home'),
          disabled: false,
          exact: true,
          to: {
            name: 'home',
          },
        },
        {
          text: '',
          disabled: true,
        },
      ],
      isProcessing: false,
    };
  },
  mounted() {
    this.prepareComponent();
  },
  watch: {
    onboarding: {
      handler() {
        this.$v.onboarding.$touch();
        this.isValidationRequired = true;
      },
      deep: true,
    },
    apiErrors: {
      handler() {
        this.isValidationRequired = true;
      },
      deep: true,
    },
    isValidationRequired(newValue) {
      if (newValue === false) {
        return;
      }

      this.isValidationRequired = false;
      this.vueFrontErrors();
    }
  },
  computed: {
    ...mapGetters({
      placeholders: "placeholders",
    }),
    ...mapGetters('onboarding', {
      onboarding: 'onboarding',
      apiErrors: 'errors',
    }),
    ...mapGetters('profile', {
      profile: 'profile',
    }),
    userName() {
      try {
        return this.profile.attributes.name;
      } catch (e) {
        return '';
      }
    },
  },
  methods: {
    ...mapActions('onboarding', {
      fetch: 'fetch',
      store: 'store',
    }),
    vueFrontErrors: vueFrontErrors,
    prepareComponent() {
      this.fetch();
    },
    async onStore() {
      this.$v.onboarding.$touch();
      let invalid = this.$v.onboarding.$invalid;
      this.isValidationRequired = true;

      if (invalid) {
        return;
      }

      this.isProcessing = true;

      await this.store({
        data: {
          type: 'onboarding',
          attributes: this.onboarding.attributes,
        },
      });

      this.isProcessing = false;

      if(this.apiErrors.length === 0 && this.step === 2) {
        this.$router.push({
          name: 'dashboard',
        });
      }
    },
  }
};
</script>
