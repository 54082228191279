<template>
<v-row>
  <v-col cols="12" sm="6" md="6" class="pa-0 d-none d-md-block">
    <v-img style="width: 100%" :src="placeholders.login_bg"></v-img>
  </v-col>
  <v-col cols="12" sm="12" md="6" class="mt-10">
    <div class="pa-10">
      <v-row>
        <v-col>
          <v-img :src="placeholders.logo_login" width="60"></v-img>
          <h1 class="title">
            {{ $t('login') }}
          </h1>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field v-model="email" required @input="$v.email.$touch()" @blur="$v.email.$touch()" :error-messages="emailErrors" :label="$t('email')"></v-text-field>
        <v-text-field v-model="password" required @input="$v.password.$touch()" @blur="$v.password.$touch()" :error-messages="passwordErrors" type="password" :label="$t('password')" @keyup.enter="onSubmit"></v-text-field>
        <div>
          <v-btn color="accent" :loading="isLoading" @click="onSubmit">
            {{ $t('login') }}
          </v-btn>
          <v-btn text :loading="isLoading" :to="{ name: 'reset.password.show' }" class="my-5 ml-5">
            {{ $t('recover_password') }}
          </v-btn>
        </div>
        <div class="mt-10">
          {{ $t('dont_have_account') }}
          <v-btn text :to="{ name: 'signup.show' }">
            {{ $t('signup') }}
          </v-btn>
        </div>
      </v-form>
    </div>
  </v-col>
</v-row>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters
} from 'vuex';
import {
  validationMixin
} from 'vuelidate';
import {
  required,
  email
} from 'vuelidate/lib/validators';
import {
  apiErrors
} from '../../util.js';

export default {
  mixins: [validationMixin],
  validations: {
    email: {
      required: required,
      email: email,
    },
    password: {
      required: required,
    },
  },
  data: () => ({
    isLoading: false,
    valid: true,
    email: '',
    password: '',
  }),
  methods: {
    ...mapActions('auth', {
      login: 'login',
      fetchUser: 'fetchUser',
    }),
    ...mapActions('onboarding', {
      fetchOnboarding: 'fetch',
    }),
    createErrors(key, rules) {
      const errors = apiErrors(key, this.errors);
      if (!this.$v[key].$dirty && errors.length == 0) return errors;
      for (let rule in rules) {
        !this.$v[key][rule] && errors.push(rules[rule]);
      }
      return errors;
    },
    async onSubmit() {
      this.isLoading = true;

      const isLoginSuccess = await this.login({
        email: this.email,
        password: this.password,
      });

      if (isLoginSuccess) {
        await this.fetchUser();
        await this.onLoginSuccess();
      }

      this.isLoading = false;
    },
    async onLoginSuccess() {
      if (this.isApplicationStashed) {
        this.redirectToPendingApplication();
      } else {
        await this.fetchOnboarding();

        // Disable onboarding for now
        // if (this.isOnboardingActive) {
        //   this.$router.push({
        //     name: 'onboarding',
        //   });
        //   return;
        // } 

        this.$router.push({
          name: 'dashboard',
        });
      }
    },
    redirectToPendingApplication() {
      this.$router.push({
        name: 'jobs.show',
        params: {
          id: this.isApplicationStash,
        },
      });
    }
  },
  computed: {
    ...mapGetters({
      placeholders: 'placeholders',
    }),
    ...mapState('auth', {
      isAuth: 'isAuth',
      errors: 'errors',
    }),
    ...mapGetters('onboarding', {
      isOnboardingActive: 'isOnboardingActive',
    }),
    ...mapGetters('applications', {
      isApplicationStash: 'stash',
      isApplicationStashed: 'isStashed',
    }),
    emailErrors() {
      return this.createErrors('email', {
        required: 'El correo es requerido',
        email: 'Ingresa un correo valido',
      });
    },
    passwordErrors() {
      return this.createErrors('password', {
        required: 'La contraseña es requerida',
      });
    },
  },
};
</script>
