<template>
    <div>
        <span class="float-right">
            <v-dialog
                v-model="value"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <v-card>
                    <v-toolbar>
                        <v-btn icon @click="onClose()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{
                            $t('publication_details')
                        }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                                text
                                @click="onClose()"
                                :disabled="isProcessing"
                            >
                                {{ $t('cancel') }}
                            </v-btn>
                            <v-btn
                                color="accent"
                                @click="onSubmit"
                                :loading="isProcessing"
                                :disabled="!form.project_id"
                            >
                                {{ $t('publish') }}
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-container fluid>
                            <v-row align="center" justify="center">
                                <v-col cols="7">
                                    <v-alert
                                        border="bottom"
                                        colored-border
                                        type="warning"
                                        elevation="2"
                                        v-if="job"
                                    >
                                        {{ $t('vacancy_edition_warning') }}
                                    </v-alert>
                                    <v-card>
                                        <!--Comencemos-->
                                        <v-card-title>
                                            {{ $t('lets_start') }}
                                        </v-card-title>
                                        <v-card-subtitle> </v-card-subtitle>
                                        <v-card-text>
                                            <v-autocomplete
                                                :items="projects"
                                                v-model="form.project_id"
                                                item-text="attributes.name"
                                                :disabled="this.job !== null"
                                                item-value="id"
                                                :error-messages="
                                                    errors['form.project_id']
                                                "
                                                @input="
                                                    $v.form.project_id.$touch()
                                                "
                                                @blur="
                                                    $v.form.project_id.$touch()
                                                "
                                                :label="`${$t(
                                                    'vacancy_company'
                                                )} *`"
                                            ></v-autocomplete>
                                            <v-autocomplete
                                                :items="industries"
                                                v-model="form.industry_id"
                                                item-text="attributes.name"
                                                item-value="id"
                                                :error-messages="
                                                    errors['form.industry_id']
                                                "
                                                @input="
                                                    $v.form.industry_id.$touch()
                                                "
                                                @blur="
                                                    $v.form.industry_id.$touch()
                                                "
                                                :label="`${$t('industry')} *`"
                                            ></v-autocomplete>
                                            <v-text-field
                                                v-model="form.title"
                                                :error-messages="
                                                    errors['form.title']
                                                "
                                                @input="$v.form.title.$touch()"
                                                @blur="$v.form.title.$touch()"
                                                :label="`${$t(
                                                    'vacancy_title'
                                                )} *`"
                                            ></v-text-field>

                                            <v-text-field
                                                v-model="form.location"
                                                :error-messages="
                                                    errors['form.location']
                                                "
                                                @input="
                                                    $v.form.location.$touch()
                                                "
                                                @blur="
                                                    $v.form.location.$touch()
                                                "
                                                :label="`${$t(
                                                    'vacancy_location'
                                                )} *`"
                                            ></v-text-field>
                                        </v-card-text>
                                        <!--/Comencemos-->
                                        <!--Detalles del empleo-->
                                        <v-card-title>
                                            {{ $t('job_details') }}
                                        </v-card-title>
                                        <v-card-subtitle> </v-card-subtitle>
                                        <v-card-text>
                                            <v-select
                                                :items="employment_types"
                                                v-model="form.type_id"
                                                item-text="attributes.description"
                                                item-value="id"
                                                :error-messages="
                                                    errors['form.type_id']
                                                "
                                                @input="
                                                    $v.form.type_id.$touch()
                                                "
                                                @blur="$v.form.type_id.$touch()"
                                                :label="`${$t(
                                                    'vacancy_employment_type_question'
                                                )} *`"
                                            ></v-select>
                                            <v-select
                                                :items="contract_types"
                                                v-model="form.contract_id"
                                                item-text="attributes.name"
                                                item-value="id"
                                                :error-messages="
                                                    errors['form.contract_id']
                                                "
                                                @input="
                                                    $v.form.contract_id.$touch()
                                                "
                                                @blur="
                                                    $v.form.contract_id.$touch()
                                                "
                                                :label="`${$t(
                                                    'vacancy_contract_type_question'
                                                )} *`"
                                            ></v-select>

                                            <v-row v-if="isBySpecificTime">
                                                <v-col cols="6">
                                                    <v-text-field
                                                        value="1"
                                                        v-model="
                                                            form.contract_time
                                                        "
                                                        @input="
                                                            $v.form.contract_time.$touch()
                                                        "
                                                        @blur="
                                                            $v.form.contract_time.$touch()
                                                        "
                                                        type="number"
                                                        :label="`${$t('time')}`"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-select
                                                        value="0"
                                                        :items="
                                                            contracts_periods
                                                        "
                                                        v-model="
                                                            form.contract_period
                                                        "
                                                        @input="
                                                            $v.form.contract_period.$touch()
                                                        "
                                                        @blur="
                                                            $v.form.contract_period.$touch()
                                                        "
                                                        :label="`${$t('type')}`"
                                                    ></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-select
                                                :items="schedule_types"
                                                item-text="attributes.description"
                                                item-value="id"
                                                v-model="form.schedule_types_id"
                                                :error-messages="
                                                    errors[
                                                        'form.schedule_types_id'
                                                    ]
                                                "
                                                @input="
                                                    $v.form.schedule_types_id.$touch()
                                                "
                                                @blur="
                                                    $v.form.schedule_types_id.$touch()
                                                "
                                                :label="`${$t(
                                                    'vacancy_schedule_type_question'
                                                )} *`"
                                                multiple
                                                chips
                                            ></v-select>
                                        </v-card-text>
                                        <!--/Detalles del empleo-->
                                        <!--Salario y beneficios-->
                                        <v-card-title>
                                            {{ $t('salary_benefits') }}
                                        </v-card-title>
                                        <v-card-subtitle>
                                            {{ $t('salary_benefits_subtitle') }}
                                        </v-card-subtitle>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-text-field
                                                        type="number"
                                                        v-model="
                                                            form.salary_starting
                                                        "
                                                        @input="
                                                            $v.form.salary_starting.$touch()
                                                        "
                                                        @blur="
                                                            $v.form.salary_starting.$touch()
                                                        "
                                                        :label="
                                                            $t('salary_staring')
                                                        "
                                                        :prefix="
                                                            $t(
                                                                'currency_prefix'
                                                            )
                                                        "
                                                        :suffix="
                                                            $t(
                                                                'currency_suffix'
                                                            )
                                                        "
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field
                                                        type="number"
                                                        v-model="
                                                            form.salary_final
                                                        "
                                                        @input="
                                                            $v.form.salary_final.$touch()
                                                        "
                                                        @blur="
                                                            $v.form.salary_final.$touch()
                                                        "
                                                        :label="
                                                            $t('salary_ending')
                                                        "
                                                        :prefix="
                                                            $t(
                                                                'currency_prefix'
                                                            )
                                                        "
                                                        :suffix="
                                                            $t(
                                                                'currency_suffix'
                                                            )
                                                        "
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <!--Percent-->
                                            <v-text-field
                                                v-model="form.percent"
                                                :error-messages="
                                                    errors['form.percent']
                                                "
                                                @input="
                                                    $v.form.percent.$touch()
                                                "
                                                @blur="$v.form.percent.$touch()"
                                                type="number"
                                                :label="
                                                    $t(
                                                        'vacancy_stock_options_question'
                                                    )
                                                "
                                                suffix="%"
                                            ></v-text-field>
                                            <!--/Percent-->
                                            <v-select
                                                :items="benefits"
                                                item-text="attributes.description"
                                                item-value="id"
                                                :label="`${$t(
                                                    'vacancy_benefit_type_question'
                                                )} *`"
                                                v-model="form.benefit_types_id"
                                                :error-messages="
                                                    errors[
                                                        'form.benefit_types_id'
                                                    ]
                                                "
                                                @input="
                                                    $v.form.benefit_types_id.$touch()
                                                "
                                                @blur="
                                                    $v.form.benefit_types_id.$touch()
                                                "
                                                multiple
                                                chips
                                            ></v-select>
                                        </v-card-text>
                                        <!--/Salario y beneficios-->
                                        <!--/Descripción del empleo-->
                                        <v-card-title>
                                            {{ $t('job_description') }}
                                        </v-card-title>
                                        <v-card-subtitle>
                                            {{ $t('job_description_subtitle') }}
                                            <br />
                                            {{
                                                $t('job_description_subtitle2')
                                            }}
                                        </v-card-subtitle>
                                        <v-card-text>
                                            <v-textarea
                                                solo
                                                name="input-7-4"
                                                v-model="form.description"
                                                :error-messages="
                                                    errors['form.description']
                                                "
                                                @input="
                                                    $v.form.description.$touch()
                                                "
                                                @blur="
                                                    $v.form.description.$touch()
                                                "
                                                :auto-grow="true"
                                                :label="`${$t(
                                                    'job_description'
                                                )} *`"
                                            ></v-textarea>
                                        </v-card-text>
                                        <!--/Descripción del empleo-->

                                        <!--Actions-->
                                        <v-card-text>
                                            <small>{{
                                                $t('required_tooltip')
                                            }}</small>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                @click="onClose"
                                                :disabled="isProcessing"
                                            >
                                                {{ $t('cancel') }}
                                            </v-btn>
                                            <v-btn
                                                color="accent"
                                                @click="onSubmit"
                                                :loading="isProcessing"
                                            >
                                                {{ $t('publish') }}
                                            </v-btn>
                                        </v-card-actions>
                                        <!--/Actions-->
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </span>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, numeric, minValue } from 'vuelidate/lib/validators';
import { vueFrontErrors, vueRulesMessages } from '../../../plugins/vuelidate';
export default {
    props: ['job', 'value'],
    mixins: [validationMixin],
    validations: {
        form: {
            project_id: {
                required: required,
            },
            industry_id: {
                required: required,
            },
            title: {
                required: required,
            },
            location: {
                required: required,
            },
            type_id: {
                required: required,
            },
            contract_id: {
                required: required,
            },
            contract_time: {
                minValue: minValue(1),
            },
            contract_period: {
                minValue: minValue(0),
            },
            schedule_types_id: {
                required: required,
            },
            salary_starting: {
                required: required,
                numeric: numeric,
                minValue: minValue(1),
            },
            salary_final: {
                required: required,
                numeric: numeric,
                minValue: minValue(1),
            },
            percent: {
                numeric: numeric,
                minValue: minValue(0),
            },
            benefit_types_id: {
                required: required,
            },
            description: {
                required: required,
            },
        },
    },
    data() {
        return {
            datepickerStart: false,
            datepickerEnd: false,
            form: {
                id: null,
                industry_id: null,
                title: null,
                location: null,
                description: null,
                percent: null,
                salary_starting: null,
                salary_final: null,
                payment_period: null, // Deprectated
                type_id: null,
                contract_id: null,
                contract_time: null,
                contract_period: null,
                start_date: null,
                due_date: null,
                hires_needed: null,
                updates_to: null,
                schedule_types_id: [],
                benefit_types_id: [],
            },
            baseForm: {
                id: null,
                industry_id: null,
                title: null,
                location: null,
                description: null,
                percent: null,
                salary_starting: null,
                salary_final: null,
                payment_period: null, // Deprectated
                type_id: null,
                contract_id: null,
                contract_time: null,
                contract_period: null,
                start_date: null,
                due_date: null,
                hires_needed: null,
                updates_to: null,
                schedule_types_id: [],
                benefit_types_id: [],
            },
            isProcessing: false,
            isLoading: true,
            errors: {
                'form.id': [],
                'form.project_id': [],
                'form.industry_id': [],
                'form.title': [],
                'form.location': [],
                'form.description': [],
                'form.percent': [],
                'form.salary_starting': [],
                'form.salary_final': [],
                'form.payment_period': [],
                'form.type_id': [],
                'form.contract_id': [],
                'form.contract_time': [],
                'form.contract_period': [],
                'form.start_date': [],
                'form.due_date': [],
                'form.hires_needed': [],
                'form.updates_to': [],
                'form.schedule_types_id': [],
                'form.benefit_types_id': [],
            },
            rules: {
                ...vueRulesMessages,
            },
            validate: false,
        };
    },
    mounted() {
        this.prepareComponent();
    },
    watch: {
        form: {
            handler() {
                this.validate = true;
            },
            deep: true,
        },
        apiErrors: {
            handler() {
                this.validate = true;
            },
            deep: true,
        },
        job() {
            this.form = {
                ...this.baseForm,
            };

            if (this.job) {
                if (this.job.attributes) {
                    this.form = {
                        ...this.job.attributes,
                    };
                }
                if (this.job.relationships) {
                    if (this.job.relationships.contract_types) {
                        this.form.contract_type_id =
                            this.job.relationships.contract_types.map(
                                (contract_type) => {
                                    return contract_type.id;
                                }
                            );
                    }

                    if (this.job.relationships.employment_types) {
                        this.form.employment_type_id =
                            this.job.relationships.employment_types.map(
                                (employment_type) => {
                                    return employment_type.id;
                                }
                            );
                    }

                    if (this.job.relationships.workspace_types) {
                        this.form.workspace_type_id =
                            this.job.relationships.workspace_types.map(
                                (workspace_type) => {
                                    return workspace_type.id;
                                }
                            );
                    }

                    if (this.job.relationships.investment_industries) {
                        this.form.industries =
                            this.job.relationships.investment_industries.map(
                                (industry) => {
                                    return industry.id;
                                }
                            );
                    }

                    if (this.job.relationships.schedule_types) {
                        this.form.schedule_types_id =
                            this.job.relationships.schedule_types.map(
                                (schedule_type) => {
                                    return schedule_type.id;
                                }
                            );
                    }

                    if (this.job.relationships.benefit_types) {
                        this.form.benefit_types_id =
                            this.job.relationships.benefit_types.map(
                                (benefit) => {
                                    return benefit.id;
                                }
                            );
                    }
                }
            }
        },
        validationForm(newValue) {
            if (newValue === false) {
                return;
            }

            this.validate = false;
            this.vueFrontErrors();
        },
    },
    computed: {
        ...mapGetters('jobs', {
            apiErrors: 'errors',
        }),
        ...mapGetters('projects', {
            projects: 'projectsAdmin',
        }),
        ...mapGetters({
            contract_types: 'contract_types',
            employment_types: 'employment_types',
            schedule_types: 'schedule_types',
            education_levels: 'education_levels',
            workspace_types: 'workspace_types',
            industries: 'industries',
            benefits: 'benefits',
            contracts_periods: 'contracts_periods',
        }),
        title() {
            return this.job === null ? 'Nueva ronda' : 'Editar ronda';
        },
        validationForm() {
            return this.validate;
        },
        isBySpecificTime() {
            return this.form.contract_id === 2 || this.form.contract_id === 3;
        },
    },
    methods: {
        vueFrontErrors: vueFrontErrors,
        async prepareComponent() {
            this.isLoading = true;
            await Promise.allSettled([
                this.fetchContractTypes(),
                this.fetchEducationLevels(),
                this.fetchEmploymentTypes(),
                this.fetchScheduleTypes(),
                this.fetchWorkspaceTypes(),
                this.fetchIndustries(),
                this.fetchProjects(),
                this.fetchBenefitTypes(),
            ]);
            this.isLoading = false;
        },
        ...mapActions('jobs', {
            store: 'store',
            update: 'update',
        }),
        ...mapActions('projects', {
            fetchProjects: 'fetchAdmin',
        }),
        ...mapActions({
            fetchContractTypes: 'fetchContractTypes',
            fetchEducationLevels: 'fetchEducationLevels',
            fetchEmploymentTypes: 'fetchEmploymentTypes',
            fetchScheduleTypes: 'fetchScheduleTypes',
            fetchWorkspaceTypes: 'fetchWorkspaceTypes',
            fetchIndustries: 'fetchIndustries',
            fetchBenefitTypes: 'fetchBenefitTypes',
        }),
        onClose() {
            this.$emit('input', false);
        },
        async onSubmit() {
            let success;
            this.isProcessing = true;
            if (this.job) {
                success = await this.update({
                    id: this.job.id,
                    data: {
                        id: this.job.id,
                        ...this.form,
                    },
                });
            } else {
                success = await this.store({
                    project_id: this.form.project_id,
                    data: {
                        ...this.form,
                    },
                });
            }
            this.isProcessing = false;

            this.$emit('input', !success);
            this.$emit('stored', success);
        },
    },
};
</script>
