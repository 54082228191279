<template>
    <div>
        All jobs
    </div>
</template>
<script>
export default {

}
</script>
