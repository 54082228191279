<template>
<div>
    <v-card flat class="mb-5">
        <v-card-title>
            Detalles de pago
        </v-card-title>
        <v-simple-table class="mx-5">
            <template v-slot:default>
                <tbody>
                    <tr>
                        <td style="width:50%">Estatus</td>
                        <td>
                            {{ investment.relationships.status.attributes.name }}
                        </td>
                    </tr>
                    <tr v-if="false">
                        <td>Método de pago</td>
                        <td>****1234</td>
                    </tr>
                    <tr>
                        <td>Inversión</td>
                        <td>
                            {{ investmentAmount | currency_mx }}
                        </td>
                    </tr>
                    <tr>
                        <td>Comisión LinkBridge</td>
                        <td>
                            {{ linkBridgeAmount | currency_mx }}
                        </td>
                    </tr>
                    <tr>
                        <td>Comisión bancaria</td>
                        <td>
                            {{ paymentMethodAmount | currency_mx }}
                        </td>
                    </tr>
                    <tr>
                        <td>IVA</td>
                        <td>
                            {{ taxAmount | currency_mx }}
                        </td>
                    </tr>
                    <tr style="background-color: #2a2a2a" class="gray darken-2 white--text h5">
                        <td>Total a pagar</td>
                        <td>
                            {{ totalAmount | currency_mx }}
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
    <v-card flat class="mb-5" v-if="false">
        <v-card-title>
            Datos del inversionista
        </v-card-title>
        <v-simple-table class="mx-5">
            <template v-slot:default>
                <tbody>
                    <tr>
                        <td style="width:50%">Nombre</td>
                        <td>Carlos Fabian Aguilera</td>
                    </tr>
                    <tr>
                        <td>Nacionalidad</td>
                        <td>MX</td>
                    </tr>
                    <tr>
                        <td>Tipo de persona</td>
                        <td>Persona Física</td>
                    </tr>
                    <tr>
                        <td>CURP</td>
                        <td>AUCA860420941</td>
                    </tr>
                    <tr>
                        <td>RFC</td>
                        <td>AUCA860420941</td>
                    </tr>
                    <tr>
                        <td>Teléfono</td>
                        <td>8113002226</td>
                    </tr>
                    <tr dark>
                        <td>Beneficiario</td>
                        <td>Ana Karent Escobedo Pacheco</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</div>
</template>
<script>
export default {
    props: {
        investment: {
            type: Object,
            required: true
        }
    },

    computed: {
        investmentAmount() {
            return this.investment.attributes.calculated_fees.investment / 100
        },
        linkBridgeAmount() {
            return this.investment.attributes.calculated_fees.linkbridge / 100
        },
        paymentMethodVariableAmount() {
            return this.investment.attributes.calculated_fees.payment_method_variable / 100
        },
        paymentMethodFixedAmount() {
            return this.investment.attributes.calculated_fees.payment_method_fixed / 100
        },
        paymentMethodAmount() {
            return this.paymentMethodVariableAmount + this.paymentMethodFixedAmount;
        },
        taxAmount() {
            return this.investment.attributes.calculated_fees.tax / 100
        },
        totalFeeAmount() {
            return this.investment.attributes.calculated_fees.total_fee / 100
        },
        totalAmount() {
            return this.investment.attributes.calculated_fees.total / 100
        },
    }
}
</script>