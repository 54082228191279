<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs">
        <app-title-crumb :title="$t('my_subscription')" :instructions="$t('my_subscription_msg')"></app-title-crumb>
    </app-breadcrumbs>
    <v-container fluid>
        <v-row align="center" justify="center" dense>
            <v-col cols="12" sm="10" md="7">
                <v-skeleton-loader max-height="150" class="mx-auto" type="card-heading, card" v-if="isLoading"></v-skeleton-loader>
                <v-card class="mx-auto" outlined v-else>
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="overline mb-4">
                                {{ $t('plan_information') | uppercase }}
                            </div>
                            <v-list-item-title class="headline mb-1" v-if="subscription === null">
                                {{ $t('basic_plan') | uppercase }}
                            </v-list-item-title>
                            <v-list-item-title class="headline mb-1" v-else>
                                {{ $t('plan') | uppercase }}
                                {{
                                        subscription.plan.nickname | uppercase
                                    }}
                                -
                                {{
                                        (subscription.plan.amount / 100)
                                            | currency
                                    }}
                                MXN / {{ $t('month') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-card-actions v-if="subscription === null">
                        <v-btn rounded :to="{ name: 'plans.index' }" color="accent">
                            {{ $t('update_plan_to', {plan: "PRO"}) | uppercase }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" sm="10" md="7">
                <v-skeleton-loader max-height="150" class="mx-auto" type="card-heading, card" v-if="isLoading"></v-skeleton-loader>
                <v-card class="mx-auto" outlined v-else>
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="overline mb-4">
                                {{ $t('subscription') | uppercase }}
                            </div>
                            <v-list-item-title class="headline mb-1">
                                <v-icon large>
                                    mdi-credit-card
                                </v-icon>
                                {{ brand | uppercase }} •••• •••• ••••
                                {{ last4 }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ statusText }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-if="defaultPaymentMethod === null">
                                {{ $t('there_is_no_default_payment_method') }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn text rounded :to="{
                                    name: 'subscription.payment.method.index'
                                }">
                            {{ $t('manage_payment_method') }}
                        </v-btn>
                        <v-btn text rounded :to="{
                                    name: 'subscription.invoices.index'
                                }">
                            {{ $t('payment_history') }}
                        </v-btn>
                        <v-btn text rounded color="accent" @click="dialog = true">
                            {{ $t('cancel') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <app-confirm @confirmed="onCancel" text="¿Deseas cancelar tu suscripción?" v-model="dialog"></app-confirm>
    </v-container>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";

import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";
import AppConfirm from "@/components/molecules/Confirm";
export default {
    data() {
        return {
            dialog: false,
            isLoading: true,
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "home"
                    }
                },
                {
                    text: this.$t('dashboard'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "dashboard"
                    }
                },
                {
                    text: "",
                    disabled: true,
                    to: {
                        name: "home"
                    }
                }
            ]
        };
    },
    mounted() {
        this.prepareComponent();
    },
    computed: {
        ...mapGetters("stripe", {
            defaultPaymentMethod: "defaultPaymentMethod",
            subscription: "subscription",
            status: "status"
        }),
        last4() {
            return this.defaultPaymentMethod ?
                this.defaultPaymentMethod.card.last4 :
                "••••";
        },
        brand() {
            return this.defaultPaymentMethod ?
                this.defaultPaymentMethod.card.brand :
                "";
        },
        statusText() {
            if (this.subscription === null) {
                return "";
            }
            const date = this.$options.filters.timestamp(
                this.subscription.current_period_end
            );
            let text;
            switch (this.status) {
                case "active":
                    text = `Tu próxima fecha de facturación es el ${date}.`;
                    break;
                case "trial":
                    text = `Tu periodo de prueba termina el ${date}.`;
                    break;
                case "grace":
                    text = `Tu suscripción se cancelara el ${date}.`;
                    break;
                case "ended":
                    text = `Tu suscripción se encuentra cancelada.`;
                    break;
                default:
                    text = "Sin información";
            }

            return text;
        }
    },
    methods: {
        ...mapActions("stripe", {
            fetchDefaultPaymentMethod: "fetchDefaultPaymentMethod",
            fetchSubscription: "fetchSubscription",
            destroySubscription: "destroySubscription"
        }),
        async prepareComponent() {
            this.isLoading = true;
            await Promise.all([
                this.fetchDefaultPaymentMethod(),
                this.fetchSubscription()
            ]);
            this.isLoading = false;
        },
        async onCancel(confirmed) {
            if (confirmed) {
                const success = await this.destroySubscription();
                this.dialog = !success;
            }
        }
    },
    components: {
        AppBreadcrumbs,
        AppTitleCrumb,
        AppConfirm
    }
};
</script>
