
<template>
    <div>
        <app-breadcrumbs :breadcrumbs="breadcrumbs">
            <!-- <app-project-crumb :project="project"></app-project-crumb> -->
        </app-breadcrumbs>
        <v-container fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="10" md="7">
                    <h1>{{ $t('investment_round') }}</h1>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";

export default {
    data() {
        return {
            dialog: false,
            isSaving: false,
            breadcrumbs: [
                {
                    text: this.$t('home'),
                    disabled: false,
                    to: {name: "home"},
                    exact: true
                },
                {
                    text: "Crear inversión",
                    disabled: true,
                    to: { name: "rounds.create" }
                }
            ]
        };
    },
    methods: {
        onSave() {}
    },
    components: {
        AppBreadcrumbs
    }
};
</script>
