<template>
<app-link-bridge v-if="isSuperAdmin" />
<app-default v-else />
</template>

<script>
import {
  mapGetters
} from 'vuex';
import AppDefault from '@/components/dashboard/Default';
import AppLinkBridge from '@/components/dashboard/LinkBridge';
export default {
  computed: {
    ...mapGetters('auth', {
      hasRole: 'hasRole',
    }),
    isSuperAdmin() {
      return this.hasRole('owner')
    },
  },
  components: {
    AppDefault,
    AppLinkBridge,
  },
};
</script>
