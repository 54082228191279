import axios from '../../plugins/axios';

const state = {
    applications: null,
    application: null,
    stash: null,
    errors: [],
};

const actions = {
    stashJobId({ commit }, positionId) {
        commit('setStash', positionId);
    },
    async fetchPositionApplication({ commit }, positionId) {
        try {
            const response = await axios.get(
                `/api/profile/applications/jobs/${positionId}`
            );
            commit('setApplication', response.data);
        } catch (error) {
            commit('setApplication', null);
        }
    },
    async fetchUserApplications({ commit }) {
        try {
            const response = await axios.get(`/api/profile/applications`);
            commit('setApplications', response.data);
        } catch (error) {
            if (error.response.status === 404) {
                commit('setErrors', error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async storeUserApplication({ commit }, positionId) {
        try {
            const response = await axios.post(
                `/api/jobs/${positionId}/applications`
            );
            commit('setStash', null);
            commit('setApplication', response.data);
        } catch (error) {
            if (error.response.status === 422) {
                commit('setErrors', error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async adminFetchByJobId({ commit }, jobId) {
        try {
            const response = await axios.get(
                `/api/admin/jobs/${jobId}/applications`
            );
            commit('setApplications', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async adminFetchById({ commit }, applicantId) {
        commit('setApplication', null);
        try {
            const response = await axios.get(
                `/api/admin/applications/${applicantId}`
            );
            commit('setApplication', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async adminUpdate({ commit }, { applicantId, data }) {
        try {
            await axios.post(
                `/api/admin/applications/${applicantId}/update`,
                data
            );
        } catch (error) {
            if (error.response.status === 422) {
                commit('setErrors', error.response.data.errors);
            } else {
                console.log(error);
            }
            return false;
        }

        return true;
    },
    // eslint-disable-next-line no-empty-pattern
    async adminDestroy({}, applicantId) {
        try {
            await axios.post(`/api/admin/applications/${applicantId}/destroy`);
            return true;
        } catch (error) {
            return false;
        }
    },
};

const mutations = {
    setApplications(state, applications) {
        state.applications = applications;
    },
    setApplication(state, application) {
        state.application = application;
    },
    setStash(state, data) {
        state.stash = data;
    },
};

const getters = {
    applications: (state) => {
        return state.applications;
    },
    application: (state) => {
        return state.application;
    },
    isStashed: (state) => {
        return state.stash !== null;
    },
    stash: (state) => {
        return state.stash;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
