<template>
<div>
  <app-breadcrumbs :breadcrumbs="breadcrumbs">
    <app-project-crumb :project="project"></app-project-crumb>
  </app-breadcrumbs>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="10" md="6" v-if="!project">
      <v-skeleton-loader class="mx-auto" type="card-heading, card, actions"></v-skeleton-loader>
    </v-col>
    <v-col cols="12" sm="10" md="6" v-else>
      <div style="overflow-y: auto; max-height: 250px;" class="mb-2 pa-2 scroll-bottom">
        <v-timeline v-if="messages.length">
          <v-timeline-item v-for="message in messages" :key="message.id" large :right="message.isEmisor" :left="!message.isEmisor" small hide-dot>
            <v-card class="elevation-2">
              <v-card-title class="subtitle-2">
                {{ message.user.name }}
              </v-card-title>
              <v-card-text>{{ message.body }}</v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </div>
      <v-card>
        <v-card-title class="headline">
          {{ $t('contact') }}
        </v-card-title>
        <v-container>
          <form>
            <v-textarea solo rows="3" auto-grow v-model="form.body" label="Escribe tu mensaje"></v-textarea>
            <div class="text-right">
              <v-btn color="accent" @click="onSubmit" :disabled="form.body.trim() === ''">
                {{ $t('send') }}
              </v-btn>
            </div>
          </form>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</div>
</template>

<script>
import {
  apiErrors
} from "@/util.js";
import {
  mapActions,
  mapGetters
} from "vuex";
import {
  validationMixin
} from "vuelidate";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppProjectCrumb from "@/components/molecules/Breadcrumbs/Project";

export default {
  mixins: [validationMixin],
  validations: {
    form: {
      body: {}
    }
  },
  data() {
    return {
      form: {
        body: ""
      },
      errors: [],
      breadcrumbs: [{
          text: this.$t('home'),
          disabled: false,
          to: {
            name: "home"
          },
          exact: true
        },
        {
          text: "Proyecto",
          disabled: false,
          exact: true,
          to: {
            name: "projects.show",
            params: {
              project: this.$route.params.project
            }
            // TODO: Add slug parameter.
          }
        },
        {
          text: "",
          disabled: true,
          to: {
            name: "home"
          }
        }
      ]
    };
  },
  mounted() {
    this.prepareComponent();
  },
  computed: {
    bodyErrors() {
      return this.createErrors("body", {
        required: "El mensaje es requerido"
      });
    },
    ...mapGetters("projects", {
      project: "project"
    }),
    ...mapGetters("contact", {
      messages: "messages"
    })
  },
  methods: {
    prepareComponent() {
      this.fetchById(this.$route.params.project);
      this.fetchMessages(this.$route.params.project);
    },
    ...mapActions("projects", {
      fetchById: "fetchById"
    }),
    ...mapActions("contact", {
      fetchMessages: "fetchMessages",
      sendMessage: "store"
    }),
    createErrors(key, rules) {
      const errors = apiErrors(key, this.errors);
      if (!this.$v.form[key].$dirty && errors.length == 0) return errors;
      for (let rule in rules) {
        !this.$v.form[key][rule] && errors.push(rules[rule]);
      }
      return errors;
    },
    onSubmit() {
      const data = {
        projectId: this.$route.params.project,
        form: {
          ...this.form
        }
      };
      this.form.body = "";
      this.sendMessage(data);
    }
  },
  components: {
    AppBreadcrumbs,
    AppProjectCrumb
  }
};
</script>
