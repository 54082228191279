<template>
<v-container fluid class="my-5">
    <v-row class="mx-10">
        <v-col>
            <h1 class="text-h5 mb-4">
                {{ title }}
                <a class="subtitle-2 d-none" style="text-decoration: underline;">
                    {{ $t('view_all') }}
                </a>
            </h1>
        </v-col>
    </v-row>
    <app-loader v-if="projects === null"></app-loader>
    <v-row class="mx-10" dense id="#new" v-else>
        <v-col v-for="item in projects" :key="item.id" cols="12" xm="1" sm="12" md="3">
            <app-card :data="item"></app-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import AppCard from "./card/Card";
import AppLoader from "@/components/atoms/Loader";

export default {
    props: {
        title: String,
        url: String,
        projects: Array
    },
    data: () => ({
        loading: false
    }),

    components: {
        AppCard,
        AppLoader
    }
};
</script>
