<template>
<div>
  <app-breadcrumbs :breadcrumbs="breadcrumbs">
    <app-project-crumb :project="project"></app-project-crumb>
  </app-breadcrumbs>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12" md="8" v-if="project">
        <app-project-description :project="project"></app-project-description>
      </v-col>
      <v-col cols="12" sm="12" md="8" v-if="project">
        <v-row>
          <v-col cols="12" sm="12" v-if="round.description">
            <v-card>
              <v-card-text v-html="round.description"></v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12">
            <v-card>
              <v-card-title>
                1. {{ $t('amount_to_invest') }}
              </v-card-title>
              <v-card-subtitle>
                {{ $t('amount_to_invest_msg') }}
              </v-card-subtitle>
              <v-card-text>
                <v-radio-group v-model="selectedAmount" v-if="round">
                  <v-radio v-for="option in round.options" :key="option" :value="option">
                    <template v-slot:label>
                      {{ option | currency }}
                    </template>
                  </v-radio>
                  <v-radio :key="0" :value="0" label="Otra cantidad">
                  </v-radio>
                </v-radio-group>
                <v-text-field :min="round.minimum" :label="`Inversión mínima ${round.minimum}`" v-model="form.amount" type="number" step="100" :prefix="$t('currency_prefix')" :suffix="$t('currency_suffix')" hide-details="auto" v-if="selectedAmount === 0 && round"></v-text-field>
              </v-card-text>
              <v-card-title>
                2. {{ $t('payment_method') }}
              </v-card-title>
              <v-card-subtitle>
                {{ $t('payment_method_msg') }}
              </v-card-subtitle>

              <v-card-text>
                <app-payment-methods ref="appPaymentMethods" v-model="paymentMethod"></app-payment-methods>
              </v-card-text>
              <v-card-title>
                3. {{ $t('confirm_investment') }}
              </v-card-title>
              <v-card-subtitle>
                {{ $t('confirm_investment_msg') }}
              </v-card-subtitle>
              <v-card-text>
                <v-checkbox v-model="isConfirmed">
                  <template v-slot:label>
                    {{ $t('confirm_to_invest') }}
                    {{ amount | currency }}
                  </template>
                </v-checkbox>
              </v-card-text>
              <!--Actions-->
              <v-card-text>
                <small> {{ $t('required_instructions') }} </small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="dialog = false" :disabled="lockSubmit" v-if="false">
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn color="accent" @click="onPurchase" :disabled="!isConfirmed || lockSubmit" :loading="lockSubmit">
                  {{ $t('invest') }}
                </v-btn>
              </v-card-actions>
              <!--/Actions-->
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppProjectCrumb from "@/components/molecules/Breadcrumbs/Project";
import AppPaymentMethods from "@/components/stripe/PaymentMethods";
import AppProjectDescription from "@/components/projects/project/Description";

export default {
  data() {
    return {
      id: this.$route.params.id,
      selectedAmount: 0,
      paymentMethod: null,
      dialog: false,
      lockSubmit: false,
      isConfirmed: false,
      breadcrumbs: [{
          text: this.$t('home'),
          disabled: false,
          to: {
            name: "home"
          },
          exact: true
        },
        {
          text: "Crear inversión",
          disabled: true,
          to: {
            name: "rounds.create"
          }
        }
      ],
      stripe: {
        stripe: undefined,
        card: undefined,
        elements: undefined,
        error: null
      },
      form: {
        name: null,
        amount: null
      }
    };
  },
  mounted() {
    this.prepareComponent();
  },
  computed: {
    ...mapGetters("auth", {
      isAuth: "isAuth"
    }),
    ...mapGetters("projects", {
      project: "project"
    }),
    ...mapGetters("rounds", {
      round: "round"
    }),
    amount() {
      return this.selectedAmount || this.form.amount || 0;
    }
  },
  methods: {
    ...mapActions("rounds", {
      fetchById: "fetchById"
    }),
    ...mapActions("projects", {
      fetchProjectById: "fetchById"
    }),
    ...mapActions("investments", {
      store: "store"
    }),
    async prepareComponent() {
      await this.fetchById(this.id);
      this.setDefaultAmount();
      await this.fetchProjectById(this.round.project_id);
    },
    setDefaultAmount() {
      this.selectedAmount = this.round.options[0];
    },
    async onPurchase() {
      this.lockSubmit = true;
      const amount = this.getAmount();
      await this.$refs.appPaymentMethods.getPaymentMethod();

      if (this.paymentMethod !== 0) {
        await this.store({
          roundId: this.id,
          data: {
            payment_method: this.paymentMethod,
            amount
          }
        });
      }

      this.lockSubmit = false;
    },
    getAmount() {
      if (this.selectedAmount === 0) {
        return this.form.amount;
      }
      return this.selectedAmount;
    }
  },
  components: {
    AppBreadcrumbs,
    AppProjectCrumb,
    AppPaymentMethods,
    AppProjectDescription
  }
};
</script>
