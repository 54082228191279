<template>
<v-list-item v-if="job" class="mt-n2">
    <v-list-item-content class="pa-0 py-4">
        <v-list-item-title class="headline">{{
                title
            }}</v-list-item-title>
        <v-list-item-subtitle>
            {{ computedSubtitle }}
        </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="ma-0">
        <slot name="action"></slot>
    </v-list-item-action>
</v-list-item>
<v-skeleton-loader class="pa-0" type="card-heading" v-else></v-skeleton-loader>
</template>

<script>
export default {
    props: ["job", "subtitle"],
    computed: {
        title() {
            let title = this.job.attributes.title;

            if (this.job.relationships.project) {
                title = `${title} @ ${this.job.relationships.project.attributes.name}`;
            }

            return title
        },
        computedSubtitle() {
            return this.subtitle || this.$t('applicants_instructions')
        }
    }
};
</script>
