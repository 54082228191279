<template>
<v-card flat :loading="isLoading">
    <v-card-title>
        {{ $t('professional_data') }}
    </v-card-title>
    <v-card-subtitle>
        {{ $t('professional_data_msg') }}
    </v-card-subtitle>
    <v-form>
        <v-card-text>
            <v-text-field :error-messages="errors['form.primary_profile']" :label="$t('primary_profile')" :placeholder="$t('primary_profile_placeholder')" v-model="form.primary_profile"></v-text-field>
            <v-text-field :error-messages="errors['form.secondary_profile']" :label="$t('secondary_profile')" :placeholder="$t('optional_placeholder')" v-model="form.secondary_profile"></v-text-field>
            <v-select :error-messages="errors['form.education_level_id']" v-model="form.education_level_id" :items="education_levels" :label="$t('what_is_your_educational_level')" item-text="attributes.description" item-value="id" persistent-hint></v-select>
            <v-radio-group v-model="form.is_currently_employed" :error-messages="errors['form.is_currently_employed']" :label="$t('are_you_currently_working')">
                <v-radio :label="$t('yes')" :value="1"></v-radio>
                <v-radio :label="$t('no')" :value="0"></v-radio>
            </v-radio-group>
            <v-text-field v-if="form.is_currently_employed === 1" :error-messages="errors['form.employer_company']" :label="$t('in_which_company')" v-model="form.employer_company"></v-text-field>
            <v-radio-group v-model="form.has_startup_experience" :error-messages="errors['form.has_startup_experience']">
                <template v-slot:label>
                    {{ $t('do_you_have_startup_experience') }}
                </template>
                <v-radio :label="$t('yes')" :value="1"></v-radio>
                <v-radio :label="$t('no')" :value="0"></v-radio>
            </v-radio-group>
            <v-text-field v-if="form.has_startup_experience === 1" :error-messages="errors['form.startup_yoe']" :label="$t('how_many_startup_yoe')" :placeholder="$t('how_many_startup_yoe_placeholder')" v-model="form.startup_yoe"></v-text-field>
            <v-radio-group v-model="form.has_travel_availability" :error-messages="errors['form.has_travel_availability']">
                <template v-slot:label>
                    {{ $t('are_you_available_to_travel') }}
                </template>
                <v-radio :label="$t('yes')" :value="1"></v-radio>
                <v-radio :label="$t('no')" :value="0"></v-radio>
            </v-radio-group>
        </v-card-text>
        <v-card-title>
            {{ $t('desired_job') }}
        </v-card-title>
        <v-card-subtitle>
            {{ $t('desired_job_msg') }}
        </v-card-subtitle>
        <v-card-text>
            <v-select :error-messages="errors['form.employment_type_id']" v-model="form.employment_type_id" :items="employment_types" :label="$t('what_kind_of_job_are_you_looking_for')" item-text="attributes.description" multiple chips item-value="id" persistent-hint></v-select>
            <v-select :error-messages="errors['form.contract_type_id']" v-model="form.contract_type_id" :items="contract_types" :label="$t('what_types_of_contract_are_you_interested')" multiple chips item-text="attributes.name" item-value="id" persistent-hint></v-select>
            <v-select :error-messages="errors['form.schedule_type_id']" v-model="form.schedule_type_id" :items="schedule_types" :label="$t('what_hours_can_you_cover')" multiple chips item-text="attributes.description" item-value="id" persistent-hint></v-select>
            <v-text-field :error-messages="errors['form.hourly_rate']" :label="$t('what_hourly_rate')" :prefix="$t('currency_prefix')" :suffix="$t('currency_suffix')" :placeholder="$t('what_hourly_rate_placeholder')" v-model="form.hourly_rate" :hint="$t('what_hourly_rate_hint')"></v-text-field>
            <v-text-field :error-messages="errors['form.monthly_rate']" :label="$t('what_desired_salary')" :prefix="$t('currency_prefix')" :suffix="$t('currency_suffix')" :placeholder="$t('what_desired_salary_placeholder')" v-model="form.monthly_rate"></v-text-field>
            <v-radio-group v-model="form.has_interest_actions" :error-messages="errors['form.has_interest_actions']">
                <template v-slot:label>
                    {{ $t('are_you_interested_in_working_for_shares') }}
                </template>
                <v-radio :label="$t('yes')" :value="1"></v-radio>
                <v-radio :label="$t('no')" :value="0"></v-radio>
            </v-radio-group>
        </v-card-text>
        <v-card-title>
            {{ $t('workspace') }}
        </v-card-title>
        <v-card-subtitle>
            {{ $t('workspace_msg') }}
        </v-card-subtitle>
        <v-card-text>
            <v-checkbox v-model="form.workspace_type_id" :label="ws.attributes.description" :value="ws.id" hide-details v-for="ws in workspace_types" :key="ws.id" :error-messages="errors['form.workspace_type_id']"></v-checkbox>
        </v-card-text>
        <v-card-title>
            {{ $t('write_a_paragraph_about_yourself') }}
        </v-card-title>
        <v-card-subtitle>
            {{ $t('write_a_paragraph_about_yourself_msg') }}
        </v-card-subtitle>
        <v-card-text>
            <v-textarea solo name="input-7-4" :label="$t('write_paragraph_about_yourself')" v-model="form.about" :error-messages="errors['form.about']" required counter :hint="$t('write_paragraph_about_yourself_msg')"></v-textarea>
        </v-card-text>
        <v-card-title>
            {{ $t('social_networks') }} {{ $t('optional') }}
        </v-card-title>
        <v-card-subtitle>
            {{ $t('social_networks_msg') }}
        </v-card-subtitle>
        <v-card-text>
            <v-text-field v-model="form.social_networks.website" :error-messages="errors['form.social_networks.website']" :label="$t('website')" :hint="$t('website_hint')" persistent-hint></v-text-field>
            <v-text-field v-model="form.social_networks.linkedin" :error-messages="errors['form.social_networks.linkedin']" label="LinkedIn"></v-text-field>
            <v-text-field v-model="form.social_networks.github" :error-messages="errors['form.social_networks.github']" label="Github" :hint="$t('github_hint')" persistent-hint></v-text-field>
            <v-text-field v-model="form.social_networks.facebook" :error-messages="errors['form.social_networks.facebook']" label="Facebook"></v-text-field>
            <v-text-field v-model="form.social_networks.instagram" :error-messages="errors['form.social_networks.instagram']" label="Instagram"></v-text-field>
            <v-text-field v-model="form.social_networks.twitter" :error-messages="errors['form.social_networks.twitter']" label="Twitter"></v-text-field>
        </v-card-text>
        <v-card-actions>
            <v-btn color="accent" class="mr-4" @click="onSubmit" :loading="isProcessing" :disabled="isLoading">
                {{ $t('save') }}
            </v-btn>
        </v-card-actions>
    </v-form>
</v-card>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import {
    validationMixin
} from "vuelidate";
import {
    required,
    minLength,
    maxLength,
    numeric,
    requiredIf,
    url
} from "vuelidate/lib/validators";
import {
    vueFrontErrors,
    vueRulesMessages
} from "../../plugins/vuelidate";
export default {
    mixins: [validationMixin],
    validations: {
        form: {
            primary_profile: {
                required
            },
            secondary_profile: {},
            education_level_id: {
                required
            },
            is_currently_employed: {
                required
            },
            employer_company: {
                required: requiredIf(function () {
                    return this.form.is_currently_employed == 1;
                })
            },
            has_startup_experience: {
                required
            },
            startup_yoe: {
                numeric: numeric,
                required: requiredIf(function () {
                    return this.form.has_startup_experience == 1;
                })
            },
            has_travel_availability: {
                required
            },
            employment_type_id: {
                required
            },
            contract_type_id: {
                required
            },
            schedule_type_id: {
                required
            },
            hourly_rate: {
                required
            },
            monthly_rate: {
                required
            },
            has_interest_actions: {
                required
            },
            about: {
                required: required,
                minLength: minLength(150),
                maxLength: maxLength(500)
            },
            workspace_type_id: {
                required
            },
            social_networks: {
                website: {
                    url
                },
                linkedin: {
                    url
                },
                github: {
                    url
                },
                facebook: {
                    url
                },
                instagram: {
                    url
                },
                twitter: {
                    url
                }
            }
        }
    },
    data() {
        return {
            isLoading: false,
            isProcessing: false,
            form: {
                about: null,
                employer_company: null,
                contract_type_id: [],
                education_level_id: null,
                employment_type_id: [],
                has_interest_actions: null,
                has_startup_experience: null,
                has_travel_availability: null,
                hourly_rate: null,
                is_currently_employed: null,
                monthly_rate: null,
                primary_profile: null,
                schedule_type_id: [],
                secondary_profile: null,
                startup_yoe: null,
                workspace_type_id: [],
                social_networks: {
                    website: null,
                    linkedin: null,
                    github: null,
                    facebook: null,
                    instagram: null,
                    twitter: null
                }
            },
            errors: {
                "form.primary_profile": [],
                "form.secondary_profile": [],
                "form.education_level_id": [],
                "form.is_currently_employed": [],
                "form.has_travel_availability": [],
                "form.employer_company": [],
                "form.has_startup_experience": [],
                "form.startup_yoe": [],
                "form.employment_type_id": [],
                "form.contract_type_id": [],
                "form.schedule_type_id": [],
                "form.hourly_rate": [],
                "form.monthly_rate": [],
                "form.has_interest_actions": [],
                "form.about": [],
                "form.workspace_type_id": [],
                "form.social_networks.website": [],
                "form.social_networks.linkedin": [],
                "form.social_networks.github": [],
                "form.social_networks.facebook": [],
                "form.social_networks.instagram": [],
                "form.social_networks.twitter": []
            },
            rules: {
                ...vueRulesMessages
            },
            validate: false
        };
    },
    mounted() {
        this.prepareComponent();
    },
    watch: {
        form: {
            handler() {
                this.validate = true;
            },
            deep: true
        },
        apiErrors: {
            handler() {
                this.validate = true;
            },
            deep: true
        },
        profile() {
            if (this.profile.attributes) {
                this.form.about = this.profile.attributes.about;
                this.form.employer_company = this.profile.attributes.employer_company;
                this.form.has_interest_actions = this.profile.attributes.has_interest_actions;
                this.form.has_startup_experience = this.profile.attributes.has_startup_experience;
                this.form.has_travel_availability = this.profile.attributes.has_travel_availability;
                this.form.hourly_rate = this.profile.attributes.hourly_rate;
                this.form.is_currently_employed = this.profile.attributes.is_currently_employed;
                this.form.monthly_rate = this.profile.attributes.monthly_rate;
                this.form.primary_profile = this.profile.attributes.primary_profile;
                this.form.secondary_profile = this.profile.attributes.secondary_profile;
                this.form.startup_yoe = this.profile.attributes.startup_yoe;
                this.form.education_level_id = this.profile.attributes.education_level_id;
            }
            if (this.profile.relationships) {
                if (this.profile.relationships.contract_types) {
                    this.form.contract_type_id = this.profile.relationships.contract_types.map(
                        contract_type => {
                            return contract_type.id;
                        }
                    );
                }

                if (this.profile.relationships.employment_types) {
                    this.form.employment_type_id = this.profile.relationships.employment_types.map(
                        employment_type => {
                            return employment_type.id;
                        }
                    );
                }

                if (this.profile.relationships.schedule_types) {
                    this.form.schedule_type_id = this.profile.relationships.schedule_types.map(
                        schedule_type => {
                            return schedule_type.id;
                        }
                    );
                }

                if (this.profile.relationships.workspace_types) {
                    this.form.workspace_type_id = this.profile.relationships.workspace_types.map(
                        workspace_type => {
                            return workspace_type.id;
                        }
                    );
                }

                if (this.profile.relationships.social_networks) {
                    this.profile.relationships.social_networks.forEach(
                        social_network => {
                            this.form.social_networks[
                                social_network.attributes.name
                            ] = social_network.attributes.url;
                        }
                    );
                }
            }
        },
        validationForm(newValue) {
            if (newValue === false) {
                return;
            }

            this.validate = false;
            this.vueFrontErrors();
        }
    },
    computed: {
        ...mapGetters("professional", {
            profile: "profile",
            apiErrors: "errors"
        }),
        ...mapGetters({
            contract_types: "contract_types",
            employment_types: "employment_types",
            schedule_types: "schedule_types",
            education_levels: "education_levels",
            workspace_types: "workspace_types"
        }),
        validationForm() {
            return this.validate;
        }
    },
    methods: {
        vueFrontErrors: vueFrontErrors,
        async prepareComponent() {
            this.isLoading = true;
            await Promise.allSettled([
                this.fetchContractTypes(),
                this.fetchEducationLevels(),
                this.fetchEmploymentTypes(),
                this.fetchScheduleTypes(),
                this.fetchWorkspaceTypes()
            ]);
            await this.fetchProfile();
            this.isLoading = false;
        },
        ...mapActions("professional", {
            fetchProfile: "fetchProfile",
            storeProfile: "storeProfile",
            storeProfileContractTypes: "storeContractTypes",
            storeProfileEmploymentTypes: "storeEmploymentTypes",
            storeProfileScheduleTypes: "storeScheduleTypes",
            storeProfileSocialNetworks: "storeSocialNetworks",
            storeProfileWorkspaceTypes: "storeWorkspaceTypes"
        }),
        ...mapActions({
            fetchContractTypes: "fetchContractTypes",
            fetchEducationLevels: "fetchEducationLevels",
            fetchEmploymentTypes: "fetchEmploymentTypes",
            fetchScheduleTypes: "fetchScheduleTypes",
            fetchWorkspaceTypes: "fetchWorkspaceTypes"
        }),
        async onSubmit() {
            this.$v.form.$touch();
            let invalid = this.$v.form.$invalid;
            this.validate = true;

            if (invalid) {
                return;
            }

            this.isProcessing = true;

            const formProfile = {
                data: {
                    type: "professional_profile",
                    attributes: {
                        about: this.form.about,
                        employer_company: this.form.employer_company,
                        education_level_id: this.form.education_level_id,
                        has_interest_actions: this.form.has_interest_actions,
                        has_startup_experience: this.form
                            .has_startup_experience,
                        has_travel_availability: this.form
                            .has_travel_availability,
                        hourly_rate: this.form.hourly_rate,
                        is_currently_employed: this.form.is_currently_employed,
                        monthly_rate: this.form.monthly_rate,
                        primary_profile: this.form.primary_profile,
                        secondary_profile: this.form.secondary_profile,
                        startup_yoe: this.form.startup_yoe
                    }
                }
            };
            const formContracts = {
                data: {
                    type: "contract_types",
                    attributes: {
                        contract_type_id: this.form.contract_type_id
                    }
                }
            };
            const formEmployments = {
                data: {
                    type: "employment_types",
                    attributes: {
                        employment_type_id: this.form.employment_type_id
                    }
                }
            };
            const formSchedules = {
                data: {
                    type: "schedule_types",
                    attributes: {
                        schedule_type_id: this.form.schedule_type_id
                    }
                }
            };
            const socialNetworks = Object.entries(this.form.social_networks)
                .filter(([key, value]) => {
                    key;
                    return value ? true : false;
                })
                .map(([key, value]) => {
                    return {
                        name: key,
                        url: value
                    };
                });
            const formsSocialNetworks = {
                data: {
                    type: "social_networks",
                    attributes: {
                        social_networks: socialNetworks
                    }
                }
            };
            const formWorkspace = {
                data: {
                    type: "workspace_types",
                    attributes: {
                        workspace_type_id: this.form.workspace_type_id
                    }
                }
            };

            await Promise.allSettled([
                this.storeProfile(formProfile),
                this.storeProfileContractTypes(formContracts),
                this.storeProfileEmploymentTypes(formEmployments),
                this.storeProfileScheduleTypes(formSchedules),
                this.storeProfileSocialNetworks(formsSocialNetworks),
                this.storeProfileWorkspaceTypes(formWorkspace)
            ]);

            this.isProcessing = false;
        }
    }
};
</script>
