var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-breadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}},[_c('app-title-crumb',{attrs:{"title":_vm.$t('companies_management')}})],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center","dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.projects || [],"items-per-page":5},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                                        name: 'projects.show',
                                        params: { project: item.id }
                                    },"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.created_at",fn:function(ref){
                                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.created_at).toLocaleString()))])]}},{key:"item.id",fn:function(ref){
                                    var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","x-small":"","fab":"","color":"indigo","to":{
                                        name: 'linkbridge.projects.show',
                                        params: { id: item.id }
                                    }}},[_c('v-icon',[_vm._v("mdi-details")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }