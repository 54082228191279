<template>
<v-row>
  <v-col v-for="report in reports" :key="report.id" cols="12">
    <v-card outlined>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="headline">
            {{ report.attributes.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ report.attributes.date }}
          </v-list-item-subtitle>
          <div v-html="report.attributes.description" class="my-5" v-if="hasDescription(report)"></div>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions>
        <v-btn color="primary" text @click="onDownloadReport(report)" v-if="hasFile(report)">
          {{ $t('download') }}
        </v-btn>
        <v-btn color="error" text @click="onDeleteReport(report.id)" v-if="btnDelete">
          {{ $t('delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</v-row>
</template>

<script>
import {
  mapActions
} from 'vuex';

export default {
  props: {
    reports: {
      type: Array,
      required: true
    },
    btnDelete: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  methods: {
    ...mapActions("rounds", [
        "reportsDestroy"
      ]),
    onDownloadReport(report) {
      window.open(report.attributes.file, '_blank');
    },
    onDeleteReport(report_id) {
      this.reportsDestroy({
        round_id: this.round.id,
        report_id
      });
    },
    hasFile(report) {
      return report.attributes.file;
    },
    hasDescription(report) {
      return report.attributes.description;
    }
  }
}
</script>
