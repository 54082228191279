<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs">
        <app-title-crumb :title="$t('jobs_management')"></app-title-crumb>
    </app-breadcrumbs>
    <v-container fluid>

        <v-row justify="center" v-if="job">
            <v-col cols="12" md="9" sm="12">
                <v-skeleton-loader class="mx-auto" type="card-heading, card, actions" v-if="isLoading"></v-skeleton-loader>
                <v-card v-else>
                    <v-card-title>
                        {{ job.attributes.title }}
                    </v-card-title>
                    <v-card-subtitle>
                        {{ job.attributes.location }}
                    </v-card-subtitle>
                    <app-job-details :job="job" />
                </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="12">
                <app-job-status class="mt-5" :job="job"></app-job-status>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";
import AppJobDetails from "@/components/jobs/job/Details";
import AppJobStatus from "@/components/jobs/job/Status";

export default {
    data() {
        return {
            id: this.$route.params.id,
            isLoading: true,
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "home"
                    }
                },
                {
                    text: this.$t('dashboard'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "linkbridge.dashboard.show"
                    }
                },
                {
                    text: "Administración de empleos",
                    disabled: false,
                    exact: true,
                    to: {
                        name: "linkbridge.jobs.index"
                    }
                }
            ]
        };
    },
    mounted() {
        this.prepareComponent();
    },
    computed: {
        ...mapGetters("jobs", {
            job: "job"
        })
    },
    methods: {
        ...mapActions("jobs", {
            fetchById: "lbFetchById"
        }),
        async prepareComponent() {
            this.isLoading = true;
            await this.fetchById(this.id);
            this.isLoading = false;
        }
    },
    components: {
        AppBreadcrumbs,
        AppTitleCrumb,
        AppJobDetails,
        AppJobStatus
    }
};
</script>
