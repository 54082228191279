<template>
    <div>
        <app-breadcrumbs :breadcrumbs="breadcrumbs">
            <app-title-crumb :title="title"></app-title-crumb>
        </app-breadcrumbs>
        <v-container fluid>
            <v-row justify="center">
                <v-col cols="12" md="9" sm="12">
                    <v-card>
                        <app-round :round="round"></app-round>
                    </v-card>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <app-round-status :round="round"></app-round-status>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";
import AppRound from "@/components/projects/rounds/Round";
import AppRoundStatus from "@/components/rounds/round/Status";

export default {
    data() {
        return {
            id: this.$route.params.id,
            isLoading: true,
            breadcrumbs: [
                {
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: { name: "home" }
                },
                {
                    text: this.$t('dashboard'),
                    disabled: false,
                    exact: true,
                    to: { name: "linkbridge.dashboard.show" }
                },
                {
                    text: "Administración de rondas",
                    disabled: false,
                    exact: true,
                    to: { name: "linkbridge.rounds.index" }
                }
            ]
        };
    },
    mounted() {
        this.prepareComponent();
    },
    computed: {
        ...mapGetters("rounds", {
            round: "round"
        }),
        title() {
            let roundName = "";
            if (this.round) {
                roundName = ` - ${this.round.relationships.project.attributes.name}`;
            }

            return `Administración de ronda ${roundName}`;
        }
    },
    methods: {
        ...mapActions("rounds", {
            fetchById: "lbFetchById"
        }),
        prepareComponent() {
            this.fetchById(this.id);
        }
    },
    components: {
        AppBreadcrumbs,
        AppTitleCrumb,
        AppRoundStatus,
        AppRound
    }
};
</script>
