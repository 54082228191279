<template>
<div>
    <app-banner></app-banner>
    <app-project-grid :title="$t('most_recents')" href="#" :projects="projectsTop"></app-project-grid>
    <app-cta></app-cta>
    <app-project-grid :title="$t('others')" href="#" :projects="projectsBottom" v-if="projectsBottom.length > 0"></app-project-grid>
    <app-faq></app-faq>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import AppProjectGrid from "@/components/projects/grid/Grid";
import AppBanner from "./Banner";
import AppFaq from "./FAQ";
import AppCta from "./CTA";

export default {
    data: () => ({
        drawer: null,
    }),
    /**
     * Prepare the component (Vue 2.x).
     */
    mounted() {
        this.prepareComponent();
    },
    computed: {
        ...mapGetters("home", {
            images: "images"
        }),
        ...mapGetters("projects", {
            projects: "projects"
        }),
        projectsTop() {
            return this.projects ? this.projects.slice(0, 4) : [];
        },
        projectsBottom() {
            return this.projects ?
                this.projects.slice(4, this.projects.length) : [];
        }
    },
    methods: {
        prepareComponent() {
            this.fetchProjects();
        },
        ...mapActions("projects", {
            fetchProjects: "fetch"
        })
    },

    components: {
        AppProjectGrid,
        AppBanner,
        AppFaq,
        AppCta
    }
};
</script>
