import axios from "../../plugins/axios";

const state = {
    admin: {
        jobs: null
    },
    jobs: null,
    job: null,
    isFetching: false,
    isSaving: false,
    errors: []
};

const actions = {
    async fetchByProjectId({ commit }, projectId) {
        try {
            const response = await axios.get(`/api/projects/${projectId}/jobs`);
            commit("setJobs", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async lbFetch({ commit }) {
        try {
            const response = await axios.get(`/api/linkbridge/jobs`);
            commit("setJobs", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async lbFetchById({ commit }, positionId) {
        commit("setJob", null);
        try {
            const response = await axios.get(
                `/api/linkbridge/jobs/${positionId}`
            );
            commit("setJob", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async lbFetchByProjectId({ commit }, projectId) {
        try {
            const response = await axios.get(
                `/api/linkbridge/projects/${projectId}/jobs`
            );
            commit("setJobs", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async lbUpdate({ commit }, { jobId, statusId }) {
        try {
            const response = await axios.post(`/api/linkbridge/jobs/${jobId}`, {
                status_id: statusId
            });
            commit("setJob", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async adminFetch({ commit }) {
        try {
            const response = await axios.get(`/api/admin/jobs`);
            commit("setAdminJobs", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async adminFetchByProjectId({ commit }, projectId) {
        try {
            const response = await axios.get(
                `/api/admin/projects/${projectId}/jobs`
            );
            commit("setAdminJobs", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async fetchById({ commit }, positionId) {
        commit("setFetching", true);
        commit("setJob", null);
        try {
            const response = await axios.get(`/api/jobs/${positionId}`);
            commit("setJob", response.data);
            commit("setFetching", false);
        } catch (error) {
            commit("setJob", error);
        }
    },
    async fetch({ commit }) {
        commit("setJobs", null);
        try {
            const response = await axios.get(`/api/jobs`);
            commit("setJobs", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async store({ commit }, job) {
        commit("setErrors", []);
        try {
            await axios.post(
                `/api/admin/projects/${job.project_id}/jobs`,
                job.data
            );
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
            return false;
        }

        return true;
    },
    async update({ commit }, { id, data }) {
        try {
            await axios.post(
                `/api/admin/jobs/${id}/update`,
                data
            );
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
            return false;
        }

        return true;
    },
    // eslint-disable-next-line no-empty-pattern
    async destroy({ }, jobId) {
        try {
            await axios.post(
                `/api/admin/jobs/${jobId}/destroy`
            );
            return true;
        } catch (error) {
            return false;
        }
    }
};

const mutations = {
    setJobs(state, jobs) {
        state.jobs = jobs;
    },
    setJob(state, job) {
        state.job = job;
    },
    setAdminJobs(state, jobs) {
        state.admin.jobs = jobs;
    },
    setFetching(state, isFetching) {
        state.isFetching = isFetching;
    },
    setSaving(state, isSaving) {
        state.isSaving = isSaving;
    },
    setErrors(state, errors) {
        state.errors = errors;
    }
};

const getters = {
    jobs: state => {
        return state.jobs;
    },
    job: state => {
        return state.job;
    },
    jobsAdmin: state => {
        return state.admin.jobs;
    },
    errors: state => {
        return state.errors;
    },
    isSaving: state => {
        return state.isSaving;
    },
    isFetching: state => {
        return state.isFetching;
    },
    benefits: state => {
        return state.benefits.map((value, index) => {
            return { value: index + 1, text: value };
        });
    },
    schedules: state => {
        return state.schedules.map((value, index) => {
            return { value: index + 1, text: value };
        });
    },
    contracts: state => {
        return state.contracts.map((value, index) => {
            return { value: index + 1, text: value };
        });
    },
    contracts_periods: state => {
        return state.contracts_periods.map((value, index) => {
            return { value: index + 1, text: value };
        });
    },
    types: state => {
        return state.types.map((value, index) => {
            return { value: index + 1, text: value };
        });
    },
    industries: state => {
        return state.industries.map((value, index) => {
            return { value: index + 1, text: value };
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
