<template>
    <v-card outlined tile>
        <v-breadcrumbs :items="breadcrumbs" class="px-4 py-2">
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
        </v-breadcrumbs>
        <slot></slot>
    </v-card>
</template>
<script>
export default {
    props: {
        breadcrumbs: Array
    },
    data: () => ({})
};
</script>
