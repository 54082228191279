<template>
<div>
  <app-breadcrumbs :breadcrumbs="breadcrumbs">
    <app-title-crumb :title="`${$t('welcome')}, LinkBridge`"> </app-title-crumb>
  </app-breadcrumbs>
  <v-container fluid>
    <v-row dense>
      <v-col v-for="item in menu" :key="item.name" :cols="3">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn :to="{ ...item.to }" text>
              <v-icon left>
                mdi-details
              </v-icon>
              {{ $t('details') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

  
  
<script>
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";
export default {
  data() {
    return {
      breadcrumbs: [{
          text: this.$t('home'),
          disabled: false,
          exact: true,
          to: {
            name: "home"
          }
        },
        {
          text: "",
          disabled: true
        }
      ],
      menu: [{
          name: "Usuarios",
          to: {
            name: "linkbridge.users.index"
          }
        },
        {
          name: "Proyectos",
          to: {
            name: "linkbridge.projects.index"
          }
        },
        {
          name: "Rondas",
          to: {
            name: "linkbridge.rounds.index"
          }
        },
        {
          name: "Empleos",
          to: {
            name: "linkbridge.jobs.index"
          }
        }
      ]
    };
  },
  components: {
    AppBreadcrumbs,
    AppTitleCrumb
  }
};
</script>
