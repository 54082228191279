var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-breadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}},[_c('app-title-crumb',{attrs:{"title":_vm.$t('jobs'),"instructions":_vm.$t('my_vacancies_instructions')},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{attrs:{"color":"accent","dark":""},on:{"click":_vm.onCreate}},[_vm._v(" "+_vm._s(_vm.$t("vacancy_create_new"))+" ")]),_c('app-job-create',{attrs:{"job":_vm.job},on:{"stored":_vm.onStored},model:{value:(_vm.isFormOpen),callback:function ($$v) {_vm.isFormOpen=$$v},expression:"isFormOpen"}})]},proxy:true}])})],1),_c('v-container',{attrs:{"fluid":""}},[(_vm.isPreparing)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"card-heading, card, actions"}}):(_vm.jobs.length === 0)?_c('app-empty-state',[_c('v-btn',{attrs:{"color":"accent","dark":""},on:{"click":_vm.onCreate}},[_vm._v(" "+_vm._s(_vm.$t("vacancy_create_new"))+" ")]),_c('app-job-create',{attrs:{"job":_vm.job},on:{"stored":_vm.onStored},model:{value:(_vm.isFormOpen),callback:function ($$v) {_vm.isFormOpen=$$v},expression:"isFormOpen"}})],1):_c('v-row',{attrs:{"align":"center","justify":"center","dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"search":_vm.search,"items":_vm.jobs || [],"items-per-page":5},scopedSlots:_vm._u([{key:"item.attributes.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                                        name: 'admin.jobs.index',
                                        query: { id: item.id }
                                    },"target":"_blank"}},[_vm._v(" "+_vm._s(item.attributes.title)+" ")])]}},{key:"item.relationships.project.attributes.name",fn:function(ref){
                                var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                                        name: 'admin.projects.show',
                                        params: {
                                            project:
                                                item.relationships.project.id
                                        }
                                    },"target":"_blank"}},[_vm._v(" "+_vm._s(item.relationships.project.attributes .name)+" ")])]}},{key:"item.attributes.created_at",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.attributes.created_at))+" ")]}},{key:"item.attributes.applications_count",fn:function(ref){
                                var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary","text-color":"white","link":"","disabled":item.attributes.applications_count === 0,"to":{
                                        name: 'admin.jobs.applications.index',
                                        params: { id: item.id }
                                    }}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-account-circle")])],1),_vm._v(" "+_vm._s(item.attributes.applications_count)+" ")],1)]}},{key:"item.id",fn:function(ref){
                                    var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","x-small":"","fab":"","color":"primary"},on:{"click":function($event){return _vm.onEdit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","x-small":"","fab":"","color":"primary"},on:{"click":function($event){return _vm.onDestroy(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)],1)],1),_c('app-confirm',{attrs:{"text":_vm.$t('vacancy_delete_question')},on:{"confirmed":_vm.onDestroyConfirmed},model:{value:(_vm.confirm),callback:function ($$v) {_vm.confirm=$$v},expression:"confirm"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }