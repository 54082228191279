<template>
<div>
  <app-breadcrumbs :breadcrumbs="breadcrumbs">
    <app-title-crumb :title="$t('investment_rounds')" :instructions="$t('investment_rounds_msg')">
      <template v-slot:action>
        <v-btn color="accent" dark @click="onCreate">
          {{ $t('round_create_new') }}
        </v-btn>
        <app-round-create :round="round" v-model="isFormOpen" @stored="onStored"></app-round-create>
      </template>
    </app-title-crumb>
  </app-breadcrumbs>
  <v-container fluid>
    <v-skeleton-loader class="mx-auto" type="card-heading, card, actions" v-if="isPreparing" />
    <app-empty-state v-else-if="rounds.length === 0">
      <v-btn color="accent" dark @click="onCreate">
        {{ $t('round_create_new') }}
      </v-btn>
      <app-round-create :round="round" v-model="isFormOpen" @stored="onStored"></app-round-create>
    </app-empty-state>
    <v-row align="center" justify="center" dense v-else>
      <v-col cols="12" sm="10" md="12">
        <v-card>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :loading="isLoading" :headers="headers" :search="search" :items="rounds || []" :items-per-page="5" class="elevation-1">
            <!--Project name-->
            <template v-slot:item.relationships.project.attributes.name="{
                                    item,
                                }">
              <router-link :to="{
                                        name: 'admin.projects.show',
                                        params: {
                                            project:
                                                item.relationships.project.id,
                                        },
                                    }" target="_blank">
                {{
                                        item.relationships.project.attributes
                                            .name
                                    }}
              </router-link>
            </template>
            <!--/Project name-->
            <template v-slot:item.attributes.name="{ item }">
              <router-link :to="{
                                        name: 'linkbridge.projects.show',
                                        params: { id: item.attributes.id },
                                    }">
                {{ item.attributes.name }}
              </router-link>
            </template>
            <template v-slot:item.attributes.amount="{ item }">
              <span>{{
                                    item.attributes.amount | currency
                                }}</span>
            </template>
            <template v-slot:item.attributes.minimum="{ item }">
              <span>{{
                                    item.attributes.minimum | currency
                                }}</span>
            </template>
            <template v-slot:item.attributes.raised="{ item }">
              <span>
                {{ item.attributes.raised | currency }} /
                {{ (item.attributes.raised / item.attributes.amount) * 100 | percent }}
              </span>
            </template>
            <template v-slot:item.attributes.type_id="{ item }">
              <span v-if="item.attributes.type_id == 1">Acciones</span>
              <span v-else>Rendimiento</span>
            </template>
            <template v-slot:item.attributes.percent="{ item }">
              <span>{{
                                    item.attributes.percent | percent
                                }}</span>
            </template>
            <template v-slot:item.attributes.start_date="{ item }">
              <span>{{
                                    item.attributes.start_date | dateToLocale
                                }}</span>
            </template>
            <template v-slot:item.attributes.end_date="{ item }">
              <span>{{
                                    item.attributes.end_date | dateToLocale
                                }}</span>
            </template>
            <!--Actions-->
            <template v-slot:item.id="{ item }">
              <v-btn class="ma-2" outlined x-small fab color="primary" @click="onDetails(item, 'investors')">
                <v-icon>mdi-account-group</v-icon>
              </v-btn>
              <v-btn class="ma-2" outlined x-small fab color="primary" @click="onDetails(item, 'reports')">
                <v-icon>mdi-file-document-multiple</v-icon>
              </v-btn>
              <v-btn class="ma-2" outlined x-small fab color="primary" @click="onEdit(item)" v-if="isPending(item) && false">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn class="ma-2" outlined x-small fab color="primary" @click="onDestroy(item)" v-if="isPending(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <!--/Actions-->
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <app-confirm v-model="confirm" @confirmed="onDestroyConfirmed" :text="$t('vacancy_delete_question')"></app-confirm>

    <v-dialog v-model="dialogDetail" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar class>
          <v-toolbar-title>
            {{ $t('round_details') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="dialogDetail = false">
              <v-icon left>
                mdi-close
              </v-icon>
              {{ $t('close') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid class="mt-1">
          <app-round :round="round" v-if="detailsType == 'investors'" />
          <app-round-reports :round="round" v-if="detailsType == 'reports'"/>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';
import AppBreadcrumbs from '@/components/organisms/Breadcrumbs';
import AppTitleCrumb from '@/components/molecules/Breadcrumbs/Title';
import AppEmptyState from '@/components/atoms/EmptyState';
import AppConfirm from '@/components/molecules/Confirm';
import AppRound from "@/components/projects/rounds/Round";

import AppRoundReports from "@/components/admin/rounds/Reports";
import AppRoundCreate from "@/components/admin/rounds/Create";

export default {
  components: {
    AppBreadcrumbs,
    AppTitleCrumb,
    AppEmptyState,
    AppRoundCreate,
    AppConfirm,
    AppRound,
    AppRoundReports
  },
  data() {
    return {
      tabs: null,
      dialogDetail: false,
      detailsType: null,
      breadcrumbs: [{
          text: this.$t('home'),
          disabled: false,
          exact: true,
          to: {
            name: 'home',
          },
        },
        {
          text: '',
          disabled: true,
        },
      ],
      search: '',
      headers: [{
          text: this.$t('company'),
          value: 'relationships.project.attributes.name',
        },
        {
          text: this.$t('amount'),
          value: 'attributes.amount',
        },
        {
          text: this.$t('minimum'),
          value: 'attributes.minimum',
        },
        {
          text: this.$t('type'),
          value: 'attributes.type_id',
        },
        {
          text: this.$t('percent'),
          value: 'attributes.percent',
        },
        {
          text: this.$t('raised'),
          value: 'attributes.raised',
        },
        {
          text: this.$t('start_date'),
          value: 'attributes.start_date',
        },
        {
          text: this.$t('end_date'),
          value: 'attributes.end_date',
        },
        {
          text: this.$t('status'),
          value: 'relationships.status.attributes.name',
        },
        {
          text: this.$t('detail'),
          value: 'id',
        },
      ],
      isPreparing: true,
      isLoading: false,
      isFormOpen: false,
      round: null,
      confirm: false,
      item: null,
    };
  },
  mounted() {
    this.prepareComponent();
  },
  computed: {
    ...mapGetters('rounds', {
      rounds: 'rounds',
    }),
  },
  methods: {
    ...mapActions('rounds', {
      fetch: 'fetch',
      destroy: 'destroy',
    }),
    async prepareComponent() {
      await this.fetchPositions();
      this.isPreparing = false;
    },
    async fetchPositions() {
      this.isLoading = true;
      await this.fetch();
      this.isLoading = false;
    },
    onCreate() {
      this.round = null;
      this.isFormOpen = true;
    },
    async onStored() {
      this.isLoading = true;
      await this.fetch();
      this.isLoading = false;
    },
    onEdit(round) {
      this.round = round;
      this.isFormOpen = true;
    },
    onDestroy(item) {
      this.item = item;
      this.confirm = true;
    },
    async onDestroyConfirmed(confirmed) {
      if (confirmed) {
        await this.destroy(this.item.id);
        this.isLoading = true;
        await this.fetch();
        this.isLoading = false;
      }
    },
    isPending(item) {
      return item.relationships.status.attributes.status_id === 2;
    },
    onDetails(item, detailsType) {
      this.dialogDetail = true;
      this.round = item;
      this.detailsType = detailsType;
    },
  },
};
</script>
