<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs"> </app-breadcrumbs>
    <v-container fluid>
        Wallet
    </v-container>
</div>
</template>

<script>
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";

export default {
    data() {
        return {
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "home"
                    }
                },
                {
                    text: this.$t('dashboard'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "dashboard"
                    }
                },
                {
                    text: "",
                    disabled: true
                }
            ]
        };
    },
    mounted() {
        this.prepareComponent();
    },
    watch: {},
    computed: {},
    methods: {
        prepareComponent() {}
    },
    components: {
        AppBreadcrumbs
    }
};
</script>
