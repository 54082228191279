import axios from "axios";

/**
 * Remember
 * Commit: calls a mutation to update the state.
 * Dispatch: call an action to proccess data and pass the result to a commit.
 */

// Initial state
const state = {
    users: [],
    current: null,
    errors: []
};

// Actions - Backend
const actions = {
    async currentFetch({ commit }) {
        try {
            const response = await axios.get("/api/user");
            commit("setCurrent", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async currentUpdate({ commit }, formData) {
        try {
            await axios.post(`/api/user/update`, formData);
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async currentDestroy({ commit }, formData) {
        try {
            await axios.post(`/api/user/destroy`, formData);
            return true;
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }

        return false;
    },
    async lbFetch({ commit }) {
        try {
            const response = await axios.get("/api/linkbridge/users");
            commit("setUsers", response.data);
        } catch (error) {
            console.log(error);
        }
    }
};

// Mutations - State
const mutations = {
    setUsers(state, users) {
        state.users = users;
    },
    setCurrent(state, data) {
        state.current = data;
    },
    setErrors(state, errors) {
        state.errors = errors;
    }
};

// Getters - Return
const getters = {
    users: state => {
        return state.users;
    },
    current: state => {
        return state.current;
    },
    errors: state => {
        return state.errors;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
