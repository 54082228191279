<style>
.app-tab-lelft {
  text-align: left;
  width: 100%;
}
</style>
<template>
<div>
  <app-breadcrumbs :breadcrumbs="breadcrumbs">
    <app-title-crumb :title="investmentTitle" instructions="">
      <template v-slot:action>
        <app-list-item-logo :logo="investmentLogo"></app-list-item-logo>
      </template>
    </app-title-crumb>
  </app-breadcrumbs>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-tabs vertical>
          <v-tab>
            <div class="app-tab-lelft">
              {{ $t('investment_receipt') }}
            </div>
          </v-tab>
          <v-tab>
            <div class="app-tab-lelft">
              {{ $t('reports') }}
            </div>
          </v-tab>
          <div v-if="false">

            <v-tab>
              <div class="app-tab-lelft">
                {{ $t('certificate') }}
              </div>
            </v-tab>
            <v-tab>
              <div class="app-tab-lelft">
                {{ $t('contract') }}
              </div>
            </v-tab>
          </div>
          <v-tab-item>
            <app-receipt :investment="investment" v-if="investment" />
          </v-tab-item>
          <v-tab-item>
            <app-reports :investment="investment" v-if="investment" />
          </v-tab-item>
          <div v-if="false">
            <v-tab-item>
              <app-receipt />
            </v-tab-item>
            <v-tab-item>
              <app-receipt />
            </v-tab-item>
          </div>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";
import AppListItemLogo from "@/components/atoms/ListItemLogo";
import AppReceipt from "@/components/investments/Receipt";
import AppReports from "@/components/investments/Reports";
export default {
  data() {
    return {
      id: this.$route.params.investment,
      isLoading: false,
      breadcrumbs: [{
          text: this.$t('home'),
          disabled: false,
          to: {
            name: "home"
          },
          exact: true
        },
        {
          text: this.$t('dashboard'),
          disabled: false,
          to: {
            name: "dashboard"
          },
          exact: true
        },
        {
          text: this.$t('my_investments'),
          disabled: false,
          to: {
            name: "profile.investments.index"
          },
          exact: true
        },
        {
          text: "",
          disabled: true,
          to: {
            name: "home"
          }
        }
      ]
    };
  },
  mounted() {
    this.prepareComponent();
  },
  computed: {
    ...mapGetters("investments", {
      investment: "investment"
    }),
    investmentTitle() {
      try {
        const project = this.investment.relationships.round.relationships.project.attributes.name;
        const amount = this.$options.filters.currency(this.investment.attributes.amount);
        return `${project} - ${amount} ${ this.$t('currency_suffix') }`;
      } catch (e) {
        return this.$t('loading');
      }
    },
    investmentLogo() {
      try {
        const project = this.investment.relationships.round.relationships.project.attributes.logo;
        return project;
      } catch (e) {
        return "";
      }
    }

  },
  methods: {
    async prepareComponent() {
      this.isLoading = true;
      await this.fetchInvestment(this.id);
      this.isLoading = false;
    },
    ...mapActions("investments", {
      fetchInvestment: "fetchById"
    })
  },
  components: {
    AppBreadcrumbs,
    AppTitleCrumb,
    AppListItemLogo,
    AppReceipt,
    AppReports
  }
};
</script>
