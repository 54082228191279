<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs">
        <app-title-crumb :title="$t('jobs')" :instructions="$t('my_vacancies_instructions')">
            <template v-slot:action>
                <v-btn color="accent" dark @click="onCreate">
                    {{ $t("vacancy_create_new") }}
                </v-btn>
                <app-job-create :job="job" v-model="isFormOpen" @stored="onStored"></app-job-create>
            </template>
        </app-title-crumb>
    </app-breadcrumbs>
    <v-container fluid>
        <v-skeleton-loader class="mx-auto" type="card-heading, card, actions" v-if="isPreparing" />
        <app-empty-state v-else-if="jobs.length === 0">
            <v-btn color="accent" dark @click="onCreate">
                {{ $t("vacancy_create_new") }}
            </v-btn>
            <app-job-create :job="job" v-model="isFormOpen" @stored="onStored"></app-job-create>
        </app-empty-state>
        <v-row align="center" justify="center" dense v-else>
            <v-col cols="12" sm="10" md="12">
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :loading="isLoading" :headers="headers" :search="search" :items="jobs || []" :items-per-page="5" class="elevation-1">
                        <!-- Title -->
                        <template v-slot:item.attributes.title="{ item }">
                            <router-link :to="{
                                        name: 'admin.jobs.index',
                                        query: { id: item.id }
                                    }" target="_blank">
                                {{ item.attributes.title }}
                            </router-link>
                        </template>
                        <!--/Title -->
                        <!--Project name-->
                        <template v-slot:item.relationships.project.attributes.name="{
                                    item
                                }">
                            <router-link :to="{
                                        name: 'admin.projects.show',
                                        params: {
                                            project:
                                                item.relationships.project.id
                                        }
                                    }" target="_blank">
                                {{
                                        item.relationships.project.attributes
                                            .name
                                    }}
                            </router-link>
                        </template>
                        <!--/Project name-->
                        <!-- Creation date -->
                        <template v-slot:item.attributes.created_at="{ item }">
                            {{ item.attributes.created_at | date }}
                        </template>
                        <!--/Creation date -->
                        <!--Employees count-->
                        <template v-slot:item.attributes.applications_count="{
                                    item
                                }">
                            <v-chip class="ma-2" color="primary" text-color="white" link :disabled="
                                        item.attributes.applications_count === 0
                                    " :to="{
                                        name: 'admin.jobs.applications.index',
                                        params: { id: item.id }
                                    }">
                                <v-avatar left>
                                    <v-icon>mdi-account-circle</v-icon>
                                </v-avatar>
                                {{ item.attributes.applications_count }}
                            </v-chip>
                        </template>
                        <!--/Employees count-->
                        <!--Actions-->
                        <template v-slot:item.id="{ item }">
                            <v-btn class="ma-2" outlined x-small fab color="primary" @click="onEdit(item)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn class="ma-2" outlined x-small fab color="primary" @click="onDestroy(item)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <!--/Actions-->
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <app-confirm v-model="confirm" @confirmed="onDestroyConfirmed" :text="$t('vacancy_delete_question')"></app-confirm>
    </v-container>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";
import AppJobCreate from "./Create";
import AppConfirm from "@/components/molecules/Confirm";
import AppEmptyState from '@/components/atoms/EmptyState';

export default {
    data() {
        return {
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "home"
                    }
                },
                {
                    text: this.$t('dashboard'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "dashboard"
                    }
                },
                {
                    text: "",
                    disabled: true
                }
            ],
            search: "",
            headers: [{
                    text: this.$t("name"),
                    value: "attributes.title"
                },
                {
                    text: this.$t("company"),
                    value: "relationships.project.attributes.name"
                },
                {
                    text: this.$t("location"),
                    value: "attributes.location"
                },
                {
                    text: this.$t("publication_date"),
                    value: "attributes.created_at"
                },
                {
                    text: this.$t("status"),
                    value: "relationships.status.attributes.name"
                },
                {
                    text: this.$t("applications"),
                    value: "attributes.applications_count"
                },
                {
                    text: this.$t("actions"),
                    value: "id"
                }
            ],
            isPreparing: true,
            isLoading: false,
            isFormOpen: false,
            job: null,
            confirm: false,
            item: null
        };
    },
    computed: {
        ...mapGetters("jobs", {
            jobs: "jobsAdmin"
        }),
        jobId() {
            return this.$route.query.id || null;
        }
    },
    async mounted() {
        await this.prepareComponent();
        this.isPreparing = false;
    },
    methods: {
        async prepareComponent() {
            this.isLoading = true;
            await this.fetch();
            if (this.jobId) {
                this.onEdit(
                    this.findJob(this.jobId)
                );
            }
            this.isLoading = false;
        },
        findJob(id) {
            return this.jobs.find(job => job.id === parseInt(id));
        },
        ...mapActions("jobs", {
            fetch: "adminFetch",
            destroy: "destroy"
        }),
        onEdit(job) {
            this.job = job;
            this.isFormOpen = true;
        },
        onCreate() {
            this.job = null;
            this.isFormOpen = true;
        },
        async onStored() {
            this.isLoading = true;
            await this.fetch();
            this.isLoading = false;
        },
        onDestroy(item) {
            this.item = item;
            this.confirm = true;
        },
        async onDestroyConfirmed(confirmed) {
            if (confirmed) {
                await this.destroy(this.item.id);
                this.isLoading = true;
                await this.fetch();
                this.isLoading = false;
            }
        }
    },
    components: {
        AppBreadcrumbs,
        AppTitleCrumb,
        AppJobCreate,
        AppConfirm,
        AppEmptyState
    }
};
</script>
