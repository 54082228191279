import axios from '../../plugins/axios';

/**
 * Remember
 * Commit: calls a mutation to update the state.
 * Dispatch: call an action to proccess data and pass the result to a commit.
 */

// Initial state
const state = {
    project: null,
    projects: [],
    fetched: false,
    isProcessing: false,
    isSaving: false,
    stash: null,
    errors: [],
    admin: {
        projects: null,
    },
};

// Actions - Backend
const actions = {
    async fetch({ commit }) {
        try {
            const response = await axios.get('/api/projects/all');
            commit('setProjects', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async fetchAdmin({ commit, rootState }) {
        axios.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${rootState.auth.token}`;
        try {
            const response = await axios.get('/api/admin/projects');
            commit('setAdminProjects', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async lbFetch({ commit, rootState }) {
        axios.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${rootState.auth.token}`;
        try {
            const response = await axios.get('/api/linkbridge/projects');
            commit('setProjects', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async lbFetchById({ commit, rootState }, projectId) {
        commit('setProject', null);
        axios.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${rootState.auth.token}`;
        try {
            const response = await axios.get(
                `/api/linkbridge/projects/${projectId}`
            );
            commit('setProject', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async lbUpdate({ commit, rootState }, { projectId, statusId }) {
        axios.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${rootState.auth.token}`;
        try {
            const response = await axios.post(
                `/api/linkbridge/projects/${projectId}`,
                {
                    status_id: statusId,
                }
            );
            commit('setProject', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async fetchById({ commit, rootState }, projectId) {
        commit('setProject', null);
        axios.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${rootState.auth.token}`;
        try {
            const response = await axios.get(`/api/projects/${projectId}`);
            commit('setProject', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async store({ commit, rootState }, projectData) {
        commit('setSaving', true);
        axios.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${rootState.auth.token}`;
        try {
            const response = await axios.post(
                '/api/admin/projects',
                projectData
            );
            commit('setStash', null);
            commit('setProject', response.data);
            commit('setSaving', false);
            commit('setFetched', false);
            return response.data;
        } catch (error) {
            if (error.response.status === 422) {
                commit('setErrors', error.response.data.errors);
            } else {
                console.log(error);
            }
            commit('setSaving', false);
        }
    },
    stash({ commit }, projectData) {
        commit('setStash', projectData);
    },
    async update({ commit, rootState }, projectData) {
        commit('setSaving', true);
        try {
            const token = rootState.auth.token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            await axios.post(
                `/api/admin/projects/${projectData.id}/update`,
                projectData
            );
            commit('setStash', null);
            commit('setSaving', false);
        } catch (error) {
            commit('setSaving', false);
        }
    },
    async delete({ commit, dispatch, rootState }, projectId) {
        commit('setProcessing', true);
        try {
            axios.defaults.headers.common[
                'Authorization'
            ] = `Bearer ${rootState.auth.token}`;
            await axios.post(`/api/admin/projects/${projectId}/destroy`);
            commit('setProject', null);
            commit('setProcessing', false);
            dispatch('fetchAdmin');
        } catch (error) {
            commit('setProcessing', false);
        }
    },
    async storeLogo({ commit, rootState }, { id, file }) {
        axios.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${rootState.auth.token}`;

        try {
            const response = await axios.post(
                `/api/admin/projects/${id}/logo`,
                file,
                {
                    headers: {
                        'Content-type': 'application/json',
                    },
                }
            );
            commit('setProjectLogo', response.data.attributes.logo);
        } catch (error) {
            if (error.response.status === 422) {
                commit('setErrors', error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async destroyLogo({ commit, rootState }, projectId) {
        axios.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${rootState.auth.token}`;
        try {
            await axios.post(`/api/admin/projects/${projectId}/logo/destroy`);
            commit('setProjectLogo', null);
        } catch (error) {
            if (error.response.status === 422) {
                commit('setErrors', error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
};

// Mutations - State
const mutations = {
    setProjects(state, projects) {
        state.fetched = true;
        state.projects = projects;
    },
    setAdminProjects(state, projects) {
        state.admin.projects = projects;
    },
    setLinkBridgeProjects(state, projects) {
        state.linkbridge.projects = projects;
    },
    setProject(state, project) {
        state.project = project;
    },
    setProjectLogo(state, logo) {
        state.project.attributes.logo = logo;
    },
    setFetched(state, fetched) {
        state.fetched = fetched;
    },
    setProcessing(state, isProcessing) {
        state.isProcessing = isProcessing;
    },
    setSaving(state, isSaving) {
        state.isSaving = isSaving;
    },
    setStash(state, data) {
        state.stash = data;
    },
    setErrors(state, errors) {
        state.errors = errors;
    },
};

// Getters - Return
const getters = {
    project: (state) => {
        return state.project;
    },
    projects: (state) => {
        return state.projects;
    },
    projectsAdmin: (state) => {
        return state.admin.projects;
    },
    isProcessing(state) {
        return state.isProcessing;
    },
    isSaving(state) {
        return state.isSaving;
    },
    isStashed: (state) => {
        return state.stash !== null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
