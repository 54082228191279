<template>
  <div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs"></app-breadcrumbs>
    <v-container fluid>
      <v-row justify="center">
        <v-col lg="8">
          <!--Investments: Start-->
          <app-subtitle :title="$t('my_investments')" :link="$t('view_all')" route="investments"></app-subtitle>
          <v-card class="mb-6" :loading="isLoading">
            <v-skeleton-loader type="card" v-if="investments === null"></v-skeleton-loader>
            <v-list two-line v-else-if="investments.length > 0">
              <v-card class="mx-auto" flat v-for="(item, index) in investments" :key="index">
                <v-list-item two-line>
                  <app-list-item-logo :logo="investmentLogo(item)"></app-list-item-logo>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5">
                      {{ investmentTitle(item) }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ investmentAmount(item) | currency }} |
                      {{ investmentCreatedAt(item) | date }} |
                      {{ investmentStatus(item) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn text color="primary" @click="onClickedInvestment(item)">
                      {{ $t('detail') }}
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider v-if="index + 1 < investments.length"></v-divider>
              </v-card>
            </v-list>
            <v-list-item three-line v-else>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  {{ $t('start_investing') }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('start_investing_msg') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="accent" :to="{ name: 'home' }">
                {{ $t('view_opportunities') }}
              </v-btn>
            </v-card-actions>
          </v-card>
          <!--Investments: End-->
          <!--Jobs: Start-->
          <app-subtitle :title="$t('last_job_applications')" :link="$t('view_all')" route="profile/jobs"></app-subtitle>
          <v-card class="mb-6" :loading="isLoading">
            <v-skeleton-loader type="card" v-if="applications === null"></v-skeleton-loader>
            <v-list two-line v-else-if="applications.length > 0">
              <v-card class="mx-auto" flat v-for="(item, index) in applications" :key="index">
                <v-list-item two-line>
                  <app-list-item-logo :logo="applicationLogo(item)"></app-list-item-logo>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5">
                      {{ applicationTitle(item) }} @ {{ applicationCompany(item) }}
                    </v-list-item-title>
                    <v-list-item-subtitle> {{ applicationLocation(item) }} -
                      {{
                                                  applicationCreatedAt(item) | date
                                              }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn text color="primary" @click="onClickedApplication(item)">
                      {{ $t('detail') }}
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider v-if="index + 1 < applications.length"></v-divider>
              </v-card>
            </v-list>
            <v-list-item three-line v-else>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  {{ $t('search_vacancies') | uppercase }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('search_vacancies_msg') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn text color="accent" :to="{ name: 'jobs.index' }">
                {{ $t('view_jobs') }}
              </v-btn>
            </v-card-actions>
          </v-card>
          <!--Jobs: End-->
          <!--Projects: Start-->
          <app-subtitle :title="$t('my_companies')" :link="$t('view_all')" :route="{ name: 'admin.projects.index' }"></app-subtitle>
          <v-card v-if="!isSubscribed" :loading="isLoading">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  {{ $t('basic_plan') | uppercase }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('update_to') }}
                  <b>{{ $t('pro_plan') | uppercase }}</b>
                  {{ $t('to_publish_your_first_project') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn :to="{ name: 'plans.index' }" text color="accent">
                {{ $t('update_to_plan_pro') }}
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-else-if="projects.length">
            <v-list two-line>
              <v-list-item-group>
                <template v-for="(item, index) in projects">
                  <app-project-item :item="item" route="admin.projects.show" :last="index + 1 < projects.length" :key="`${index}-${item.attributes.name}`"></app-project-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-skeleton-loader v-else type="card"></v-skeleton-loader>
          <!--Projects: End-->
        </v-col>
        <v-col lg="3">
          <app-subtitle :title="$t('my_profile')" :link="$t('update_profile')" :route="{ name: 'profile.edit' }"></app-subtitle>
          <v-card class="mx-auto" :loading="isLoading">
            <v-list-item>
              <v-list-item-avatar color="white">
                <v-img :src="avatar"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  {{ $t('hello') }},
                  {{
                                          profile.attributes
                                              ? profile.attributes.name
                                              : ''
                                      }}!</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list two-line>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                                          user ? user.attributes.email : ''
                                      }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>
  
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                                          profile.attributes
                                              ? profile.attributes.phone
                                              : ''
                                      }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-lock-question</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>********</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-star-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('subscription_type') }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t('plan') }}
                    {{ planName | capitalize }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
  </template>
  
  <script>
  import {
    mapActions,
    mapGetters
  } from 'vuex';
  import AppBreadcrumbs from '@/components/organisms/Breadcrumbs';
  import AppProjectItem from '@/components/molecules/ProjectItem';
  import AppSubtitle from '@/components/molecules/Subtitle';
  import AppListItemLogo from '@/components/atoms/ListItemLogo';
  export default {
    data() {
      return {
        breadcrumbs: [{
            text: this.$t('home'),
            disabled: false,
            exact: true,
            to: {
              name: 'home',
            },
          },
          {
            text: this.$t('dashboard'),
            disabled: true,
            to: {
              name: 'dashboard',
            },
          },
        ],
        isLoading: true,
      };
    },
    computed: {
      ...mapGetters({
        placeholders: "placeholders",
      }),
      ...mapGetters('projects', {
        allProjects: 'projectsAdmin',
      }),
      ...mapGetters('investments', {
        allInvestments: 'investments',
      }),
      ...mapGetters('applications', {
        allApplications: 'applications',
      }),
      ...mapGetters('stripe', {
        planName: 'planName',
        isSubscribed: 'subscribed',
        subscription: 'subscription',
      }),
      ...mapGetters('auth', {
        user: 'user',
      }),
      ...mapGetters('profile', {
        profile: 'profile',
      }),
      projects() {
        return this.allProjects ? this.allProjects.slice(0, 3) : [];
      },
      investments() {
        return this.allInvestments ? this.allInvestments.slice(0, 3) : [];
      },
      applications() {
        return this.allApplications ? this.allApplications.slice(0, 3) : [];
      },
      avatar() {
        try {
          let avatar = this.profile.attributes.photo;
          return avatar === null ? this.placeholders.profile : avatar;
        } catch (error) {
          return this.placeholders.profile;
        }
      }
    },
    mounted() {
      this.prepareComponent();
    },
    methods: {
      ...mapActions('projects', {
        fetchAdmin: 'fetchAdmin',
      }),
      ...mapActions('profile', {
        fetchProfile: 'fetch',
      }),
      ...mapActions('applications', {
        fetchApplications: 'fetchUserApplications',
      }),
      ...mapActions('investments', {
        fetchInvestments: 'fetch',
      }),
      ...mapActions('auth', {
        fetchUser: 'fetchUser',
      }),
      async prepareComponent() {
        this.isLoading = true;
        await Promise.all([
          this.fetchAdmin(),
          this.fetchUser(),
          this.fetchApplications(),
          this.fetchInvestments(),
          this.fetchProfile(),
        ]);
        this.isLoading = false;
      },
      applicationLogo(application) {
        try {
          let logo = application.relationships.job.relationships.project.attributes.logo;
          return logo ? logo : this.placeholders.company;
        } catch (error) {
          return this.placeholders.company;
        }
      },
      applicationTitle(application) {
        return application.relationships.job.attributes.title;
      },
      applicationCompany(application) {
        return application.relationships.job.relationships.project.attributes.name;
      },
      applicationLocation(application) {
        return application.relationships.job.attributes.location;
      },
      applicationCreatedAt(application) {
        return application.relationships.job.attributes.created_at;
      },
      investmentLogo(investment) {
        try {
          let logo = investment.relationships.round.relationships.project.attributes.logo;
          return logo ? logo : this.placeholders.company;
        } catch (error) {
          return this.placeholders.company;
        }
      },
      onClickedApplication(application) {
        this.$router.push({
          name: 'jobs.show',
          params: {
            id: application.relationships.job.id,
          },
        });
      },
      investmentTitle(investment) {
        return investment.relationships.round.relationships.project.attributes.name;
      },
      investmentProject(investment) {
        return investment.relationships.round.relationships.project.attributes.name;
      },
      investmentLocation(investment) {
        return investment.relationships.round.attributes.location;
      },
      investmentCreatedAt(investment) {
        return investment.relationships.round.attributes.created_at;
      },
      investmentAmount(investment) {
        return investment.attributes.amount;
      },
      investmentStatus(investment) {
        return investment.relationships.status.attributes.name;
      },
      onClickedInvestment(investment) {
        this.$router.push({
          name: 'profile.investments.show',
          params: {
            investment: investment.id,
          },
        });
      },
    },
    components: {
      AppBreadcrumbs,
      AppSubtitle,
      AppProjectItem,
      AppListItemLogo,
    },
  };
  </script>
  