<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs">
        <app-title-crumb :title="$t('job_applications')" :instructions="$t('job_applications_msg')"></app-title-crumb>
    </app-breadcrumbs>
    <v-container fluid>
        <v-skeleton-loader class="mx-auto" type="card-heading, card, actions" v-if="applications === null"></v-skeleton-loader>
        <app-empty-state v-else-if="applications.length === 0"></app-empty-state>
        <v-row dense v-else>
            <v-col v-for="application in applications" :key="application.id" :cols="4">
                <v-card class="mx-auto" outlined>
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="overline mb-4">
                                {{ applicationProject(application) }}
                            </div>
                            <v-list-item-title class="headline mb-1">
                                {{ applicationTitle(application) }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{
                                    applicationCreatedAt(application) | date
                                    }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <app-list-item-logo :logo="applicationLogo(application)"></app-list-item-logo>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn text :to="{
                                    name: 'jobs.show',
                                    params: { id: 1 }
                                }">
                            <v-icon left>
                                mdi-details
                            </v-icon>
                            {{ $t('detail') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";
import AppListItemLogo from "@/components/atoms/ListItemLogo";
import AppEmptyState from "@/components/atoms/EmptyState";

export default {
    data() {
        return {
            isLoading: false,
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "home"
                    }
                },
                {
                    text: this.$t('dashboard'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "dashboard"
                    }
                },
                {
                    text: "",
                    disabled: true,
                    to: {
                        name: "home"
                    }
                }
            ]
        };
    },
    computed: {
        ...mapGetters("applications", {
            applications: "applications"
        })
    },
    mounted() {
        this.prepareComponent();
    },
    methods: {
        async prepareComponent() {
            this.isLoading = true;
            await this.fetchApplications();
            this.isLoading = false;
        },
        ...mapActions("applications", {
            fetchApplications: "fetchUserApplications"
        }),
        applicationLogo(application) {
            try {
                let logo = application.relationships.job.relationships.project.attributes.logo;
                return logo ? logo: this.placeholders.company;
            } catch (error) {
                return this.placeholders.company;
            }
        },
        applicationTitle(application) {
            return application.relationships.job.attributes.title;
        },
        applicationProject(application) {
            return application.relationships.job.relationships.project.attributes.name;
        },
        applicationLocation(application) {
            return application.relationships.job.attributes.location;
        },
        applicationCreatedAt(application) {
            return application.relationships.job.attributes.created_at;
        },
    },
    components: {
        AppBreadcrumbs,
        AppTitleCrumb,
        AppListItemLogo,
        AppEmptyState
    }
};
</script>
