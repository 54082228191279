import axios from '../../plugins/axios';

/**
 * Remember
 * Commit: calls a mutation to update the state.
 * Dispatch: call an action to proccess data and pass the result to a commit.
 */

// Initial state
const state = {
    profile: {},
    documents: {},
    errors: [],
};

// Actions
const actions = {
    async fetch({ commit }) {
        try {
            const response = await axios.get(`/api/profile`);
            commit('setProfile', response.data);
        } catch (error) {
            const { response } = error;
            if (response.status === 404) {
                console.log(error);
            }
        }
    },
    async update({ commit }, profileData) {
        try {
            await axios.post(`/api/profile`, profileData);
        } catch (error) {
            if (error.response.status === 422) {
                commit('setErrors', error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async storeIDFiles({ commit }, { dataFiles }) {
        try {
            const response = await axios.post(
                '/api/profile/documents/id',
                dataFiles
            );
            commit('setDocuments', response.data);
        } catch (error) {
            if (error.status === 422) {
                commit('setErrors', error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async storeProfilePhoto({ commit }, file) {
        try {
            const response = await axios.post(
                '/api/profile/documents/photo',
                file
            );
            commit('setProfilePhoto', response.data.attributes.photo);
        } catch (error) {
            if (error.status === 422) {
                commit('setErrors', error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async destroyProfilePhoto({ commit }) {
        try {
            await axios.post('/api/profile/documents/photo/destroy');
            commit('setProfilePhoto', null);
        } catch (error) {
            if (error.status === 422) {
                commit('setErrors', error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
};

// Mutations
const mutations = {
    setProfile(state, profile) {
        state.profile = profile;
    },
    setProfilePhoto(state, photo) {
        state.profile.attributes.photo = photo;
    },
    setDocuments(state, documents) {
        state.documents = documents;
    },
    setErrors(state, errors) {
        state.errors = errors;
    },
};

// Getters
const getters = {
    profile: (state) => state.profile,
    documents: (state) => state.documents,
    errors: (state) => state.errors,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
