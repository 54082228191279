<template>
<app-http-error></app-http-error>
</template>

<script>
import AppHttpError from '@/components/atoms/HttpError';

export default {
    data: () => ({}),
    components: {
        AppHttpError
    },
};
</script>
