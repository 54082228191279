import axios from '../plugins/axios';
import * as storage from "./storage";

// Initial state
const state = {
    language: "es",
    breadcrumbs: [],
    contract_types: [],
    education_levels: [],
    employment_types: [],
    errors: [],
    industries: [],
    investment_types: [],
    isProcessing: false,
    schedule_types: [],
    workspace_types: [],
    benefits: [
        "Vacaciones superiores a las de ley",
        "Vales de despensa",
        "Vales de restaurante",
        "Caja de ahorro",
        "Seguro de gastos médicos",
        "Seguro dental",
        "Trabajar desde casa",
        "Servicio de comedor",
        "Seguro de la vista",
        "Días de maternidad superiores a los de la ley",
        "Horarios flexibles",
        "Estacionamiento gratuito",
        "Estacionamiento de la empresa",
        "Seguro de gastos médicos mayores",
        "Seguro de vida",
        "Días por enfermedad",
        "Servicio de comedor con descuento",
        "Días de paternidad superiores a los de la ley",
        "Programa de referidos",
        "Teléfono de la empresa",
        "Descuentos y precios preferenciales",
        "Bebidas gratis",
        "Otro"
    ],
    contracts_periods: ["Hora(s)", "Día(s)", "Semana(s)", "Mes(es)"],
    placeholders: {
        profile: require('../assets/images/profile_pic_placeholder.svg'),
        profile_bg: require('../assets/images/cover_placeholder.svg'),
        company: require('../assets/images/lb_placeholder_project.svg'),
        company_bg: require('../assets/images/cover_placeholder.svg'),
        page_empty: require('../assets/images/undraw/undraw_empty_xct9.svg'),
        page_not_found: require('../assets/images/undraw/undraw_page_not_found_re_e9o6.svg'),
        logo_footer: require('../assets/images/logo_footer.svg'),
        logo_navbar: require('../assets/images/logo.svg'),
        logo_login: require('../assets/images/lb_logo_square.svg'),
        login_bg: require('../assets/images/login_bg.jpg'),
    }
};

// Actions
const actions = {
    setLanguage({ commit }, language) {
        commit("setLanguage", language);
    },
    async fetchContractTypes({ commit }) {
        try {
            const response = await axios.get(`/api/contract-types`);
            commit("setContractTypes", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async fetchEmploymentTypes({ commit }) {
        try {
            const response = await axios.get(`/api/employment-types`);
            commit("setEmploymentTypes", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async fetchIndustries({ commit }) {
        try {
            const response = await axios.get(`/api/industries`);
            commit("setIndustries", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async fetchInvestmentTypes({ commit }) {
        try {
            const response = await axios.get(`/api/investment-types`);
            commit("setInvestmentTypes", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async fetchScheduleTypes({ commit }) {
        try {
            const response = await axios.get(`/api/schedule-types`);
            commit("setScheduleTypes", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async fetchEducationLevels({ commit }) {
        try {
            const response = await axios.get(`/api/education-levels`);
            commit("setEducationLevels", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async fetchWorkspaceTypes({ commit }) {
        try {
            const response = await axios.get(`/api/workspace-types`);
            commit("setWorkspaceTypes", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async fetchBenefitTypes({ commit }) {
        try {
            const response = await axios.get(`/api/benefit-types`);
            commit("setBenefitTypes", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    setBreadcrumbs({ commit }, breadcrumbs) {
        commit("setBreadcrumbs", breadcrumbs);
    }
};

// Mutations
const mutations = {
    setContractTypes(state, data) {
        state.contract_types = data;
    },
    setEmploymentTypes(state, data) {
        state.employment_types = data;
    },
    setScheduleTypes(state, data) {
        state.schedule_types = data;
    },
    setEducationLevels(state, data) {
        state.education_levels = data;
    },
    setIndustries(state, data) {
        state.industries = data;
    },
    setInvestmentTypes(state, data) {
        state.investment_types = data;
    },
    setWorkspaceTypes(state, data) {
        state.workspace_types = data;
    },
    setErrors(state, errors) {
        state.errors = errors;
    },
    setBreadcrumbs(state, breadcrumbs) {
        state.breadcrumbs = breadcrumbs;
    },
    setProcessing(state, isProcessing) {
        state.isProcessing = isProcessing;
    },
    setBenefitTypes(state, benefits) {
        state.benefits = benefits;
    },
    setLanguage(state, language) {
        storage.local.setItem("language", language);
        state.language = language;
    }
};

// Getters
const getters = {
    contract_types: state => state.contract_types,
    education_levels: state => state.education_levels,
    employment_types: state => state.employment_types,
    industries: state => state.industries,
    investment_types: state => state.investment_types,
    schedule_types: state => state.schedule_types,
    workspace_types: state => state.workspace_types,
    benefits: state => state.benefits,
    contracts_periods: state => {
        return state.contracts_periods.map((value, index) => {
            return { value: index + 1, text: value };
        });
    },
    placeholders: state => state.placeholders,
    language: state => {
        return storage.local.getItem("language") || state.language;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
