<template>
<v-card flat :loading="isLoading">
    <v-card-title>
        {{ $t('close_account') }}
    </v-card-title>
    <v-card-subtitle>
        {{ $t('close_account_msg') }}
    </v-card-subtitle>
    <v-card-text>
        <v-form>
            <v-text-field type="password" v-model="form.password" :error-messages="errors['form.password']" :label="$t('password')"></v-text-field>
        </v-form>
    </v-card-text>
    <v-card-actions>
        <v-btn text class="mr-4" @click="onSubmit" :loading="isProcessing" :disabled="isLoading">
            {{ $t('close_account') }}
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import {
    validationMixin
} from "vuelidate";
import {
    required
} from "vuelidate/lib/validators";
import {
    vueFrontErrors,
    vueRulesMessages
} from "../../plugins/vuelidate";
export default {
    mixins: [validationMixin],
    validations: {
        form: {
            password: {
                required: required
            }
        }
    },
    data() {
        return {
            isLoading: false,
            isProcessing: false,
            form: {
                password: null
            },
            errors: {
                "form.email": [],
                "form.password": []
            },
            rules: {
                ...vueRulesMessages
            },
            validate: false
        };
    },
    mounted() {
        this.prepareComponent();
    },
    watch: {
        form: {
            handler() {
                this.validate = true;
            },
            deep: true
        },
        apiErrors: {
            handler() {
                this.validate = true;
            },
            deep: true
        },
        validationForm(newValue) {
            if (newValue === false) {
                return;
            }

            this.validate = false;
            this.vueFrontErrors();
        }
    },
    computed: {
        ...mapGetters("users", {
            user: "current",
            apiErrors: "errors"
        }),
        validationForm() {
            return this.validate;
        }
    },
    methods: {
        vueFrontErrors: vueFrontErrors,
        async prepareComponent() {
            this.isLoading = false;
        },
        ...mapActions("users", {
            destroy: "currentDestroy"
        }),
        ...mapActions("auth", {
            logout: "logout"
        }),
        async onSubmit() {
            this.$v.form.$touch();
            let invalid = this.$v.form.$invalid;
            this.validate = true;

            if (invalid) {
                return;
            }

            this.isProcessing = true;

            const formUser = {
                data: {
                    type: "users",
                    attributes: {
                        password: this.form.password
                    }
                }
            };
            const success = await this.destroy(formUser);

            if (success) {
                this.logout();
            }

            this.isProcessing = false;
        }
    }
};
</script>
