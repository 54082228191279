<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs">
        <app-title-crumb :title="$t('payment_method_update')" :instructions="$t('payment_method_update_instructions')"></app-title-crumb>
    </app-breadcrumbs>
    <v-container fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="10" md="7">
                <!-- <h1>Inversión en {{ project.name }}</h1> -->
                <v-card>
                    <v-card-title>
                        1. {{ $t('payment_method') }}
                    </v-card-title>
                    <v-card-subtitle>
                        {{ $t('payment_method_update_msg') }}
                    </v-card-subtitle>
                    <v-card-text>
                        <app-payment-methods ref="appPaymentMethods" v-model="paymentMethod" @onloaded="onPaymentMethodLoaded"></app-payment-methods>
                    </v-card-text>
                    <v-card-actions v-if="isLoaded">
                        <v-btn color="accent" @click="onUpdate" :disabled="lockSubmit" :loading="lockSubmit">
                            {{ $t('update') }}
                        </v-btn>
                    </v-card-actions>
                    <!--/Actions-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";
import AppPaymentMethods from "@/components/stripe/PaymentMethods";
export default {
    data() {
        return {
            isLoaded: false,
            lockSubmit: false,
            paymentMethod: null,
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "home"
                    }
                },
                {
                    text: this.$t('dashboard'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "dashboard"
                    }
                },
                {
                    text: this.$t('my_subscription'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "subscription.show"
                    }
                },
                {
                    text: "",
                    disabled: true,
                    to: {
                        name: "home"
                    }
                }
            ]
        };
    },
    computed: {},
    methods: {
        ...mapActions("stripe", {
            update: "updateDefaultPaymentMethod"
        }),
        async onUpdate() {
            this.lockSubmit = true;
            await this.$refs.appPaymentMethods.getPaymentMethod();

            if (this.paymentMethod !== 0) {
                await this.update(this.paymentMethod);
            }

            this.lockSubmit = false;
        },
        onPaymentMethodLoaded(isLoaded) {
            this.isLoaded = isLoaded;
        }
    },
    components: {
        AppBreadcrumbs,
        AppTitleCrumb,
        AppPaymentMethods
    }
};
</script>
