import axios from "axios";

// Initial state
const state = {
    errors: [],
    profile: {}
};

// Actions
const actions = {
    async fetchProfile({ commit }) {
        try {
            const response = await axios.get(
                `/api/profile/professional-profile`
            );
            commit("setProfile", response.data);
        } catch (error) {
            const { response } = error;
            if (response.status === 404) {
                // Show tooltip.
            } else {
                console.log(error);
            }
        }
    },
    async storeProfile({ commit }, formData) {
        try {
            await axios.post(
                `/api/profile/professional-profile`,
                formData
            );
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async storeContractTypes({ commit }, formData) {
        try {
            await axios.post(
                `/api/profile/contract-types`,
                formData
            );
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async storeEmploymentTypes({ commit }, formData) {
        try {
            await axios.post(
                `/api/profile/employment-types`,
                formData
            );
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async storeScheduleTypes({ commit }, formData) {
        try {
            await axios.post(
                `/api/profile/schedule-types`,
                formData
            );
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async storeSocialNetworks({ commit }, formData) {
        try {
            await axios.post(
                `/api/profile/social-networks`,
                formData
            );
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async storeWorkspaceTypes({ commit }, formData) {
        try {
            await axios.post(
                `/api/profile/workspace-types`,
                formData
            );
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    }
};

// Mutations
const mutations = {
    setProfile(state, profile) {
        state.profile = profile;
    },
    setErrors(state, errors) {
        state.errors = errors;
    }
};

// Getters
const getters = {
    profile: state => state.profile,
    errors: state => state.errors
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
