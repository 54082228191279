<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs">
        <app-title-crumb :title="$t('new_company')" :instructions="$t('new_company_msg')"></app-title-crumb>
    </app-breadcrumbs>
    <v-container fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="10" md="8">
                <app-project-create></app-project-create>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppProjectCreate from "@/components/projects/project/Create";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";

export default {
    data() {
        return {
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "home"
                    }
                },
                {
                    text: "",
                    disabled: true,
                    to: {
                        name: "home"
                    }
                }
            ]
        };
    },
    computed: {},
    components: {
        AppBreadcrumbs,
        AppProjectCreate,
        AppTitleCrumb
    }
};
</script>
