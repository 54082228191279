<template>
    <v-list-item v-if="project" class="mt-n2">
        <v-list-item-content class="pa-0 py-4">
            <v-list-item-title class="headline">{{
                project.name
            }}</v-list-item-title>
            <v-list-item-subtitle v-if="project.user"
                >{{ project.user.name }} |
                {{ project.location }}
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="ma-0">
            <slot name="action"></slot>
        </v-list-item-action>
    </v-list-item>
    <v-skeleton-loader
        class="pa-0"
        type="card-heading"
        v-else
    ></v-skeleton-loader>
</template>

<script>
export default {
    props: ["project"]
};
</script>
