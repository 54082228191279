import { storageFactory } from 'storage-factory';

export const ls = storageFactory(() => localStorage);
export const ss = storageFactory(() => sessionStorage);

export const local = {
    setItem(key, value) {
        value = JSON.stringify(value);
        ls.setItem(key, value);
    },
    getItem(key, defaultValue) {
        let value = ls.getItem(key, value);
        if (value === null || value === 'undefined' || value === '') {
            value = defaultValue;
        } else {
            try {
                value = JSON.parse(value);
            } catch(e) {
                value = defaultValue;
            }
        }
        return value;
    },
    removeItem(key) {
        ls.removeItem(key);
    },
    removeItems(items) {
        items.forEach(element => ls.removeItem(element));
    },
    clear() {
        ls.clear();
    }
};
