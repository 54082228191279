<template>
<v-dialog v-model="dialog" max-width="350">
    <v-card>
        <v-card-title class="headline">{{
                title || $t('confirm')
            }}</v-card-title>
        <slot>
            <v-card-text>
                {{ text || $t('do_you_wish_continue') }}
            </v-card-text>
        </slot>
        <v-card-actions>
            <v-btn width="100%" color="accent" @click="onConfirm">
                {{ $t('confirm') }}
            </v-btn>
        </v-card-actions>
        <v-card-actions>
            <v-btn width="100%" outlined @click="onCancel">
                {{ $t('cancel') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    props: ["value", "data", "title", "text"],
    computed: {
        dialog: {
            // getter
            get: function () {
                return this.value;
            },
            // setter
            set: function (newValue) {
                this.$emit("confirmed", false);
                this.$emit("input", newValue);
            }
        }
    },
    methods: {
        onConfirm() {
            this.dialog = false;
            this.$emit("confirmed", true);
        },
        onCancel() {
            this.dialog = false;
            this.$emit("confirmed", false);
        }
    }
};
</script>
