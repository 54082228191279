export const vueApiErrors = (name, errors) => {
    const result = [];

    if (errors.length != 0) {
        for (const index in errors) {
            const error = errors[index];
            const path = index.split(".");
            console.log(path);
            console.log(error);
            if (name === path[path.length - 1]) {
                result.push(error[0]);
                delete errors[index];
            }
        }
    }

    return result;
};

export const vueRulesMessages = {
    maxLength: "Este campo excede el máximo de caracteres",
    minLength: "Este campo no cumple con el mínimo de caracteres",
    numeric: "Este campo es numérico",
    required: "Este campo es requerido",
    url: "Este campo debe ser un url valido: https://example.com/profile",
    sameAsPassword: "Este campo debe ser igual al campo de nueva contraseña",
    email: "Este campo debe ser un email valido"
};

export const vueFrontErrors = function() {
    if (this.errors === undefined) {
        throw new Error("frontErrors: Undefined component errors");
    }

    if (this.rules === undefined) {
        throw new Error("frontErrors: Undefined component rules");
    }

    if (this.apiErrors === undefined) {
        throw new Error("frontErrors: Undefined component apiErrors");
    }

    for (let path in this.errors) {
        this.errors[path] = [];
    }

    const params = this.$v.$flattenParams();

    params.forEach(param => {
        let rule = param.name;
        let errorsPath = param.path.join(".");

        param.path.reduce((previous, current, currentIndex) => {
            let $v;
            if (previous == null) {
                $v = this.$v[current];
            } else {
                $v = previous[current];
            }

            if (currentIndex === param.path.length - 1) {
                const errors = vueApiErrors(current, this.apiErrors);

                if (errors.length) {
                    this.errors[errorsPath] = errors;
                }

                if ($v.$dirty) {
                    try {
                        !$v[rule] &&
                            this.errors[errorsPath].push(this.rules[rule]);
                    } catch (e) {
                        console.log("vueFrontErrors:", errorsPath);
                        throw e;
                    }
                }
            }

            return $v;
        }, null);
    });
};

export const curpCustomValidation = value => {
    if (!value) return false;

    let curpRegex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    let validate = value.match(curpRegex);

    if (!validate) return false;

    //Validar que coincida el dígito verificador
    function digitVerifier(curp17) {
        //Fuente https://consultas.curp.gob.mx/CurpSP/
        let dictionary = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";
        let lngPlus = 0.0;
        let lngDigit = 0.0;

        for (let i = 0; i < 17; i++)
            lngPlus = lngPlus + dictionary.indexOf(curp17.charAt(i)) * (18 - i);
        lngDigit = 10 - (lngPlus % 10);

        if (lngDigit == 10) return 0;

        return lngDigit;
    }

    if (validate[2] != digitVerifier(validate[1])) {
        return false;
    }

    return true;
};

export const rfcCustomValidation = value => {
    if (!value) return false;

    let rfcRegex = /^([ A-ZÑ&]?[A-ZÑ&]{3}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    let validRFC = value.match(rfcRegex);

    if (!validRFC) return false;

    //Separar el dígito verificador del resto del RFC
    let digitVerified = validRFC.pop(),
        rfcNotDigit = validRFC.slice(1).join("");

    //Obtener el digito esperado
    let dictionary = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
        lngPlus = 0.0,
        expectedDigit;

    if (rfcNotDigit.length == 11) rfcNotDigit = " " + rfcNotDigit; //Ajustar a 12
    for (let i = 0; i < 13; i++)
        lngPlus =
            lngPlus + dictionary.indexOf(rfcNotDigit.charAt(i)) * (13 - i);
    expectedDigit = 11 - (lngPlus % 11);
    if (expectedDigit == 11) expectedDigit = 0;
    if (expectedDigit == 10) expectedDigit = "A";

    //El dígito verificador coincide con el esperado
    return digitVerified == expectedDigit;
};
