var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"app":"","light":"","flat":"","color":"#fff","dense":""}},[[(_vm.isTabsToDrawer || _vm.isAuth)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-toolbar-title',[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticStyle:{"padding-top":"10px"},attrs:{"src":this.placeholders.logo_navbar}})])],1),(!_vm.isTabsToDrawer)?_c('div',[_c('v-chip',{staticClass:"ma-2",staticStyle:{"cursor":"pointer"},attrs:{"label":"","outlined":""},on:{"click":_vm.onChangeLanguage}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-web")])],1),_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.optionLanguage))+" ")],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",staticStyle:{"cursor":"pointer"},attrs:{"label":"","color":"primary"}},'v-chip',attrs,false),on),[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-circle-multiple")])],1),_vm._v(" "+_vm._s(_vm.$t('LNKB'))+" ")],1)]}}],null,false,3967678147)},[_c('v-list',{attrs:{"subheader":""}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t('whitepaper')))]),_c('v-list-item',{attrs:{"exact":true,"to":{name: 'whitepaper.show'}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('whitepaper')))])],1),_c('v-divider'),_c('v-subheader',[_vm._v(_vm._s(_vm.$t('airdrop')))]),_c('v-list-item',{attrs:{"exact":true,"to":{name: 'airdrop.show'}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('airdrop')))])],1),_c('v-divider'),_c('v-subheader',[_vm._v(_vm._s(_vm.$t('buy_tokens')))]),_vm._l((_vm.tokens),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.to,"href":item.href,"exact":true,"target":item.target}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)})],2)],1)],1):_vm._e()],_c('v-spacer'),(!_vm.isTabsToDrawer)?_c('div',{staticClass:"app-navbar-buttons-container"},[_vm._l((_vm.tabsFiltered),function(tab,i){return [(tab.children)?_c('v-menu',{key:i,attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"app-navbar-buttons",attrs:{"elevation":0,"text":"","tile":"","plain":"","active-class":"app-navbar-buttons-active"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(tab.text)+" ")])]}}],null,true)},[_c('v-list',_vm._l((tab.children),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.to,"href":item.href,"exact":true}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1):_c('v-btn',{key:i,staticClass:"app-navbar-buttons",attrs:{"to":tab.to,"href":tab.href,"exact":true,"elevation":0,"text":"","tile":"","plain":"","active-class":"app-navbar-buttons-active"}},[_vm._v(" "+_vm._s(tab.text)+" ")])]})],2):_vm._e(),(_vm.isAuth)?_c('v-menu',{attrs:{"open-on-hover":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-list-item-avatar',{staticClass:"mx-auto",attrs:{"color":"grey darken-3"}},[_c('v-img',{attrs:{"src":_vm.avatar}})],1)],1)]}}],null,false,3201697356)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'profile.edit' }}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('my_profile')))])],1),_c('v-list-item',{attrs:{"to":{ name: 'onboarding' }}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('onboarding')))])],1),_c('v-divider'),_c('v-list-item',{attrs:{"to":{ name: 'logout.show' }}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('logout'))+" ")])],1)],1)],1):_c('div',[(!_vm.isTabsToDrawer && false)?_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","to":{ name: 'login.show' }}},[_vm._v(" "+_vm._s(_vm.$t('login'))+" ")]):_vm._e(),_c('v-btn',{staticClass:"mr-1",attrs:{"color":"accent","to":{ name: 'login.show' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(_vm.$t('my_account'))+" ")],1)],1)],2),_c('v-navigation-drawer',{attrs:{"app":"","permanent":false && _vm.isAuth,"temporary":true || !_vm.isAuth},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":""}},[_c('center',{staticClass:"mt-2"},[_c('img',{attrs:{"src":this.placeholders.logo_navbar},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}})]),(_vm.isTabsToDrawer)?_c('app-drawer-list',{attrs:{"items":_vm.menu}}):_vm._e(),(_vm.isAuth)?_c('app-drawer-list',{attrs:{"items":_vm.dashboard}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }