<template>
    <v-list-item class="mt-n2">
        <v-list-item-content class="pa-0 py-4">
            <v-list-item-title class="headline">{{ title }}</v-list-item-title>
            <v-list-item-subtitle v-if="instructions"
                >{{ instructions }}
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="ma-0">
            <slot name="action"></slot>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
    props: ["title", "instructions"]
};
</script>
