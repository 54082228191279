function apiErrors(name, errors) {
    const result = [];
    if (errors.length != 0) {
        if (errors[name] != undefined) {
            result.push(errors[name][0]);
        }
        delete errors[name];
    }
    return result;
}

export { apiErrors }
