<style>
.round-table-height-td {
    height: 2em !important;
}
</style>
<template>
<div v-if="round">
    <v-simple-table style="background-color: #F5F5F5">
        <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-center round-table-height-td">{{ $t('round') }}</th>
                    <th class="text-center round-table-height-td">{{ $t('amount') }}</th>
                    <th class="text-center round-table-height-td">
                        {{ $t('minimum_investment') }}
                    </th>
                    <th class="text-center round-table-height-td">
                        {{ $t('percent') }}
                    </th>
                    <th class="text-center round-table-height-td">
                        {{ $t('raised') }}
                    </th>
                    <th class="text-center round-table-height-td">
                        {{ $t('status') }}
                    </th>
                    <th class="text-center round-table-height-td">
                        {{ $t('start_date') }}
                    </th>
                    <th class="text-center round-table-height-td">
                        {{ $t('end_date') }}
                    </th>
                </tr>
                <tr>
                    <td class="text-center round-table-height-td">
                        {{ round.attributes.created_at | date }}
                    </td>
                    <td class="text-center round-table-height-td">
                        {{ round.attributes.amount | currency }}
                    </td>
                    <td class="text-center round-table-height-td">
                        {{ round.attributes.minimum | currency }}
                    </td>
                    <td class="text-center round-table-height-td">
                        {{ round.attributes.percent | percent }}
                    </td>
                    <td class="text-center round-table-height-td">
                        {{ round.attributes.raised | currency }} /
                        {{ (round.attributes.raised / round.attributes.amount * 100) | percent }}
                    </td>
                    <td class="text-center round-table-height-td">
                        {{ round.relationships.status.attributes.name }}
                    </td>
                    <td class="text-center round-table-height-td">
                        {{ round.attributes.start_date }}
                    </td>
                    <td class="text-center round-table-height-td">
                        {{ round.attributes.end_date }}
                    </td>
                </tr>
            </thead>
        </template>
    </v-simple-table>
    <v-simple-table>
        <template v-slot:default v-if="round.relationships.investments.length === 0">
            <thead>
                <tr>
                    <th class="text-center">
                        {{ $t('no_ivestors') }}
                    </th>
                </tr>
            </thead>
        </template>
        <template v-slot:default v-else>
            <thead>
                <tr>
                    <th>{{ $t('investors') }}</th>
                    <th class="text-center">{{ $t('date') }}</th>
                    <th>{{ $t('amount') }}</th>
                    <th>{{ $t('status') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="investment in round.relationships.investments" :key="investment.id">
                    <td>{{ investment.relationships.user.relationships.person.attributes.full_name }}</td>
                    <td class="text-center">
                        {{ investment.attributes.created_at | date }}
                    </td>
                    <td>{{ investment.attributes.amount | currency }}</td>
                    <td>{{ investment.relationships.status.attributes.name }}</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
    <v-divider class="pt-15"></v-divider>
</div>
</template>

<script>
export default {
    props: ["round"],
    components: {}
};
</script>
