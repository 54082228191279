<template>
<v-card v-if="rounds && rounds.length">
  <v-card-title>
    {{ $t("investment_rounds") | uppercase }}
  </v-card-title>
  <v-list two-line subheader>
    <div v-for="(round, index) in rounds" :key="round.id">
      <v-list-item>
        <v-list-item-content>
          <v-row align="center" justify="center">
            <v-col>
              <v-list-item-title>
                <b class="right">
                    {{ $t("round")  | uppercase  }} {{ getStatusText(round.is_active) | uppercase }} 
                </b>
              </v-list-item-title>
              <v-list-item-subtitle v-if="false">
                {{ round.start_date | date }} - {{ round.end_date | date }}
              </v-list-item-subtitle>

              <v-list-item-subtitle class="my-2">
                <b>{{ $t("type") | uppercase }}: </b> 
                <span v-if="round.type_id == 1">Reparto de acciones</span>
                <span v-else>Rendimiento de</span> {{ round.percent | percent }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="my-2">
                <v-row>
                  <v-col sm="12" lg="3">
                    <b>{{ $t("raised") | uppercase }}: </b> {{ round.raised | currency }}
                  </v-col>
                  <v-col sm="12" lg="3">
                    <b> {{ $t("from") | uppercase }} </b> {{ round.amount | currency }}
                  </v-col>
                  <v-col sm="12" lg="3">
                    <b>{{ round.investments }}</b>
                    {{ $t("investors") }}
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-col>
            <v-col lg="2">
              <v-btn :color="getButtonColor(round.is_active)" @click="onInvest(round)">
                {{ getButtonText(round.is_active) }}</v-btn>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="index !== rounds.length - 1"></v-divider>
    </div>
    <app-continue v-model="dialog"></app-continue>
  </v-list>
</v-card>
</template>

<script>
import {
  mapGetters
} from "vuex";
import AppContinue from "@/components/molecules/Continue";

export default {
  props: ["rounds"],
  data: () => ({
    dialog: false
  }),
  computed: {
    ...mapGetters("auth", {
      isAuth: "isAuth"
    })
  },
  methods: {
    getButtonColor(isActive) {
      return isActive ? "accent" : "";
    },
    getButtonText(isActive) {
      return isActive ? this.$t('invest') : this.$t('detail');
    },
    getStatusText(isActive) {
      return isActive ? this.$t('in_progress') : this.$t('closed');
    },
    onInvest(round) {
      if (this.isAuth) {
        this.$router.push({
          name: "rounds.investments.create",
          params: {
            id: round.id
          }
        });
      } else {
        this.dialog = true;
      }
    }
  },
  components: {
    AppContinue
  }
};
</script>
