import axios from "axios";

// Initial state
const state = {
    countries: [],
    states: [],
    address: {},
    errors: [],
    current_country: null
};

// Actions
const actions = {
    async fetchCountries({ commit, state}) {
        try {
            if(state.countries.length === 0) {
                const response = await axios.get(`/api/countries`);
                commit("setCountries", response.data);
            }
        } catch (error) {
            const { response } = error;
            if (response.status === 404) {
                console.log(error);
            }
        }
    },
    async fetchStates({ commit, state }, countryCode = "MX") {
        try {
            if(state.current_country !== countryCode) {
                const response = await axios.get(
                    `/api/countries/${countryCode}/states`
                );
                commit("setStates", response.data);
                commit("setCurrentCountry", countryCode);
            }
        } catch (error) {
            const { response } = error;
            if (response.status === 404) {
                console.log(error);
            }
        }
    },
    async fetchAddress({ commit }) {
        try {
            const response = await axios.get(`/api/profile/personal-address`);
            commit("setAddress", response.data);
        } catch (error) {
            const { response } = error;
            if (response.status === 404) {
                console.log(error);
            }
        }
    },
    async storeAddress({ commit }, formData) {
        try {
            await axios.post(
                `/api/profile/personal-address`,
                formData
            );
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    }
};

// Mutations
const mutations = {
    setErrors(state, errors) {
        state.errors = errors;
    },
    setCountries(state, countries) {
        state.countries = countries;
    },
    setStates(state, states) {
        state.states = states;
    },
    setCurrentCountry(state, current) {
        state.current_country = current;
    },
    setAddress(state, address) {
        state.address = address;
    }
};

// Getters
const getters = {
    countries: state => state.countries,
    states: state => state.states,
    address: state => state.address,
    errors: state => state.errors
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
