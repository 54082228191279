<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs">
        <app-title-crumb :title="$t('payment_history')" :instructions="$t('payment_history_msg')"></app-title-crumb>
    </app-breadcrumbs>
    <v-container fluid>
        <v-row align="center" justify="center" dense>
            <v-col cols="12" md="12">
                <v-data-table :headers="headers" :items="invoices" :items-per-page="5" class="elevation-1">
                    <template v-slot:item.lines="{ item }">
                        <span>{{ item.lines.data[0].description }}</span>
                    </template>
                    <template v-slot:item.total="{ item }">
                        <span>{{ item.total / 100 | currency }}</span>
                    </template>
                    <template v-slot:item.subtotal="{ item }">
                        <span>{{ item.subtotal / 100 | currency }}</span>
                    </template>
                    <template v-slot:item.status_transitions.paid_at="{ item }">
                        <span>{{
                                item.status_transitions.paid_at | timestamp
                            }}</span>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";
export default {
    data() {
        return {
            isLoading: true,
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "home"
                    }
                },
                {
                    text: this.$t('dashboard'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "dashboard"
                    }
                },
                {
                    text: this.$t('my_subscription'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "subscription.show"
                    }
                },
                {
                    text: "",
                    disabled: true,
                    to: {
                        name: "home"
                    }
                }
            ],
            headers: [{
                    text: "#",
                    value: "number"
                },
                {
                    text: this.$t('date'),
                    value: "status_transitions.paid_at"
                },
                {
                    text: this.$t('description'),
                    value: "lines"
                },
                {
                    text: this.$t('period'),
                    value: "carbs"
                },
                {
                    text: this.$t('payment_method'),
                    value: "status_transitions.paid_at"
                },
                {
                    text: this.$t('subtotal'),
                    value: "subtotal"
                },
                {
                    text: this.$t('total'),
                    value: "total"
                }
            ],
            desserts: []
        };
    },
    mounted() {
        this.prepareComponent();
    },
    computed: {
        ...mapGetters("stripe", {
            invoices: "invoices"
        })
    },
    methods: {
        ...mapActions("stripe", {
            fetchInvoices: "fetchInvoices"
        }),
        async prepareComponent() {
            this.isLoading = true;
            await this.fetchInvoices();
            this.isLoading = false;
        }
    },
    components: {
        AppBreadcrumbs,
        AppTitleCrumb
    }
};
</script>
