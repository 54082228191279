<template>
    <div>
        <app-breadcrumbs :breadcrumbs="breadcrumbs">
            <app-title-crumb title="Airdrop" instructions="">
            </app-title-crumb>
        </app-breadcrumbs>
        <v-carousel cycle height="450" class="mb-5" :show-arrows="false" hide-delimiters>
    <v-carousel-item>
        <v-sheet color="#2A2A2A" height="100%">
            <v-row class="fill-height mx-10" align="center" justify="center">
                <v-col class="text-center" cols="12" lg="6" sm="12">
                    <h1 class="text-h4 mb-5 white--text">
                        {{ $t('airdrop_title') }}
                    </h1>
                    <v-btn class="white--text" :to="{ name: 'signup.show' }" color="accent">
                        <v-icon left>mdi-account-plus</v-icon>
                        {{ $t('signup') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-sheet>
    </v-carousel-item>
</v-carousel>
        <v-container fluid>
            <v-row class="mx-10">
                <v-col>
                    <h1 class="text-h5 mb-4">
                        {{ $t('faq') }}
                    </h1>
                </v-col>
            </v-row>
            <v-row class="mx-10">
                <v-col>
                    <v-expansion-panels accordion>
                        <v-expansion-panel v-for="(item,i) in questions" :key="i" class="my-1">
                            <v-expansion-panel-header>
                                <h1 class="text-h6"> {{ item.title }} </h1>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div v-html="item.content"></div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>


<script>
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";

export default {
    
    data() {
        return {
            id: this.$route.params.project,
            isDateVisible: true,
            isSalaryVisible: false,
            dialog: false,
            rounded: false,
            flat: false,
            breadcrumbs: [
                {
                    text: this.$t('home'),
                    disabled: false,
                    to: { name: "home" },
                    exact: true
                },
                {
                    text: "",
                    disabled: true,
                    to: { name: "dashboard" }
                }
            ],
            questions: [{
                    title: this.$t('airdrop_q1'),
                    content: this.$t('airdrop_a1')
                },
                {
                    title: this.$t('airdrop_q2'),
                    content: this.$t('airdrop_a2')
                },
                {
                    title: this.$t('airdrop_q3'),
                    content: this.$t('airdrop_a3')
                },
                {
                    title: this.$t('airdrop_q4'),
                    content: this.$t('airdrop_a4')
                },
                {
                    title: this.$t('airdrop_q5'),
                    content: this.$t('airdrop_a5', { tokpie: "" })
                },
                {
                    title: this.$t('airdrop_q6'),
                    content: this.$t('airdrop_a6')
                },
                {
                    title: this.$t('airdrop_q7'),
                    content: this.$t('airdrop_a7')
                },
                {
                    title: this.$t('airdrop_q8'),
                    content: this.$t('airdrop_a8')
                },
                {
                    title: this.$t('airdrop_q9'),
                    content: this.$t('airdrop_a9')
                },
                {
                    title: this.$t('airdrop_q10'),
                    content: this.$t('airdrop_a10')
                },
            ]
        };
    },
    components: {
        AppBreadcrumbs,
        AppTitleCrumb
    }
};
</script>
