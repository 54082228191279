import axios from "axios";
import router from "./../../router";

const state = {
    investment: null,
    applicants: null,
    errors: [],
    investments: null
};

const actions = {
    async fetch({ commit,  rootState }) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${rootState.auth.token}`;

        try {
            const response = await axios.get(`/api/profile/investments`);
            commit("setInvestments", response.data);
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async store({ commit,  rootState }, { roundId, data }) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${rootState.auth.token}`;

        try {
            await axios.post(
                `/api/rounds/${roundId}/investments`,
                data
            );

            router
                .replace({ name: "profile.investments.index" })
                .catch(err => {
                    console.log(err);
                });
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async fetchById({ commit, rootState }, investmentId) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${rootState.auth.token}`;

        try {
            const response = await axios.get(
                `/api/profile/investments/${investmentId}`
            );
            commit("setInvestment", response.data);
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },

    // async update({ commit, state }, investmentId) {},

    // async delete({ commit, state }, investmentId) {}
};

const mutations = {
    setInvestments(state, investments) {
        state.investments = investments;
    },
    setInvestment(state, investment) {
        state.investment = investment;
    }
};

const getters = {
    investments: state => {
        return state.investments;
    },
    investment: state => {
        return state.investment;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
