<template>
<v-card :loading="!round">
    <v-card-title>
        {{ $t('status') }}
    </v-card-title>
    <v-card-text>
        <div v-if="round">
            <v-select v-model="selection" :items="items" :label="$t('select')" :disabled="isStatusDropDownDisabled"></v-select>
        </div>
        <v-skeleton-loader v-else class="mx-auto" type="text"></v-skeleton-loader>
    </v-card-text>
    <v-card-actions>
        <v-btn color="primary" outlined text block @click="onUpdate" :disabled="isUpdated">
            {{ $t('update') }}
        </v-btn>
    </v-card-actions>
    <v-card-actions>
        <v-btn color="accent" outlined text block @click="onRaiseInvestment" :disabled="isRaiseButtonDisabled">
            {{ $t('raise_investment') }}
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import {
    mapActions
} from "vuex";

export default {
    props: ["round"],
    data() {
        return {
            isUpdated: true,
            items: [{
                    text: this.$t('pending'),
                    value: 2
                },
                {
                    text: this.$t('approved'),
                    value: 3
                },
                {
                    text: this.$t('rejected'),
                    value: 4
                },
                {
                    text: this.$t('canceled'),
                    value: 5
                },
                {
                    text: this.$t('paused'),
                    value: 8
                },
                {
                    text: this.$t('finished'),
                    value: 7
                },
                {
                    text: this.$t('raising_investment'),
                    value: 9,
                    disabled: true
                }
            ]
        };
    },
    computed: {
        selection: {
            get() {
                return parseInt(this.round ? this.round.relationships.status.attributes.status_id : 0);
            },
            set(newValue) {
                this.isUpdated = false;
                this.round.relationships.status.attributes.status_id = newValue;
            }
        },
        isStatusDropDownDisabled() {
            if (this.selection === 9 && this.isUpdated) {
                return true;
            }

            return false;
        },
        isRaiseButtonDisabled() {
            if (this.selection === 3 && this.isUpdated) {
                return false;
            }

            return true;
        }
    },
    methods: {
        ...mapActions("rounds", {
            update: "lbUpdate",
            raise: "lbRaise"
        }),
        async onUpdate() {
            await this.update({
                roundId: this.round.id,
                statusId: this.round.relationships.status.attributes.status_id
            });
            this.isUpdated = true;
        },
        async onRaiseInvestment() {
            this.raise({
                roundId: this.round.id
            });
        }
    }
};
</script>
