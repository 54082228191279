<template>
<v-row>
  <v-col cols="12" sm="6" md="6" class="pa-0 d-none d-md-block">
    <v-img style="width: 100%" :src="placeholders.login_bg"></v-img>
  </v-col>
  <v-col cols="12" sm="12" md="6" class="mt-10">
    <div class="pa-10">
      <v-row>
        <v-col>
          <v-img :src="placeholders.logo_login" width="60"></v-img>
          <h1 class="title">
            {{ $t('recover_password') }}
          </h1>
        </v-col>
      </v-row>
      <p v-if="message">{{message}}</p>
      <v-form ref="form" v-model="valid" v-else>
        <v-text-field v-model="form.email" required @input="$v.form.email.$touch()" @blur="$v.form.email.$touch()" :error-messages="emailErrors" :label="$t('email')" :disabled="true" @keyup.enter="onSubmit"></v-text-field>
        <v-text-field v-model="form.password" required @input="$v.form.password.$touch()" @blur="$v.form.password.$touch()" :error-messages="passwordErrors" type="password" :label="$t('password')" @keyup.enter="onSubmit"></v-text-field>
        <v-text-field v-model="form.password_confirmation" required @input="$v.form.password_confirmation.$touch()" @blur="$v.form.password_confirmation.$touch()" :error-messages="passwordConfirmationErrors" type="password" :label="$t('password_confirmation')" @keyup.enter="onSubmit"></v-text-field>
        <p v-if="errors.error">{{errors.error}}</p>
        <div class="text-center">
          <v-btn block color="accent" :loading="isLoading" @click="onSubmit" :disabled="!valid">
            {{ $t('set_new_password') }}
          </v-btn>
        </div>
      </v-form>
    </div>
  </v-col>
</v-row>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters
} from 'vuex';
import {
  validationMixin
} from 'vuelidate';
import {
  required,
  email
} from 'vuelidate/lib/validators';
import {
  apiErrors
} from '../../../util.js';

export default {
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required: required,
        email: email,
      },
      password: {
        required: required,
      },
      password_confirmation: {
        required: required,
      },
    }
  },
  data() {
    return {
      form: {
        token: this.$route.params.token,
        email: this.$route.query.email,
        password: '',
        password_confirmation: '',
      },
      isLoading: false,
      valid: false,
      message: '',
    };
  },
  mounted() {
    this.getResetEmail();
  },
  methods: {
    ...mapActions('auth', {
      getResetEmail: 'getResetEmail',
      resetPassword: 'resetPassword',
    }),
    createErrors(key, rules) {
      const errors = apiErrors(key, this.errors.errors || {});
      if (!this.$v.form[key].$dirty && errors.length == 0) return errors;
      for (let rule in rules) {
        !this.$v.form[key][rule] && errors.push(rules[rule]);
      }
      return errors;
    },
    async onSubmit() {
      this.$v.form.$touch();
      let invalid = this.$v.form.$invalid;
      this.validate = true;
      if (invalid) {
        return;
      }

      this.isLoading = true;

      const response = await this.resetPassword(this.form);

      this.isLoading = false;

      if (response) {
        this.message = response.data.message;
      }
    },
  },
  computed: {
    ...mapGetters({
      placeholders: 'placeholders',
    }),
    ...mapState('auth', {
      isAuth: 'isAuth',
      errors: 'errors',
    }),
    emailErrors() {
      return this.createErrors('email', {
        required: this.$t('validation_required'),
        email: this.$t('validation_email'),
      });
    },
    passwordErrors() {
      return this.createErrors('password', {
        required: this.$t('validation_required'),
      });
    },
    passwordConfirmationErrors() {
      return this.createErrors('password_confirmation', {
        required: this.$t('validation_required'),
      });
    },
  },
};
</script>
