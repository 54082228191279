import Vuetify from '../../plugins/vuetify';
import axios from '../../plugins/axios';
import router from '../../router';
import * as storage from '../storage';

/**
 * Remember
 * Commit: calls a mutation to update the state.
 * Dispatch: call an action to proccess data and pass the result to a commit.
 */

// Initial state
const state = {
    token: null,
    user: null,
    expirationDate: null,
    isProcessing: false,
    errors: [],
};

// Actions
const actions = {
    setLogoutTimer({ commit }, expirationTime) {
        setTimeout(() => {
            commit('delAuthData');
        }, expirationTime * 1000);
    },
    async signup({ commit, dispatch }, registerData) {
        commit('setErrors', []);
        commit('setProcessing', true);
        try {
            const response = await axios.post(
                '/api/auth/register',
                registerData
            );
            const now = new Date();
            const expirationDate = new Date(
                now.getTime() + response.data.expires_in * 1000
            );
            const payload = {
                token: response.data.access_token,
                expirationDate: expirationDate,
            };
            // Mutation
            commit('setAuthData', payload);
            commit('setProcessing', false);
            // Action
            dispatch('fetchUser');
            dispatch('setLogoutTimer', response.data.expires_in);
        } catch (error) {
            if (error.response.status === 422) {
                commit('setErrors', error.response.data.errors);
            } else if (error.response.status != 200) {
                commit('setErrors', {
                    common: ['Intenta mas tarde nuevamente'],
                });
            }
            commit('setProcessing', false);
        }
    },
    async login({ commit, dispatch }, authData) {
        commit('setErrors', []);
        try {
            const response = await axios.post('/api/auth/login', {
                email: authData.email,
                password: authData.password,
            });
            const now = new Date();
            const expirationDate = new Date(
                now.getTime() + response.data.expires_in * 1000
            );
            const payload = {
                token: response.data.access_token,
                expirationDate: expirationDate,
            };

            // Mutations
            commit('setAuthData', payload);
            dispatch('setLogoutTimer', response.data.expires_in);

            return true;
        } catch (error) {
            if (error.response.status === 422) {
                commit('setErrors', error.response.data.errors);
            } else {
                console.log(error);
            }
        }
        
        return false;
    },
    tryAutoLogin({ commit, dispatch }) {
        const token = storage.local.getItem('token');
        if (!token) {
            return;
        }
        const expirationDate = storage.local.getItem('expirationDate');
        const now = new Date();

        if (now >= expirationDate) {
            return;
        }
        const payload = {
            token: token,
            expirationDate: expirationDate,
        };

        // Mutation
        commit('setAuthData', payload);

        // Action
        dispatch('fetchUser');
    },
    logout({ commit }) {
        // Mutation
        commit('delAuthData');

        // Redirect
        router.replace({ name: 'login.show' }).catch((err) => {
            console.log(err);
        });
    },
    async fetchUser({ commit, state }) {
        if (!state.token) {
            return;
        }

        try {
            const user = storage.local.getItem('user');

            if (user) {
                commit('setUser', user);
                commit('setProcessing', false);
            } else {
                axios.defaults.headers.common['Authorization'] =
                    'Bearer ' + state.token;
                const response = await axios.get('/api/user');
                storage.local.setItem('user', response.data);
                commit('setUser', response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    getResetEmail({ commit }) {
        commit('setErrors', []);
    },
    async sendResetLinkEmail({ commit }, registerData) {
        commit('setErrors', []);
        commit('setProcessing', true);
        try {
            return await axios.post(
                '/api/auth/password/email',
                registerData
            );
        } catch (error) {
            if (error.response.status === 422) {
                commit('setErrors', error.response.data);
            } else if (error.response.status != 200) {
                commit('setErrors', {
                    common: ['Intenta más tarde nuevamente'],
                });
            }
            commit('setProcessing', false);

            return false;
        }
    },
    async resetPassword({ commit }, registerData) {
        commit('setErrors', []);
        commit('setProcessing', true);
        try {
            return await axios.post(
                '/api/auth/password/reset',
                registerData
            );
        } catch (error) {
            if (error.response.status === 422) {
                commit('setErrors', error.response.data);
            } else if (error.response.status != 200) {
                commit('setErrors', {
                    common: ['Intenta más tarde nuevamente'],
                });
            }
            commit('setProcessing', false);

            return false;
        }
    },
};

// Mutations
const mutations = {
    setAuthData(state, payload) {
        storage.local.setItem('token', payload.token);
        storage.local.setItem('expirationDate', payload.expirationDate);
        state.token = payload.token;
    },
    setUser(state, user) {
        state.user = user;
    },
    delAuthData(state) {
        storage.local.removeItems(['expirationDate', 'token', 'user']);
        storage.local.clear();
        state.token = null;
        state.user = null;
    },
    setProcessing(state, isProcessing) {
        state.isProcessing = isProcessing;
    },
    setErrors(state, errors) {
        state.errors = errors;
    },
};

// Getters
const getters = {
    user: (state) => {
        return state.user;
    },
    isAuth: (state) => {
        return state.token !== null;
    },
    isUserFetched: (state) => {
        return state.user !== null;
    },
    isLoading() {
        return state.user === null && state.token !== null;
    },
    isAdmin() {
        return state.user.role.data.id === 1;
    },
    isSeller() {
        return state.user.role.data.id === 2;
    },
    isTabsToDrawer: () => {
        switch (Vuetify.framework.breakpoint.name) {
            case 'xs':
            case 'sm':
            case 'md':
                return true;
            case 'lg':
            case 'xl':
        }

        return false;
    },
    /** eslint-disable-next-line no-unused-vars */
    isItemVisible: (state, getters) => (item) => {
        const auth = item.auth;
        const guest = item.guest;
        const isAuth = getters.isAuth;

        if (guest !== undefined) {
            return guest && !isAuth;
        } else if (auth !== undefined) {
            return auth && isAuth;
        } else {
            return true;
        }
    },
    hasRole: (state) => (roleName) => {
        let hasRole = false;
        if (state.user && state.user.relationships.roles) {
            state.user.relationships.roles.forEach((role) => {
                if (role.attributes.name == roleName) {
                    hasRole = true;
                }
            });
        }

        return hasRole;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
