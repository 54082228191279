<template>
<div>
  <app-breadcrumbs :breadcrumbs="breadcrumbs">
    <app-title-crumb :title="$t('companies')" :instructions="$t('my_companies_instructions')">
      <template v-slot:action>
        <v-btn color="accent" @click="onCreate">{{ $t('new_company') }}</v-btn>
      </template>
    </app-title-crumb>
  </app-breadcrumbs>
  <v-container fluid>
    <v-skeleton-loader class="mx-auto" type="card-heading, card, actions" v-if="projects === null"></v-skeleton-loader>
    <app-empty-state v-else-if="projects.length === 0">
      <v-btn color="accent" @click="onCreate">{{ $t('new_company') }}</v-btn>
    </app-empty-state>
    <v-row dense>
      <v-col v-for="project in projects" :key="project.id" :cols="4">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4 d-none">
                STARTUP
              </div>
              <v-list-item-title class="headline mb-1">
                {{ project.attributes.name }}
              </v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
            <app-list-item-logo :logo="project.attributes.logo"></app-list-item-logo>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn :to="{
                                    name: 'admin.projects.show',
                                    params: { project: project.id }
                                }" text>
              <v-icon left>
                mdi-details
              </v-icon>
              {{ $t('details') }}
            </v-btn>
            <v-btn @click="onDelete(project)" text>
              <v-icon left>
                mdi-delete
              </v-icon>
              {{ $t('delete') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <app-confirm v-model="confirm" @confirmed="onDeleteConfirmed"></app-confirm>
  </v-container>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";
import AppConfirm from "@/components/molecules/Confirm";
import AppEmptyState from "@/components/atoms/EmptyState";
import AppListItemLogo from "@/components/atoms/ListItemLogo";

export default {
  data() {
    return {
      breadcrumbs: [{
          text: this.$t('home'),
          disabled: false,
          exact: true,
          to: {
            name: "home"
          }
        },
        {
          text: this.$t('dashboard'),
          disabled: false,
          exact: true,
          to: {
            name: "dashboard"
          }
        },
        {
          text: "",
          disabled: true
        }
      ],
      confirm: false,
      item: null
    };
  },
  computed: {
    ...mapGetters("projects", {
      projects: "projectsAdmin",
      isProcessing: "isProcessing"
    })
  },
  mounted() {
    this.prepareComponent();
  },
  methods: {
    prepareComponent() {
      this.fetchAdmin();
    },
    ...mapActions("projects", {
      fetchAdmin: "fetchAdmin",
      deleteProject: "delete"
    }),
    onCreate() {
      this.$router.push({
        name: "projects.create"
      });
    },
    onDelete(item) {
      this.item = item;
      this.confirm = true;
    },
    onDeleteConfirmed(confirmed) {
      if (confirmed) {
        this.deleteProject(this.item.id);
      }
    }
  },
  components: {
    AppBreadcrumbs,
    AppTitleCrumb,
    AppConfirm,
    AppEmptyState,
    AppListItemLogo
  }
};
</script>
