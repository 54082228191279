<template>
    <div>
        <app-breadcrumbs :breadcrumbs="breadcrumbs">
            <app-title-crumb
                :title="$t('companies_management')"
            ></app-title-crumb>
        </app-breadcrumbs>
        <v-container fluid>
            <v-row justify="center">
                <v-col cols="12" md="9" sm="12">
                    <app-project-description
                        :project="project"
                    ></app-project-description>
                    <v-spacer></v-spacer>
                    <app-rounds :rounds="rounds" class="mt-5"></app-rounds>
                    <app-jobs
                        :jobs="jobs"
                        :isDateVisible="true"
                        :isSalaryVisible="true"
                        class="mt-5"
                    ></app-jobs>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <app-project-status :project="project"></app-project-status
                ></v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";
import AppProjectDescription from "@/components/projects/project/Description";
import AppJobs from "@/components/projects/jobs/List";
import AppRounds from "@/components/projects/rounds/List";
import AppProjectStatus from "@/components/projects/project/Status";

export default {
    data() {
        return {
            id: this.$route.params.id,
            isLoading: true,
            breadcrumbs: [
                {
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: { name: "home" }
                },
                {
                    text: this.$t('dashboard'),
                    disabled: false,
                    exact: true,
                    to: { name: "linkbridge.dashboard.show" }
                },
                {
                    text: "Administración de proyectos",
                    disabled: false,
                    exact: true,
                    to: { name: "linkbridge.projects.index" }
                }
            ]
        };
    },
    mounted() {
        this.prepareComponent();
    },
    computed: {
        ...mapGetters("projects", {
            project: "project"
        }),
        ...mapGetters("jobs", {
            jobs: "jobs"
        }),
        ...mapGetters("rounds", {
            rounds: "rounds"
        })
    },
    methods: {
        ...mapActions("projects", {
            fetchById: "lbFetchById"
        }),
        ...mapActions("jobs", {
            fetchPositions: "lbFetchByProjectId"
        }),
        ...mapActions("rounds", {
            fetchRounds: "lbFetchByProjectId"
        }),
        prepareComponent() {
            this.fetchById(this.id);
            this.fetchPositions(this.id);
            this.fetchRounds(this.id);
        }
    },
    components: {
        AppBreadcrumbs,
        AppTitleCrumb,
        AppProjectDescription,
        AppProjectStatus,
        AppJobs,
        AppRounds
    }
};
</script>
