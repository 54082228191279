<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs">
        <app-title-crumb :title="$t('my_profile')" :instructions="$t('my_profile_msg')"></app-title-crumb>
    </app-breadcrumbs>
    <v-container fluid>
        <v-row align="center" justify="center" dense>
            <v-col cols="12" sm="10" md="7">
                <v-tabs vertical>
                    <v-tab v-for="tab in tabs" :key="tab.title">
                        <div class="app-tab-lelft">
                            {{ tab.title }}
                        </div>
                    </v-tab>
                    <v-tab-item>
                        <app-personal></app-personal>
                    </v-tab-item>
                    <v-tab-item>
                        <app-professionals></app-professionals>
                    </v-tab-item>
                    <v-tab-item>
                        <app-economical></app-economical>
                    </v-tab-item>
                    <v-tab-item>
                        <app-address></app-address>
                    </v-tab-item>
                    <v-tab-item>
                        <app-bank></app-bank>
                    </v-tab-item>
                    <v-tab-item>
                        <app-beneficiary></app-beneficiary>
                    </v-tab-item>
                    <v-tab-item>
                        <app-user></app-user>
                    </v-tab-item>
                    <v-tab-item>
                        <app-close></app-close>
                    </v-tab-item>
                </v-tabs>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";
import AppPersonal from "@/components/profile/Personal";
import AppAddress from "@/components/profile/Address";
import AppBank from "@/components/profile/Bank";
import AppBeneficiary from "@/components/profile/Beneficiary";
import AppUser from "@/components/profile/User";
import AppProfessionals from "@/components/profile/Professionals";
import AppEconomical from "@/components/profile/Economical";
import AppClose from "@/components/profile/Close";

export default {
    data() {
        return {
            tabs: [{
                    title: this.$t("personal_data"),
                    component: null
                },
                {
                    title: this.$t("professional_data"),
                    component: null
                },
                {
                    title: this.$t("economic_activity"),
                    component: null
                },
                {
                    title: this.$t("address"),
                    component: null
                },
                {
                    title: this.$t("bank_data"),
                    component: null
                },
                {
                    title: this.$t("beneficiary"),
                    component: null
                },
                {
                    title: this.$t("user"),
                    component: null
                },
                {
                    title: this.$t("close_account"),
                    component: null
                }
            ],
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "home"
                    }
                },
                {
                    text: this.$t('dashboard'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "dashboard"
                    }
                },
                {
                    text: "",
                    disabled: true,
                    to: {
                        name: "home"
                    }
                }
            ]
        };
    },
    components: {
        AppBreadcrumbs,
        AppTitleCrumb,
        AppPersonal,
        AppAddress,
        AppBank,
        AppBeneficiary,
        AppUser,
        AppProfessionals,
        AppEconomical,
        AppClose
    }
};
</script>
