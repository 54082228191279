<style>
.app-toolbar-title-class {
  background-color: transparent !important;
}

.app-navbar-buttons-container {
  height: 100%;
}

.app-navbar-buttons {
  height: 100% !important;
}

.app-navbar-buttons:hover {
  border-bottom: 2px solid #f43838;
}

.app-navbar-buttons-active {
  border-bottom: 2px solid #f43838;
  background-color: white;
}
</style>
<template>
<div>
  <v-app-bar app light flat color="#fff" dense>
    <template>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="isTabsToDrawer || isAuth" />
      <v-toolbar-title>
        <router-link to="/">
          <img style="padding-top: 10px" :src="this.placeholders.logo_navbar" />
        </router-link>
      </v-toolbar-title>
      <div v-if="!isTabsToDrawer">
        <v-chip class="ma-2" style="cursor: pointer" label outlined @click="onChangeLanguage">
          <v-avatar left>
            <v-icon>mdi-web</v-icon>
          </v-avatar>
          {{ optionLanguage | uppercase }}
        </v-chip>
        <v-menu open-on-hover bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip class="ma-2" style="cursor: pointer" label color="primary" v-bind="attrs" v-on="on">
              <v-avatar left>
                <v-icon>mdi-circle-multiple</v-icon>
              </v-avatar>
              {{ $t('LNKB') }}
            </v-chip>
          </template>
          <v-list subheader>
            <v-subheader>{{$t('whitepaper')}}</v-subheader>
            <v-list-item :exact="true" :to="{name: 'whitepaper.show'}">
              <v-list-item-title>{{$t('whitepaper')}}</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-subheader>{{$t('airdrop')}}</v-subheader>
            <v-list-item :exact="true" :to="{name: 'airdrop.show'}">
              <v-list-item-title>{{$t('airdrop')}}</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            
            <v-subheader>{{ $t('buy_tokens') }}</v-subheader>
            <v-list-item v-for="(item, index) in tokens" :key="index" :to="item.to" :href="item.href" :exact="true" :target="item.target">
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <v-spacer></v-spacer>
    <div class="app-navbar-buttons-container" v-if="!isTabsToDrawer">
      <template v-for="(tab, i) in tabsFiltered">
        <v-menu open-on-hover offset-y v-if="tab.children" :key="i">
          <template v-slot:activator="{ on, attrs }">
            <v-btn :elevation="0" text tile plain class="app-navbar-buttons" active-class="app-navbar-buttons-active" v-bind="attrs" v-on="on">
              {{ tab.text }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="(item, index) in tab.children" :key="index" :to="item.to" :href="item.href" :exact="true">
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn :to="tab.to" :href="tab.href" :exact="true" :elevation="0" text tile plain class="app-navbar-buttons" active-class="app-navbar-buttons-active" :key="i" v-else>
          {{ tab.text }}
        </v-btn>
      </template>
    </div>

    <v-menu open-on-hover left offset-y v-if="isAuth">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-list-item-avatar color="grey darken-3" class="mx-auto">
            <v-img :src="avatar"></v-img>
          </v-list-item-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item :to="{ name: 'profile.edit' }">
          <v-list-item-title>{{ $t('my_profile') }}</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'onboarding' }">
          <v-list-item-title>{{ $t('onboarding') }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item :to="{ name: 'logout.show' }">
          <v-list-item-title>
            {{ $t('logout') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <div v-else>
      <v-btn v-if="!isTabsToDrawer && false" text :to="{ name: 'login.show' }" class="mr-1">
        {{ $t('login') }}
      </v-btn>
      <v-btn color="accent" :to="{ name: 'login.show' }" class="mr-1">
        <v-icon left>mdi-account</v-icon>
        {{ $t('my_account') }}
      </v-btn>
    </div>
  </v-app-bar>
  <v-navigation-drawer app v-model="drawer" :permanent="false && isAuth" :temporary="true || !isAuth">
    <v-list dense>
      <center class="mt-2">
        <img @click.stop="drawer = !drawer" :src="this.placeholders.logo_navbar" />
      </center>
      <app-drawer-list :items="menu" v-if="isTabsToDrawer"></app-drawer-list>
      <app-drawer-list :items="dashboard" v-if="isAuth"></app-drawer-list>
    </v-list>
  </v-navigation-drawer>
</div>
</template>

<script>
import AppDrawerList from '@/components/molecules/Drawer/List';
import {
  mapState,
  mapActions,
  mapGetters
} from 'vuex';

export default {
  props: {
    source: String,
  },
  data() {
    return {
      drawer: null,
      offset: true,
      tokens: [{
          text: "Tokpie",
          target: '_blank',
          href: "https://tokpie.com/view_exchange/lnkb-eth/"
        },
        {
          text: this.$t('support_lnkb'),
          to: {
                name: "support.show"
              }
        },
        {
          text: "UniSwap",
          target: '_blank',
          href: "https://app.uniswap.org/#/swap?inputCurrency=0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64&outputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7&chain=mainnet"
        },
        {
          text: "ParaSwap",
          target: '_blank',
          href: " https://app.paraswap.io/#/?network=ethereum"
        },
        {
          text: "Flooz Trade",
          target: '_blank',
          href: "https://flooz.trade/trade/0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64?network=eth"
        },
        {
          text: "Arken Finance",
          target: '_blank',
          href: "https://swap.arken.finance/tokens/ethereum/0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64"
        },
        {
          text: "Apple pay & Bank cards",
          target: '_blank',
          href: "https://tokpie.zendesk.com/hc/en-us/articles/7027567813649--The-LinkBridge-LNKB-Token-Listed-on-Tokpie-#h_01G4XD4R5FSMWDTYNHTM4Z449B",
        },
      ],
      tabs: [{
          text: this.$t('investment_opportunities'),
          to: {
            name: 'home',
          },
        },
        {
          text: this.$t('jobs'),
          to: {
            name: 'jobs.index',
          },
        },
        {
          text: this.$t('about'),
          href: "mailto:team@linkbridge.app",
          children: [{
              text: this.$t('pricing'),
              to: {
                name: 'plans.index',
              },
            }, {
              text: this.$t('contact_us'),
              href: "mailto:team@linkbridge.app",
            },

          ]
        },
      ],
      exact: true,
      menu: [{
          heading: this.$t('menu'),
        },
        {
          icon: 'mdi-home',
          text: this.$t('companies'),
          to: {
            name: 'home',
          },
        },
        {
          icon: 'mdi-briefcase',
          text: this.$t('jobs'),
          to: {
            name: 'jobs.index',
          },
        },
        {
          icon: 'mdi-cards-variant',
          text: this.$t('pricing'),
          to: {
            name: 'plans.index',
          },
        },
        {
          icon: 'mdi-file',
          text: this.$t('whitepaper'),
          to: {
            name: 'whitepaper.show',
          },
        },
        {
          icon: 'mdi-file',
          text: this.$t('airdrop'),
          to: {
            name: 'airdrop.show',
          },
        },
        {
          icon: 'mdi-help-circle',
          text: this.$t('contact_us'),
          href: "mailto:team@linkbridge.app",
        },
        {
          icon: 'mdi-login',
          text: this.$t('login'),
          guest: true,
          to: {
            name: 'login.show',
          },
        },
        {
          heading: this.$t('buy_tokens'),
        },
        {
          icon: 'mdi-link-variant',
          text: "Tokpie",
          href: "https://tokpie.com/view_exchange/lnkb-eth/"
        },
        {
          icon: 'mdi-link-variant',
          text: this.$t('support_lnkb'),
          to: {
                name: "support.show"
              }
        },
        {
          icon: 'mdi-link-variant',
          text: "UniSwap",
          href: "https://app.uniswap.org/#/swap?inputCurrency=0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64&outputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7&chain=mainnet"
        },
        {
          icon: 'mdi-link-variant',
          text: "ParaSwap",
          href: " https://app.paraswap.io/#/?network=ethereum"
        },
        {
          icon: 'mdi-link-variant',
          text: "Flooz Trade",
          href: "https://flooz.trade/trade/0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64?network=eth"
        },
        {
          icon: 'mdi-link-variant',
          text: "Arken Finance",
          href: "https://swap.arken.finance/tokens/ethereum/0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64"
        },
        {
          icon: 'mdi-link-variant',
          text: "Apple pay & Bank cards",
          href: "https://tokpie.zendesk.com/hc/en-us/articles/7027567813649--The-LinkBridge-LNKB-Token-Listed-on-Tokpie-#h_01G4XD4R5FSMWDTYNHTM4Z449B",
        },
      ],
      dashboard: [{
          heading: this.$t('home'),
          auth: true,
        },
        {
          icon: 'mdi-view-dashboard',
          text: this.$t('dashboard'),
          auth: true,
          to: {
            name: 'dashboard',
          },
        },
        {
          heading: this.$t('admin'),
          auth: true,
        },
        {
          icon: 'mdi-domain',
          text: this.$t('companies'),
          auth: true,
          to: {
            name: 'admin.projects.index',
          },
        },
        {
          icon: 'mdi-finance',
          text: this.$t('investments'),
          auth: true,
          plan: 'pro',
          to: {
            name: 'admin.rounds.index',
          },
        },
        {
          icon: 'mdi-briefcase',
          text: this.$t('jobs'),
          auth: true,
          plan: 'pro',
          to: {
            name: 'admin.jobs.index',
          },
        },
        {
          heading: this.$t('follow_up'),
          auth: true,
        },
        {
          icon: 'mdi-finance',
          text: this.$t('my_investments'),
          auth: true,
          to: {
            name: 'profile.investments.index',
          },
        },
        {
          icon: 'mdi-briefcase-search',
          text: this.$t('my_applications'),
          auth: true,
          to: {
            name: 'profile.jobs.index',
          },
        },
        {
          heading: this.$t('account'),
          auth: true,
        },
        {
          icon: 'mdi-wallet',
          text: this.$t('wallet'),
          auth: true,
          to: {
            name: 'wallet.index',
          },
        },
        {
          icon: 'mdi-account-details',
          text: this.$t('my_profile'),
          auth: true,
          to: {
            name: 'profile.edit',
          },
        },
        {
          text: this.$t('my_subscription'),
          auth: true,
          to: {
            name: 'subscription.show',
          },
          icon: 'mdi-credit-card',
        },
        {
          text: this.$t('logout'),
          auth: true,
          to: {
            name: 'logout.show',
          },
          icon: 'mdi-logout',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      placeholders: "placeholders",
    }),
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters('auth', {
      isAuth: 'isAuth',
      isUserFetched: 'isUserFetched',
      isLoading: 'isLoading',
      isTabsToDrawer: 'isTabsToDrawer',
      isItemVisible: 'isItemVisible',
    }),
    ...mapGetters('profile', {
      profile: 'profile',
    }),
    tabsFiltered() {
      const items = this.tabs;
      return items.filter((item) => {
        return this.isItemVisible(item);
      });
    },
    avatar() {
      try {
        if (this.profile.attributes.photo !== null) {
          return this.profile.attributes.photo;
        } else {
          return this.placeholders.profile
        }
      } catch (e) {
        return this.placeholders.profile
      }
    },
    optionLanguage() {
      if (this.$i18n.locale === 'es') {
        return 'en';
      } else {
        return 'es';
      }
    },
  },
  async mounted() {
    await this.fetchProfile();
  },
  methods: {
    ...mapActions('profile', {
      fetchProfile: 'fetch',
    }),
    ...mapActions('auth', {
      onLogout: 'logout',
    }),
    ...mapActions({
      setLanguage: 'setLanguage',
    }),
    onChangeLanguage() {
      if (this.$i18n.locale === 'es') {
        this.$i18n.locale = 'en';
      } else {
        this.$i18n.locale = 'es';
      }

      this.setLanguage(this.$i18n.locale);
      window.location.reload();
    },
  },
  components: {
    AppDrawerList,
  },
};
</script>
