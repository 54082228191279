<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs">
        <app-title-crumb :title="$t('users_management')"></app-title-crumb>
    </app-breadcrumbs>
    <v-container fluid>
        <v-row align="center" justify="center" dense>
            <v-col cols="12" sm="10" md="12">
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :loading="isLoading" :headers="headers" :items="users || []" :items-per-page="5" class="elevation-1">
                        <template v-slot:item.created_at="{ item }">
                            <span>{{
                                    new Date(item.created_at).toLocaleString()
                                }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";
export default {
    data() {
        return {
            search: "",
            isLoading: true,
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "home"
                    }
                },
                {
                    text: this.$t('dashboard'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "linkbridge.dashboard.show"
                    }
                },
                {
                    text: "",
                    disabled: true
                }
            ],
            headers: [{
                    text: this.$t("name"),
                    value: "person.full_name"
                },
                {
                    text: this.$t("email"),
                    value: "email"
                },
                {
                    text: this.$t("date"),
                    value: "created_at"
                },
                {
                    text: this.$t("subscription"),
                    value: "status"
                }
            ]
        };
    },
    mounted() {
        this.prepareComponent();
    },
    computed: {
        ...mapGetters("users", {
            users: "users"
        })
    },
    methods: {
        ...mapActions("users", {
            fetch: "lbFetch"
        }),
        prepareComponent() {
            this.fetchUsers();
        },
        async fetchUsers() {
            this.isLoading = true;
            await this.fetch();
            this.isLoading = false;
        }
    },
    components: {
        AppBreadcrumbs,
        AppTitleCrumb
    }
};
</script>
