import Vue from "vue";
import VueRouter from "vue-router";
import * as storage from "./store/storage";
import store from "./store/store";

Vue.use(VueRouter);

function route(path, file, name, meta, beforeEnter, children) {
    return {
        exact: true,
        path,
        name,
        children,
        component: require(`./pages/${file}.vue`).default,
        meta: meta,
        beforeEnter: beforeEnter
    };
}

export const routes = [
    route("/", "home/Home", "home"),
    route("/terms-and-conditions", "public/TermsAndConditions", "terms.show"),
    route("/whitepaper", "public/Whitepaper", "whitepaper.show"),
    route("/airdrop", "public/Airdrop", "airdrop.show"),
    route("/support", "public/Support", "support.show"),

    // Auth
    route("/signup", "auth/Signup", "signup.show", { guest: true }),
    route("/login", "auth/Login", "login.show", { guest: true }),
    route("/logout", "auth/Logout", "logout.show", { auth: true }),
    route("/reset-password", "auth/password/ResetEmail", "reset.password.show", { guest: true }),
    route("/reset-password/:token", "auth/password/Reset", "reset.password.update", { guest: true }),

    // Projects
    route("/projects/create", "projects/Create", "projects.create"),
    route("/projects/:project/:slug?", "projects/Project", "projects.show"),

    // Rounds
    // route('/rounds', 'rounds/Rounds', 'rounds.index'),
    // route('/rounds/:id/:slug?', 'rounds/Round', 'rounds.show'),

    route("/investments", "investments/Investments", "investments.index"),
    route("/investments/:id", "investments/Investments", "investments.show"),

    //////////////////////////////////////////////////////////////////
    // Public
    //////////////////////////////////////////////////////////////////

    // Jobs
    route("/jobs", "jobs/Jobs", "jobs.index"),
    route("/jobs/:id/:slug?", "jobs/Job", "jobs.show"),

    //////////////////////////////////////////////////////////////////
    // Protected
    //////////////////////////////////////////////////////////////////

    // Home
    route("/dashboard", "dashboard/Dashboard", "dashboard", {
        auth: true
    }),

    // Onboarding
    route("/onboarding", "onboarding/Onboarding", "onboarding", {
        auth: true
    }),

    // Rounds
    route(
        "/rounds/:id/investments",
        "rounds/investments/Create",
        "rounds.investments.create",
        { auth: true }
    ),

    //////////////////////////////////////////////////////////////////
    // Account
    //////////////////////////////////////////////////////////////////

    // Investments
    route(
        "/profile/investments",
        "investments/Investments",
        "profile.investments.index",
        { auth: true }
    ),
    route(
        "/profile/investments/:investment",
        "investments/Investment",
        "profile.investments.show",
        { auth: true }
    ),

    // applications
    route("/profile/jobs", "applications/Jobs", "profile.jobs.index", {
        auth: true
    }),

    route("/profile/jobs/:id", "applications/Job", "profile.jobs.show", {
        auth: true
    }),

    // Profile
    route("/profile", "profile/Profile", "profile.edit", { auth: true }),
    route("/profile/:username", "profile/Public", "profile.show", {
        auth: true
    }),

    //////////////////////////////////////////////////////////////////
    // Admin
    //////////////////////////////////////////////////////////////////

    route(
        "/admin/projects",
        "admin/projects/Projects",
        "admin.projects.index",
        { auth: true }
    ),
    route(
        "/admin/projects/:project",
        "admin/projects/Project",
        "admin.projects.show",
        { auth: true }
    ),
    route(
        "/admin/projects/:project/contact",
        "admin/projects/Contact",
        "admin.projects.contact.show",
        { auth: true, plan: "pro" }
    ),
    route(
        "/admin/projects/:project/contact/:person",
        "admin/projects/Chat",
        "admin.projects.contact.person",
        { auth: true, plan: "pro" }
    ),

    // Rounds
    route("/admin/rounds", "admin/rounds/Rounds", "admin.rounds.index", {
        auth: true,
        plan: "pro"
    }),

    // Vacancies
    route("/admin/jobs", "admin/jobs/Jobs", "admin.jobs.index", {
        auth: true,
        plan: "pro"
    }),

    route(
        "/admin/jobs/:id/applications",
        "admin/jobs/Applications",
        "admin.jobs.applications.index",
        {
            auth: true,
            plan: "pro"
        }
    ),

    route(
        "/admin/applications/:id",
        "admin/jobs/Application",
        "admin.jobs.applications.show",
        {
            auth: true,
            plan: "pro"
        }
    ),

    //////////////////////////////////////////////////////////////////
    // Subscriptions
    //////////////////////////////////////////////////////////////////

    // Plans
    route("/plans", "subscription/Plans", "plans.index"),
    route("/plans/:id/:slug?", "subscription/Create", "subscription.create", {
        auth: true
    }),

    // Subscriptions
    route("/subscription", "subscription/Subscription", "subscription.show", {
        auth: true
    }),
    route(
        "/subscription/payment-method",
        "subscription/PaymentMethod",
        "subscription.payment.method.index",
        { auth: true }
    ),
    route(
        "/subscription/invoices",
        "subscription/Invoices",
        "subscription.invoices.index",
        { auth: true }
    ),

    //////////////////////////////////////////////////////////////////
    // Wallet
    //////////////////////////////////////////////////////////////////

    route("/wallet", "wallet/Wallet", "wallet.index", {
        auth: true
    }),

    //////////////////////////////////////////////////////////////////
    // Owner
    //////////////////////////////////////////////////////////////////
    // route("/linkbridge", "linkbridge/Dashboard", "linkbridge.dashboard.show", {
    //     auth: true,
    //     role: ["owner"]
    // }),

    route("/linkbridge/users", "linkbridge/Users", "linkbridge.users.index", {
        auth: true,
        role: ["owner"]
    }),

    route("/linkbridge/users/:id", "linkbridge/User", "linkbridge.users.show", {
        auth: true,
        role: ["owner"]
    }),

    route(
        "/linkbridge/projects",
        "linkbridge/Projects",
        "linkbridge.projects.index",
        {
            auth: true,
            role: ["owner"]
        }
    ),

    route(
        "/linkbridge/project/:id",
        "linkbridge/Project",
        "linkbridge.projects.show",
        {
            auth: true,
            role: ["owner"]
        }
    ),

    route(
        "/linkbridge/rounds",
        "linkbridge/Rounds",
        "linkbridge.rounds.index",
        {
            auth: true,
            role: ["owner"]
        }
    ),

    route(
        "/linkbridge/rounds/:id",
        "linkbridge/Round",
        "linkbridge.rounds.show",
        {
            auth: true,
            role: ["owner"]
        }
    ),

    route("/linkbridge/jobs", "linkbridge/Jobs", "linkbridge.jobs.index", {
        auth: true
    }),

    route("/linkbridge/jobs/:id", "linkbridge/Job", "linkbridge.jobs.show", {
        auth: true,
        role: ["owner"]
    }),

    /*
    |--------------------------------------------------------------------------
    | Redirects
    |--------------------------------------------------------------------------
    */
    route("*", "error/Error", "error")
];

const router = new VueRouter({ mode: "history", routes });
router.beforeEach((to, from, next) => {
    let hasMeta = to.matched.some(record => {
        return record.meta && (to.meta.auth || to.meta.guest || to.meta.role);
    });

    if (hasMeta) {
        const user = storage.local.getItem("user");
        const isAuth = storage.local.getItem("token");
        const isAuthRequired = to.meta.auth ? to.meta.auth : false;
        const isGuestRequired = to.meta.guest ? to.meta.guest : !isAuthRequired;
        const isRoleRequired = to.meta.role ? to.meta.role : false;
        const isPlanRequired = to.meta.plan ? to.meta.plan : false;

        // Auth user
        if (isAuthRequired) {
            if (!isAuth) {
                next({ name: "login.show" });
                return;
            }

            // Validate the plan
            if (isPlanRequired) {
                if (store.state.stripe.subscription === null) {
                    store.watch(
                        state => state.stripe.subscription,
                        value => {
                            if (value !== null) {
                                if (to.meta.plan === store.getters["stripe/planName"]) {
                                    next();
                                } else {
                                    next({ name: "dashboard" });
                                }
                            }
                        }
                    );
                } else {
                    if (to.meta.plan === store.getters["stripe/planName"]) {
                        next();
                    } else {
                        next({ name: "dashboard" });
                    }
                }
            }

            // Validate the roles
            if (isRoleRequired) {
                let hasRole = false;
                if (user && user.relationships.roles) {
                    let userRoles = {};

                    user.relationships.roles.forEach(role => {
                        userRoles[role.attributes.name] = true;
                    });

                    to.meta.role.forEach(role => {
                        if (role in userRoles) {
                            hasRole = true;
                        }
                    });
                }

                if (hasRole) {
                    next();
                } else {
                    next({ name: "dashboard" });
                }
            }
        }

        // Redirect auth users to dashboard
        if (isGuestRequired && isAuth) {
            next({ name: "dashboard" });
            return;
        }
    }

    next(); // make sure to always call next()!
});

export default router;
