import axios from "../../plugins/axios";

// Initial state
const state = {
    states: [],
    beneficiary: {},
    beneficiary_types: [],
    errors: [],
};
// Actions
const actions = {
    async fetchBeneficiaryTypes({ commit }) {
        try {
            const response = await axios.get(`api/beneficiary-types`);
            commit("setBeneficiaryTypes", response.data);
        } catch (error) {
            const { response } = error;
            if (response.status === 404) {
                console.log(error);
            }
        }
    },
    async fetchStates({ commit }, countryCode = "MX") {
        try {
            const response = await axios.get(
                `/api/countries/${countryCode}/states`
            );
            commit("setStates", response.data);
        } catch (error) {
            const { response } = error;
            if (response.status === 404) {
                console.log(error);
            }
        }
    },
    async fetchBeneficiary({ commit }) {
        try {
            const response = await axios.get(
                `/api/profile/personal-beneficiary`
            );
            commit("setBeneficiary", response.data);
        } catch (error) {
            const { response } = error;
            if (response.status === 404) {
                console.log(error);
            }
        }
    },
    async storeBeneficiary({ commit }, formData) {
        try {
            await axios.post(
                `/api/profile/personal-beneficiary`,
                formData
            );
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
};

// Mutations
const mutations = {
    setErrors(state, errors) {
        state.errors = errors;
    },
    setStates(state, states) {
        state.states = states;
    },
    setBeneficiary(state, beneficiary) {
        state.beneficiary = beneficiary;
    },
    setBeneficiaryTypes(state, beneficiaryTypes) {
        state.beneficiary_types = beneficiaryTypes;
    },
};

// Getters
const getters = {
    states: (state) => state.states,
    beneficiary: (state) => state.beneficiary,
    beneficiary_types: (state) => state.beneficiary_types,
    errors: (state) => state.errors,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
