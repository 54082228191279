var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-breadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}},[_c('app-title-crumb',{attrs:{"title":_vm.$t('payment_history'),"instructions":_vm.$t('payment_history_msg')}})],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center","dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.invoices,"items-per-page":5},scopedSlots:_vm._u([{key:"item.lines",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.lines.data[0].description))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.total / 100)))])]}},{key:"item.subtotal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.subtotal / 100)))])]}},{key:"item.status_transitions.paid_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("timestamp")(item.status_transitions.paid_at)))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }