<style scoped>
.card-text-height {
    height: 265px;
}
</style>
<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs">
        <app-title-crumb :title="$t('plans')" :instructions="$t('plans_instructions')"></app-title-crumb>
    </app-breadcrumbs>
    <v-container fluid>
        <v-row align="center" justify="center" v-if="plans === null">
            <v-col cols="12" sm="6" md="4" class="text-center" v-for="item in 2" :key="item">
                <v-skeleton-loader class="mx-auto" type="card-heading, card, actions"></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-row align="center" justify="center" v-else>
            <v-col cols="12" sm="6" md="4" class="text-center" v-for="item in plans" :key="item.id">
                <v-card>
                    <v-card-title primary-title class="justify-center">
                        {{ item.name }}
                    </v-card-title>
                    <v-card-text class="card-text-height">
                        <div class="caption py-1" v-for="(feature, i) in item.items" :key="i">
                            {{ feature.desc }}
                        </div>
                    </v-card-text>
                    <v-card-text class="display-1">
                        ${{ item.price }} MXN + IVA
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="my-2 mx-auto">
                            <v-btn large outlined @click="onSelectPlan(item)" v-if="item.price === 0">
                                {{
                                        isBasic ? $t('current_plan') : $t('select')
                                    }}
                            </v-btn>
                            <v-btn large color="accent" @click="onSelectPlan(item)" v-else>
                                {{
                                        isPro
                                            ? $t('current_plan')
                                            : grace
                                            ? $t('reactivate')
                                            : $t('select')
                                    }}
                            </v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="headline">{{ $t('contract_plan') }}</v-card-title>
                <v-card-text>
                    {{ $t('login_required_msg') }}
                </v-card-text>
                <v-card-actions>
                    <v-btn block color="accent" @click="onClickModal('login.show')">
                        {{ $t('login') }}
                    </v-btn>
                </v-card-actions>
                <v-card-actions>
                    <v-btn block outlined @click="onClickModal('signup.show')">
                        {{ $t('signup') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</div>
</template>

<script>
import {
    mapState,
    mapActions,
    mapGetters
} from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";

export default {
    data() {
        return {
            dialog: false,
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "home"
                    }
                },
                {
                    text: "",
                    disabled: true,
                    to: {
                        name: "home"
                    }
                }
            ]
        };
    },
    mounted() {
        this.prepareComponent();
    },
    computed: {
        ...mapState("subs", {
            plans: "plans"
        }),
        ...mapGetters("auth", {
            isAuth: "isAuth"
        }),
        ...mapGetters("stripe", {
            grace: "grace",
            isBasic: "isBasic",
            isPro: "isPro",
            status: "status",
            subscribed: "subscribed",
            subscription: "subscription",
            token: "token"
        })
    },
    methods: {
        ...mapActions("subs", {
            fetchPlans: "fetchPlans",
            setPlan: "setPlan"
        }),
        ...mapActions("stripe", {
            fetchSubscription: "fetchSubscription"
        }),
        async prepareComponent() {
            this.isLoading = true;
            if (this.isAuth) {
                await this.fetchSubscription();
            }
            this.fetchPlans();
            this.isLoading = false;
        },
        onSelectPlan(plan) {
            if (this.isAuth) {
                if (plan.id === 1) {
                    this.$router.push({
                        name: "subscription.show"
                    });
                } else {
                    if (this.subscribed) {
                        this.$router.push({
                            name: "subscription.show"
                        });
                    } else {
                        this.$router.push({
                            name: "subscription.create",
                            params: {
                                id: plan.id,
                                slug: plan.slug
                            }
                        });
                    }
                }
            } else {
                this.plan = plan;
                this.dialog = true;
            }
        },
        onClickModal(route) {
            this.modal = false;
            this.setPlan({
                plan_id: this.plan.id
            });
            this.plan = null;
            this.$router.push({
                name: route
            });
        }
    },
    components: {
        AppBreadcrumbs,
        AppTitleCrumb
    }
};
</script>
