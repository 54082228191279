<template>
<v-card flat :loading="isLoading">
    <v-card-title>
        {{ $t('bank_data') }}
    </v-card-title>
    <v-card-subtitle>
        {{ $t('bank_data_msg') }}
    </v-card-subtitle>
    <v-card-text>
        <v-form>
            <v-text-field :hint="$t('bank_account_holder_hint')" :label="$t('bank_account_holder')" v-model="form.account_holder" :error-messages="errors['form.account_holder']"></v-text-field>
            <v-text-field counter :hint="$t('bank_account_clabe_hint')" :label="$t('bank_account_clabe')" maxlength="18" minlength="18" :placeholder="$t('bank_account_clabe_placeholder')" type="number" v-model="form.clabe" v-on:keyup="onCableChange" :error-messages="errors['form.clabe']"></v-text-field>
            <v-select v-model="form.bank" :items="banks" item-text="attributes.description" item-value="id" return-object :label="$t('bank')" :hint="$t('bank_hint')" :error-messages="errors['form.bank']"></v-select>
        </v-form>
    </v-card-text>
    <v-card-actions>
        <v-btn color="accent" class="mr-4" @click="onSubmit" :loading="isProcessing">
            {{ $t('save') }}
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import {
    validationMixin
} from "vuelidate";
import {
    required,
    minLength,
    maxLength
} from "vuelidate/lib/validators";
import {
    vueFrontErrors,
    vueRulesMessages
} from "../../plugins/vuelidate";

export default {
    mixins: [validationMixin],
    validations: {
        form: {
            account_holder: {
                required
            },
            clabe: {
                required: required,
                minLength: minLength(18),
                maxLength: maxLength(18)
            },
            bank: {
                required
            }
        }
    },
    data() {
        return {
            isLoading: false,
            isProcessing: false,
            form: {
                account_holder: null,
                clabe: null,
                bank: null
            },
            errors: {
                "form.account_holder": [],
                "form.clabe": [],
                "form.bank": []
            },
            rules: {
                ...vueRulesMessages
            },
            validate: false
        };
    },
    mounted() {
        this.prepareComponent();
    },
    watch: {
        form: {
            handler() {
                // this.$v.form.$touch();
                this.validate = true;
            },
            deep: true
        },
        apiErrors: {
            handler() {
                this.validate = true;
            },
            deep: true
        },
        profile() {
            if (this.profile.attributes) {
                this.form.account_holder = this.profile.attributes.account_holder;
                this.form.clabe = this.profile.attributes.clabe;
                this.form.bank = this.banks.find(
                    bank => bank.id === this.profile.attributes.bank_id
                );
            }
        },
        validationForm(newValue) {
            if (newValue === false) {
                return;
            }

            this.validate = false;
            this.vueFrontErrors();
        }
    },
    computed: {
        ...mapGetters("bank", {
            banks: "banks",
            profile: "profile",
            apiErrors: "errors"
        }),
        validationForm() {
            return this.validate;
        }
    },
    methods: {
        async prepareComponent() {
            this.isLoading = true;
            await Promise.allSettled([
                this.fetchBanks()
            ]);
            await this.fetchProfile();
            this.isLoading = false;
        },
        ...mapActions("bank", {
            fetchBanks: "fetchBanks",
            fetchProfile: "fetchProfile",
            storeProfile: "storeProfile"
        }),
        vueFrontErrors: vueFrontErrors,
        onCableChange() {
            if (this.form.clabe === null) {
                return;
            }

            const code = this.form.clabe.substring(0, 3);
            this.form.bank = this.banks.find(
                bank => bank.attributes.code === code
            );
        },
        async onSubmit() {
            this.$v.form.$touch();
            let invalid = this.$v.form.$invalid;
            this.validate = true;

            if (invalid) {
                return;
            }

            this.isProcessing = true;
            const formProfile = {
                data: {
                    type: "bank_profile",
                    attributes: {
                        account_holder: this.form.account_holder,
                        clabe: this.form.clabe,
                        bank_id: this.form.bank.id
                    }
                }
            };

            await Promise.allSettled([this.storeProfile(formProfile)]);

            this.isProcessing = false;
        }
    }
};
</script>
