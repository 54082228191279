import axios from '../../plugins/axios';

/**
 * Remember
 * Commit: calls a mutation to update the state.
 * Dispatch: call an action to proccess data and pass the result to a commit.
 */

// Initial state
const state = {
    messages: [],
    isProcessing: false,
    errors: []
};

// Actions - Backend
const actions = {
    fetchMessages({ commit }, projectId) {
        axios
            .get(`/api/projects/${projectId}/contact`)
            .then(response => {
                commit('setMessages', { messages: response.data });
            })
            .catch(error => {
                if (error.response.status !== 404) {
                    console.log(error);
                }
            });
    },

    store({ commit, dispatch, rootState }, messageData) {
        commit('setProcessing', true);
        const token = rootState.auth.token;
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return axios
            .post(`/api/projects/${messageData.projectId}/contact`, messageData.form)
            .then(response => {
                commit('setProcessing', false);
                dispatch('fetchMessages', messageData.projectId);
                return response;
            })
            .catch(error => {
                if (error.response.status === 422) {
                    commit('setErrors', error.response.data.errors);
                } else {
                    console.log(error);
                }
                commit('setProcessing', false);
            });
    },
    async delete({ commit, rootState }, projectId) {
        commit('setProcessing', true);
        try {
            const token = rootState.auth.token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            await axios.post(`/api/projects/${projectId}/contact`);

            // Mutation
            commit('setProcessing', false);
        }
        catch (error) {
            commit('setProcessing', false);
        }
    },
};

// Mutations - State
const mutations = {
    setMessages(state, data) {
        state.messages = data.messages;
    },
    setProcessing(state, isProcessing) {
        state.isProcessing = isProcessing;
    },
    setErrors(state, errors) {
        state.errors = errors;
    }
};

// Getters - Return
const getters = {
    messages: (state) => {
        return state.messages;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
