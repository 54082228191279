<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs">
        <app-job-crumb :job="job" v-if="job" />
    </app-breadcrumbs>
    <v-container fluid>
        <v-card>
            <!--Data iterator-->
            <v-data-iterator :items="applicants || []" :items-per-page.sync="itemsPerPage" :page.sync="page" :search="search" :sort-by="sortBy.toLowerCase()" :sort-desc="sortDesc" hide-default-footer :loading="isLoading">
                <template v-slot:loading>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                    <p class="text-center ma-10">{{ $t('loading') }}</p>
                    <v-divider></v-divider>
                </template>
                <template v-slot:header>
                    <v-toolbar class="mb-1">
                        <v-text-field v-model="search" clearable flat solo-inverted hide-details prepend-inner-icon="mdi-magnify" :label="$t('search')"></v-text-field>
                        <template v-if="$vuetify.breakpoint.mdAndUp">
                            <v-spacer></v-spacer>
                            <v-select v-model="sortBy" flat solo-inverted hide-details :items="keys" prepend-inner-icon="mdi-magnify" :label="$t('sort_by')" class="d-none"></v-select>
                            <v-spacer></v-spacer>
                            <v-btn-toggle v-model="sortDesc" mandatory>
                                <v-btn large :value="false" color="accent" outlined>
                                    <v-icon>mdi-arrow-up</v-icon>
                                </v-btn>
                                <v-btn large :value="true" color="accent" outlined>
                                    <v-icon>mdi-arrow-down</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </template>
                    </v-toolbar>
                </template>

                <template v-slot:no-data>
                    <v-card-text>
                        <v-row class="ml-1 mr-1">
                            <v-col cols="12" class="text-center">
                                {{ $vuetify.lang.t('$vuetify.noDataText') }}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </template>
                <template v-slot:no-results>
                    <v-card-text>
                        <v-row class="ml-1 mr-1">
                            <v-col cols="12" class="text-center">
                                {{
                                        $vuetify.lang.t(
                                            '$vuetify.dataIterator.noResultsText'
                                        )
                                    }}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </template>

                <template v-slot:default="props">
                    <v-card v-for="applicant in props.items" :key="applicant.id" class="ma-2" :to="applicantProfile(applicant)">
                        <v-card-title class="mt-8">
                            <v-avatar size="75">
                                <img alt="user" :src="applicantPhoto(applicant)" />
                            </v-avatar>
                            <v-row>
                                <v-col cols="3">
                                    <div class="title ml-3">
                                        {{
                                                getPerson(applicant).attributes
                                                    .full_name
                                            }}
                                    </div>
                                    <div class="subtitle-1 ml-3">
                                        {{ getPrimaryProfile(applicant) }} {{ getSecondaryProfile(applicant) ? ` | ${getSecondaryProfile(applicant)}` : '' }}
                                    </div>
                                    <div class="subtitle-2 ml-3" v-if="getLocality(applicant)">
                                        {{ getLocality(applicant) }}, 
                                        {{ getAdministrativeArea(applicant) }}, 
                                        {{ getCountry(applicant) }}
                                    </div>
                                </v-col>
                                <!-- Estatus -->
                                <v-col cols="3">
                                    <div class="title ml-3">
                                        {{ applicant.relationships.status.attributes.name }}
                                    </div>
                                    <div class="subtitle-1 ml-3">
                                        {{ applicant.attributes.created_at_humans | capitalize }}
                                    </div>
                                </v-col>
                                <v-col cols="6">
                                    <v-list-item :href="mailTo(applicant)">
                                        <v-list-item-icon>
                                            <v-icon>mdi-email</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-subtitle>{{
                                                getUser(applicant).attributes.email
                                            }}</v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item :href="tel(applicant)">
                                        <v-list-item-icon>
                                            <v-icon>mdi-phone</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-subtitle>{{
                                                getPerson(applicant).attributes.phone
                                            }}</v-list-item-subtitle>
                                    </v-list-item>
                                    <div class="subtitle-2 ml-3"></div>
                                </v-col>
                            </v-row>
                        </v-card-title>
                    </v-card>
                </template>

                <template v-slot:footer>
                    <v-row class="ma-4" align="center" justify="center">
                        <span class="grey--text">
                            {{
                                    $vuetify.lang.t(
                                        '$vuetify.dataFooter.itemsPerPageText'
                                    )
                                }}
                        </span>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn dark text color="primary" class="ml-2" v-bind="attrs" v-on="on">
                                    {{ itemsPerPage }}
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(
                                            number, index
                                        ) in itemsPerPageArray" :key="index" @click="updateItemsPerPage(number)">
                                    <v-list-item-title>{{
                                            number
                                        }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-spacer></v-spacer>

                        <span class="mr-4 grey--text">
                            {{ $t('page') }} {{ page }} {{ $t('of') }}
                            {{ numberOfPages }}
                        </span>
                        <v-btn icon dark color="primary" class="mr-1" @click="formerPage">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn icon dark color="primary" class="ml-1" @click="nextPage">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-row>
                </template>
            </v-data-iterator>
            <!--/Data iterator-->
        </v-card>
        <app-confirm v-model="confirm" @confirmed="onDestroyConfirmed" :text="$t('vacancy_delete_question')"></app-confirm>
    </v-container>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex';
import AppBreadcrumbs from '@/components/organisms/Breadcrumbs';
import AppJobCrumb from '@/components/molecules/Breadcrumbs/Job';
import AppConfirm from '@/components/molecules/Confirm';

export default {
    data() {
        return {
            itemsPerPageArray: [5, 10, 15, 20],
            search: '',
            filter: {},
            sortDesc: false,
            page: 1,
            itemsPerPage: 5,
            sortBy: 'name',
            keys: [
                'Name',
                'Calories',
                'Fat',
                'Carbs',
                'Protein',
                'Sodium',
                'Calcium',
                'Iron',
            ],
            jobId: this.$route.params.id,
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: 'home',
                    },
                },
                {
                    text: this.$t('dashboard'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: 'dashboard',
                    },
                },
                {
                    text: '',
                    disabled: true,
                },
            ],
            isLoading: true,
            isFormOpen: false,
            candidate: null,
            confirm: false,
            item: null,
        };
    },
    computed: {
        ...mapGetters({
            placeholders: "placeholders",
        }),
        numberOfPages() {
            try {
                return Math.ceil(this.applicants.length / this.itemsPerPage);
            } catch (e) {
                return 0;
            }
        },
        filteredKeys() {
            return this.keys.filter((key) => key !== 'Name');
        },
        ...mapGetters('applications', {
            applicants: 'applications',
        }),
        ...mapGetters('jobs', {
            job: 'job',
        }),
    },
    mounted() {
        this.prepareComponent();
    },
    methods: {
        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1;
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1;
        },
        updateItemsPerPage(number) {
            this.itemsPerPage = number;
        },
        async prepareComponent() {
            await this.fetchJob(this.jobId);
            await this.fetch(this.jobId);
            this.isLoading = false;
        },
        ...mapActions('applications', {
            fetch: 'adminFetchByJobId',
            destroy: 'adminDestroy',
            update: 'adminUpdate',
        }),
        ...mapActions('jobs', {
            fetchJob: 'fetchById',
        }),
        onEdit(candidate) {
            this.candidate = candidate;
            this.isFormOpen = true;
        },
        onCreate() {
            this.candidate = null;
            this.isFormOpen = true;
        },
        async onStored() {
            this.isFormOpen = false;
            this.isLoading = true;
            await this.fetch();
            this.isLoading = false;
        },
        onDestroy(item) {
            this.item = item;
            this.confirm = true;
        },
        async onDestroyConfirmed(confirmed) {
            if (confirmed) {
                await this.destroy(this.item.id);
                this.isLoading = true;
                await this.fetch();
                this.isLoading = false;
            }
        },
        applicantPhoto(applicant) {
            try {
                const photo = this.getPerson(applicant).attributes.photo
                return photo ? photo : this.placeholders.profile;
            } catch (e) {
                return this.placeholders.profile;
            }
        },
        mailTo(applicant) {
            const jobTitle = this.job.attributes.title;
            const companyName = this.job.relationships.project.attributes.name;
            const subject = `${jobTitle} @ ${companyName}`;
            const body = `${this.$t('applicant_mail_body')} ${jobTitle}`;
            const uri = encodeURIComponent(`subject=${subject}&body=${body}`);
            return `mailto:${this.getUser(applicant).attributes.email}?${uri}`;
        },
        waMe(applicant) {
            const jobTitle = this.job.attributes.title;
            const companyName = this.job.relationships.project.attributes.name;
            const subject = `${jobTitle} @ ${companyName}`;
            const uri = encodeURIComponent(
                `text=${this.$t('applicant_wa_body')} ${subject}`
            );
            return `https://api.whatsapp.com/send?phone=${this.getPerson(applicant).attributes.phone}&${uri}`;
        },
        tel(applicant) {
            return `tel:${this.getPerson(applicant).attributes.phone}`;
        },
        applicantProfile(applicant) {
            return {
                name: 'admin.jobs.applications.show',
                params: {
                    id: applicant.id,
                },
            };
        },
        getUser(applicant) {
            return applicant.relationships.user;
        },
        getPerson(applicant) {
            return this.getUser(applicant).relationships.person;
        },
        getProfessionalProfile(applicant) {
            return this.getUser(applicant).relationships.professional_profile;
        },
        getPrimaryProfile(applicant) {
            try {
                return this.getProfessionalProfile(applicant).attributes.primary_profile
            } catch (e) {
                return null;
            }
        },
        getSecondaryProfile(applicant) {
            try {
                return this.getProfessionalProfile(applicant).attributes.secondary_profile
            } catch (e) {
                return null;
            }
        },
        getLocality(applicant) {
            try {
                return this.getPerson(applicant).attributes.location.address.locality
            } catch (e) {
                return null;
            }
        },
        getAdministrativeArea(applicant) {
            try {
                return this.getPerson(applicant).attributes.location.address.administrative_area_level_1
            } catch (e) {
                return null;
            }
        },
        getCountry(applicant) {
            try {
                return this.getPerson(applicant).attributes.location.address.country
            } catch (e) {
                return null;
            }
        }
    },
    components: {
        AppBreadcrumbs,
        AppJobCrumb,
        AppConfirm,
    },
};
</script>
