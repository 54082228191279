import axios from "axios";

const state = {
    applicants: null,
    defaultPaymentMethod: null,
    errors: [],
    intent: null,
    investment: null,
    invoices: [],
    newPaymentMethod: null,
    paymentMethods: [],
    subscription: null,
    token: document.querySelector('meta[name="stripe-token"]').content
};

const actions = {
    async fetchIntent({ commit, rootState }) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${rootState.auth.token}`;
        try {
            const response = await axios.get(`/api/stripe/intent`);
            commit("setIntent", response.data);
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async fetchPaymentMethods({ commit, rootState }) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${rootState.auth.token}`;
        try {
            const response = await axios.get(`/api/stripe/payment-method`);
            commit("setPaymentMethods", response.data);
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async storePaymentMethod({ commit, rootState }, paymentMethod) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${rootState.auth.token}`;

        try {
            const response = await axios.post(`/api/stripe/payment-method`, {
                paymentMethod
            });
            commit("setNewPaymentMethod", response.data);
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async fetchDefaultPaymentMethod(
        { commit, rootState },
        paymentMethod
    ) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${rootState.auth.token}`;

        try {
            const response = await axios.get(
                `/api/stripe/payment-method/default`,
                {
                    paymentMethod
                }
            );
            commit("setDefaultPaymentMethod", response.data);
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async updateDefaultPaymentMethod(
        { commit, rootState },
        paymentMethod
    ) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${rootState.auth.token}`;

        try {
            await axios.post(
                `/api/stripe/payment-method/default`,
                {
                    paymentMethod
                }
            );
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    // eslint-disable-next-line no-unused-vars
    async storeSubscription({ commit, rootState }, { planId, data }) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${rootState.auth.token}`;

        try {
            const response = await axios.post(`/api/stripe/subscription`, data);
            commit("setSubscription", response.data);
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    },
    async fetchSubscription({ commit, rootState }) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${rootState.auth.token}`;

        try {
            const response = await axios.get(`/api/stripe/subscription/show`);
            commit("setSubscription", response.data);
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                commit("setSubscription", false);
                console.log(error);
            }
        }
    },
    async destroySubscription({ commit, rootState }) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${rootState.auth.token}`;

        try {
            await axios.post(
                `/api/stripe/subscription/destroy`
            );
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
            return false;
        }

        return true;
    },
    async fetchInvoices({ commit, rootState }) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${rootState.auth.token}`;
        try {
            const response = await axios.get(`/api/stripe/invoices`);
            commit("setInvoices", response.data);
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    }
};

const mutations = {
    setIntent(state, intent) {
        state.intent = intent;
    },
    setPaymentMethods(state, paymentMethods) {
        state.paymentMethods = paymentMethods;
    },
    setNewPaymentMethod(state, newPaymentMethod) {
        state.paymentMethods.push(newPaymentMethod);
        state.newPaymentMethod = newPaymentMethod;
    },
    setDefaultPaymentMethod(state, defaultPaymentMethod) {
        state.defaultPaymentMethod = defaultPaymentMethod;
    },
    setInvoices(state, invoices) {
        state.invoices = invoices;
    },
    setSubscription(state, subscription) {
        state.subscription = subscription;
    }
};

const getters = {
    token: state => {
        return state.token;
    },
    intent: state => {
        return state.intent;
    },
    paymentMethods: state => {
        return state.paymentMethods;
    },
    newPaymentMethod: state => {
        return state.newPaymentMethod;
    },
    defaultPaymentMethod: state => {
        return state.defaultPaymentMethod.length === 0
            ? null
            : state.defaultPaymentMethod;
    },
    invoices: state => {
        return state.invoices;
    },
    subscription: state => {
        if (!state.subscription) {
            return null;
        }

        return state.subscription.data.length === 0
            ? null
            : state.subscription.data;
    },
    status: state => {
        return state.subscription ? state.subscription.status : null;
    },
    // eslint-disable-next-line no-unused-vars
    subscribed: (state, getters, rootState, rootGetters) => {
        if (!rootGetters["auth/isAuth"]) {
            return false;
        }

        switch (getters.status) {
            case "active":
            case "trial":
                return true;
            case "ended":
            default:
                return false;
        }
    },
    // eslint-disable-next-line no-unused-vars
    grace: (state, getters, rootState, rootGetters) => {
        return rootGetters["auth/isAuth"] && getters.status === "grace";
    },
    isBasic: (state, getters, rootState, rootGetters) => {
        return (
            rootGetters["auth/isAuth"] && !getters.subscribed && !getters.grace
        );
    },
    // eslint-disable-next-line no-unused-vars
    isPro: (state, getters, rootState, rootGetters) => {
        return rootGetters["auth/isAuth"] && getters.subscribed;
    },
    // eslint-disable-next-line no-unused-vars
    planName: (state, getters, rootState, rootGetters) => {
        if (getters.isBasic) {
            return "basic";
        }

        if (getters.isPro) {
            return "pro";
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
