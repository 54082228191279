<template>
    <div>
        <app-breadcrumbs :breadcrumbs="breadcrumbs">
            <app-project-crumb :project="project"></app-project-crumb>
        </app-breadcrumbs>
        <v-container fluid>
            <v-row align="center" justify="center" dense>
                <v-col cols="12" sm="10" md="7">
                    <app-project-description
                        :project="project"
                    ></app-project-description>
                    <app-rounds :rounds="rounds" class="mt-5"></app-rounds>
                    <app-jobs
                        :jobs="jobs"
                        :isDateVisible="isDateVisible"
                        :isSalaryVisible="isSalaryVisible"
                        class="mt-5"
                    ></app-jobs>
                </v-col>
            </v-row>
        </v-container>
        <app-continue v-model="dialog"></app-continue>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppContinue from "@/components/molecules/Continue";
import AppProjectCrumb from "@/components/molecules/Breadcrumbs/Project";
import AppJobs from "@/components/projects/jobs/List";
import AppRounds from "@/components/projects/rounds/List";
import AppProjectDescription from "@/components/projects/project/Description";

export default {
    data() {
        return {
            id: this.$route.params.project,
            isDateVisible: true,
            isSalaryVisible: false,
            dialog: false,
            rounded: false,
            flat: false,
            breadcrumbs: [
                {
                    text: this.$t('home'),
                    disabled: false,
                    to: {
                        name: "home"
                    },
                    exact: true
                },
                {
                    text: "",
                    disabled: true,
                    to: { name: "dashboard" }
                }
            ]
        };
    },
    computed: {
        ...mapGetters("auth", {
            isAuth: "isAuth"
        }),
        ...mapGetters("projects", {
            project: "project"
        }),
        ...mapGetters("jobs", {
            jobs: "jobs"
        }),
        ...mapGetters("rounds", {
            rounds: "rounds"
        })
    },
    mounted() {
        this.prepareComponent();
    },
    methods: {
        ...mapActions("projects", {
            fetchById: "fetchById"
        }),
        ...mapActions("jobs", {
            fetchPositions: "fetchByProjectId"
        }),
        ...mapActions("rounds", {
            fetchRounds: "fetchByProjectId"
        }),
        prepareComponent() {
            this.fetchById(this.id);
            this.fetchPositions(this.id);
            this.fetchRounds(this.id);
        },
        onContact() {
            if (this.isAuth) {
                this.$router.push({ name: "admin.projects.contact" });
            } else {
                this.dialog = true;
            }
        },
        onDownload() {
            // Download
        }
    },

    components: {
        AppBreadcrumbs,
        AppProjectCrumb,
        AppContinue,
        AppJobs,
        AppRounds,
        AppProjectDescription
    }
};
</script>
