<template>
    <div>
        <app-breadcrumbs :breadcrumbs="breadcrumbs">
            <app-title-crumb :title="$t('support_lnkb')" instructions="">
            </app-title-crumb>
        </app-breadcrumbs>

        <v-container fluid>
            <v-row class="mx-10 text-center" >
                <v-col>
                    <h1 class="text-h5 mb-4">
                        {{ $t('support_lnkb_instructions') }}
                    </h1>
                    <img :src="ethereum_qr_code" alt="LinkBridge Token" height="300">
                    <div class="text-subtitle-1">{{ethereum_address}}</div>
                    <v-btn class="white--text" :href="etherscan_link" color="accent" target="_blank">
                        <v-icon left>mdi-arrow-top-right</v-icon>
                        {{ $t('support_lnkb_button') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>


<script>
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";

export default {
    
    data() {
        return {
            id: this.$route.params.project,
            isDateVisible: true,
            isSalaryVisible: false,
            dialog: false,
            rounded: false,
            flat: false,
            etherscan_link:'https://etherscan.io/address/0x175e7014156d4d43abeb6b6fb0e4203da257f91f',
            ethereum_address: '0x175E7014156d4d43aBEB6B6fb0e4203DA257f91f',
            ethereum_qr_code: require('../../assets/images/ethereum_qr_code.png'),
            breadcrumbs: [
                {
                    text: this.$t('home'),
                    disabled: false,
                    to: { name: "home" },
                    exact: true
                },
                {
                    text: "",
                    disabled: true,
                    to: { name: "dashboard" }
                }
            ]
        };
    },
    components: {
        AppBreadcrumbs,
        AppTitleCrumb
    }
};
</script>
