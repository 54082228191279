<template>
<v-row align="center" justify="center">
    <v-col>
        <v-card flat tile color="transparent" class="text-center">
            <v-img :src="emptyPlaceholderComputed" max-height="150" class="my-10" contain></v-img>
            <div class="text-h6 ma-5">
                <div class="text-h6">{{ numberComputed }}</div>
                <div class="text-subtitle-1">{{ responseComputed }}</div>
            </div>
            <slot></slot>
        </v-card>
    </v-col>
</v-row>
</template>

<script>
import {
    mapGetters
} from "vuex";
export default {
    props: ["number", "response", "image"],
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            placeholders: "placeholders",
        }),
        numberComputed() {
            return this.number ? this.number : 404;
        },
        responseComputed() {
            return this.response ? this.response : this.$t('page_not_found');
        },
        emptyPlaceholderComputed() {
            return this.image ? this.image : this.placeholders.page_not_found;
        }
    },

};
</script>
