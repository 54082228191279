<template>
<v-list-item>
    <v-list-item-content>
        <v-row align="center" justify="center">
            <v-col>
                <v-list-item-title>
                    {{ job.title }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    <v-row>
                        <v-col sm="12" lg="6" v-if="isDateVisible">
                            {{ $t('posted_on') }} {{ job.created_at | date }}
                            |
                            {{ job.location }}
                        </v-col>
                        <v-col sm="12" lg="6" v-if="isSalaryVisible">
                            {{ $t('salary') }}
                            {{ job.salary_starting | currency }}
                            <span v-if="job.salary_final">
                                -
                                {{ job.salary_final | currency }}
                            </span>
                            {{ $t('currency_suffix') }}
                        </v-col>
                    </v-row>
                </v-list-item-subtitle>
            </v-col>
            <v-col lg="3">
                <v-btn color="accent" :to="{
                            name: 'jobs.show',
                            params: { id: job.id }
                        }">
                    {{ $t('view_job') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-list-item-content>
</v-list-item>
</template>

<script>
export default {
    props: ["job", "isDateVisible", "isSalaryVisible"]
};
</script>
