<template>
<v-row align="center" justify="center">
    <v-col>
        <v-card flat tile color="transparent" class="text-center">
            <v-img :src="placeholders.page_empty" max-height="150" class="my-10" contain></v-img>
            <div class="text-h6 ma-5">
                <div class="text-h6">{{ titleText }}</div>
                <div class="text-subtitle-1">{{ subtitleText }}</div>
            </div>
            <slot></slot>
        </v-card>
    </v-col>
</v-row>
</template>

<script>
import {
    mapGetters
} from "vuex";
export default {
    params: ['title', 'subtitle'],
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            placeholders: "placeholders",
        }),
        titleText() {
            return this.title || this.$t('not_results_found_title');
        },
        subtitleText() {
            return this.subtitle || this.$t('not_results_found_subtitle');
        }
    },
};
</script>
