<template>
<div>
    <app-http-error v-if="notFoundError"></app-http-error>
    <div v-else>
        <app-breadcrumbs :breadcrumbs="breadcrumbs">
            <app-project-crumb :project="project"></app-project-crumb>
        </app-breadcrumbs>
        <v-container fluid>
            <v-row align="center" justify="center" dense>
                <v-col cols="12" sm="10" md="7" v-if="isLoading">
                    <v-skeleton-loader class="mx-auto" type="card-heading, card, actions"></v-skeleton-loader>
                </v-col>
                <v-col cols="12" sm="10" md="7" v-else>
                    <v-card class="mx-auto">
                        <v-img height="100%" :src="this.placeholders.company_bg">
                            <v-row class="fill-height">
                                <v-col cols="12">
                                    <v-avatar class="profile ma-2" size="164" tile>
                                        <v-img :src="profilePictureUrl"></v-img>
                                    </v-avatar>
                                </v-col>
                                <v-col class="py-0">
                                    <v-list-item color="rgba(0, 0, 0, .4)" dark>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">
                                                {{ jobTitle }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ job.location }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-img>
                        <app-job-details :job="job" />
                        <div class="mb-5">
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn block :loading="isApplying" :disabled="hasApplied" @click="onApply" color="accent">
                                    {{ $t("apply") }}
                                </v-btn>
                            </v-card-actions>
                            <v-card-text v-if="hasApplied" class="text-center">
                                {{ $t("you_have_already_applied_for_this_job") }}
                            </v-card-text>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <app-continue v-model="dialog"></app-continue>
        </v-container>
    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppProjectCrumb from "@/components/molecules/Breadcrumbs/Project";
import AppContinue from "@/components/molecules/Continue";
import AppJobDetails from "@/components/jobs/job/Details";
import AppHttpError from '@/components/atoms/HttpError';

export default {
    data() {
        return {
            isLoading: true,
            isApplying: false,
            dialog: false,
            id: this.$route.params.id,
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    to: {
                        name: "home"
                    },
                    exact: true
                },
                {
                    text: this.$t('jobs'),
                    disabled: false,
                    to: {
                        name: "jobs.index"
                    },
                    exact: true
                },
                {
                    text: "",
                    disabled: true,
                    to: {
                        name: "dashboard"
                    }
                }
            ]
        };
    },
    mounted() {
        this.prepareComponent();
    },
    watch: {
        dialog(val) {
            if (val === false) {
                this.isApplying = false;
                this.stash(null);
            }
        }
    },
    computed: {
        ...mapGetters({
            placeholders: "placeholders",
        }),
        ...mapGetters("jobs", {
            job: "job"
        }),
        ...mapGetters("projects", {
            project: "project"
        }),
        ...mapGetters("auth", {
            isAuth: "isAuth"
        }),
        ...mapGetters("applications", {
            application: "application"
        }),
        hasApplied() {
            return this.application !== null;
        },
        notFoundError() {
            return this.job instanceof Error;
        },
        profilePictureUrl() {
            try {
                if(this.project.attributes.logo) {
                    return this.project.attributes.logo
                }
            } catch (e) {
                return this.placeholders.company;
            }

            return this.placeholders.company;
        },
        jobTitle() {
            return `${this.job.attributes.title} - ${this.project.attributes.name}`;
        }
    },
    methods: {
        async prepareComponent() {
            this.isLoading = true;
            await this.fetchPositionApplication(this.id);
            await this.fetchById(this.id);
            await this.fetchProjectById(this.job.attributes.project_id);
            this.isLoading = false;
        },
        ...mapActions("jobs", {
            fetchById: "fetchById"
        }),
        ...mapActions("projects", {
            fetchProjectById: "fetchById"
        }),
        ...mapActions("applications", {
            apply: "storeUserApplication",
            stash: "stashJobId",
            fetchPositionApplication: "fetchPositionApplication"
        }),
        async onApply() {
            this.isApplying = true;
            if (this.isAuth) {
                await this.apply(this.id);
            } else {
                this.stash(this.id);
                this.dialog = true;
            }
            this.isApplying = false;
        }
    },
    components: {
        AppBreadcrumbs,
        AppProjectCrumb,
        AppContinue,
        AppJobDetails,
        AppHttpError
    }
};
</script>
