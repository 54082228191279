<template>
<v-card flat class="mb-5">
  <v-card-text>
    <app-reports-list :reports="reports" :btnDelete="false"  />
  </v-card-text>
</v-card>
</template>

<script>
import AppReportsList from '@/components/admin/rounds/ReportsList';

export default {
  props: {
    investment: {
      type: Object,
      required: true
    }
  },
  components: {
    AppReportsList
  },
  computed: {
    reports() {
      return this.investment.relationships.round.relationships.reports || null
    }
  }
}
</script>
