<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs">
        <app-title-crumb :title="$t('rounds_management')"></app-title-crumb>
    </app-breadcrumbs>
    <v-container fluid>
        <v-row align="center" justify="center" dense>
            <v-col cols="12" sm="10" md="12">
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :loading="isLoading" :headers="headers" :items="rounds || []" :items-per-page="5" class="elevation-1">
                        <template v-slot:item.name="{ item }">
                            <router-link :to="{
                                        name: 'linkbridge.projects.show',
                                        params: { id: item.id }
                                    }">
                                {{ item.name }}
                            </router-link>
                        </template>
                        <template v-slot:item.amount="{ item }">
                            <span>{{ item.amount | currency }}</span>
                        </template>
                        <template v-slot:item.minimum="{ item }">
                            <span>{{ item.minimum | currency }}</span>
                        </template>
                        <template v-slot:item.raised="{ item }">
                            <span>{{ item.raised | currency }}</span>
                        </template>
                        <template v-slot:item.percent="{ item }">
                            <span>{{ item.percent | percent }}</span>
                        </template>
                        <template v-slot:item.start_date="{ item }">
                            <span>{{
                                    item.start_date | dateToLocale
                                }}</span>
                        </template>
                        <template v-slot:item.end_date="{ item }">
                            <span>{{ item.end_date | dateToLocale }}</span>
                        </template>
                        <template v-slot:item.id="{ item }">
                            <v-btn class="ma-2" outlined x-small fab color="indigo" :to="{
                                        name: 'linkbridge.rounds.show',
                                        params: { id: item.id }
                                    }">
                                <v-icon>mdi-details</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";
export default {
    data() {
        return {
            search: "",
            isLoading: true,
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "home"
                    }
                },
                {
                    text: this.$t('dashboard'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "linkbridge.dashboard.show"
                    }
                },
                {
                    text: "",
                    disabled: true
                }
            ],
            headers: [{
                    text: this.$t("company"),
                    value: "project.name"
                },
                {
                    text: this.$t("amount"),
                    value: "amount"
                },
                {
                    text: this.$t("minimum_investment"),
                    value: "minimum"
                },
                {
                    text: this.$t("percent"),
                    value: "percent"
                },
                {
                    text: this.$t("raised"),
                    value: "raised"
                },
                {
                    text: this.$t("start_date"),
                    value: "start_date"
                },
                {
                    text: this.$t("end_date"),
                    value: "end_date"
                },
                {
                    text: this.$t("status"),
                    value: "status.name"
                },
                {
                    text: this.$t("detail"),
                    value: "id"
                }
            ]
        };
    },
    mounted() {
        this.prepareComponent();
    },
    computed: {
        ...mapGetters("rounds", {
            rounds: "rounds"
        })
    },
    methods: {
        ...mapActions("rounds", {
            fetch: "lbFetch"
        }),
        async prepareComponent() {
            this.isLoading = true;
            await this.fetch();
            this.isLoading = false;
        }
    },
    components: {
        AppBreadcrumbs,
        AppTitleCrumb
    }
};
</script>
