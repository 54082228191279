<template>
    <router-link
        v-if="route"
        class="subtitle-2"
        style="text-decoration: underline;"
        :to="route"
    >
        {{ title }}
    </router-link>
</template>
<script>
export default {
    props: ["title", "route"]
};
</script>
