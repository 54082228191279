<template>
<v-card :loading="!project">
    <v-card-title>
        {{ $t('status') | uppercase }}
    </v-card-title>
    <v-card-text>
        <div v-if="project">
            <v-select v-model="selection" :items="items" :label="$t('select')"></v-select>
        </div>
        <v-skeleton-loader v-else class="mx-auto" type="text"></v-skeleton-loader>
    </v-card-text>
    <v-card-actions>
        <v-btn color="primary" outlined text block @click="onUpdate" :disabled="isUpdated">
            {{ $t('update') }}
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import {
    mapActions
} from "vuex";

export default {
    props: ["project"],
    data() {
        return {
            isUpdated: true,
            items: [{
                    text: "Pendiente",
                    value: 2
                },
                {
                    text: "Aprobado",
                    value: 3
                },
                {
                    text: "Rechazado",
                    value: 4
                },
                {
                    text: "Cancelado",
                    value: 5
                },
                {
                    text: "En pausa",
                    value: 8
                },
                {
                    text: "Finalizado",
                    value: 7
                }
            ]
        };
    },
    computed: {
        selection: {
            get() {
                return this.project ? this.project.relationships.status.attributes.status_id : 0;
            },
            set(newValue) {
                this.isUpdated = false;
                this.project.relationships.status.attributes.status_id = newValue;
            }
        }
    },
    methods: {
        ...mapActions("projects", {
            update: "lbUpdate"
        }),
        async onUpdate() {
            await this.update({
                projectId: this.project.id,
                statusId: this.project.relationships.status.attributes.status_id
            });
            this.isUpdated = true;
        }
    }
};
</script>
