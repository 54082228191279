<template>
<div>
    <v-card-title>
        {{ $t('description') | uppercase }}
    </v-card-title>
    <v-card-text style="white-space: pre-line;">
        {{ job.attributes.description }}
    </v-card-text>
    <v-card-title>
        {{ $t('salary') | uppercase }}
    </v-card-title>
    <v-card-subtitle>
        <span v-if="job">
            {{ job.attributes.salary_starting | currency }}
        </span>
        <span v-if="job && job.attributes.salary_final">
            -
            {{ job.attributes.salary_final | currency }}
        </span>
        MXN
    </v-card-subtitle>
    <div v-if="job && job.attributes.percent > 0">
        <v-card-title>
            {{ $t('actions') | uppercase }}
        </v-card-title>
        <v-card-subtitle>
            {{ job.attributes.percent | percent }}
        </v-card-subtitle>
    </div>
    <div v-if="job && job.attributes.type">
        <v-card-title>
            {{ $t('job_type') | uppercase }}
        </v-card-title>
        <v-card-subtitle>
            {{ job.attributes.type }}
        </v-card-subtitle>
    </div>
    <div v-if="job && job.attributes.contract">
        <v-card-title>
            {{ $t('contract_type') | uppercase }}
        </v-card-title>
        <v-card-subtitle>
            {{ job.attributes.contract }}
        </v-card-subtitle>
    </div>
    <slot></slot>
</div>
</template>

<script>
export default {
    props: ["job"]
};
</script>
