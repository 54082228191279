import axios from "axios";

// Initial state
const state = {
    banks: [],
    profile: {},
    errors: []
};

// Actions
const actions = {
    async fetchBanks({ commit }) {
        try {
            const response = await axios.get(`/api/banks`);
            commit("setBanks", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async fetchProfile({ commit }) {
        try {
            const response = await axios.get(`/api/profile/bank`);
            commit("setProfile", response.data);
        } catch (error) {
            const { response } = error;
            if (response.status === 404) {
                console.log(error);
            }
        }
    },
    async storeProfile({ commit }, formData) {
        try {
            await axios.post(`/api/profile/bank`, formData);
        } catch (error) {
            if (error.response.status === 422) {
                commit("setErrors", error.response.data.errors);
            } else {
                console.log(error);
            }
        }
    }
};

// Mutations
const mutations = {
    setBanks(state, banks) {
        state.banks = banks;
    },
    setProfile(state, profile) {
        state.profile = profile;
    },
    setErrors(state, errors) {
        state.errors = errors;
    }
};

// Getters
const getters = {
    profile: state => state.profile,
    banks: state => state.banks,
    errors: state => state.errors
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
