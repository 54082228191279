<template>
<div>
  <app-breadcrumbs :breadcrumbs="breadcrumbs">
    <app-project-crumb :project="project"> </app-project-crumb>
  </app-breadcrumbs>
  <v-container fluid>
    <v-row align="center" justify="center" dense>
      <v-col cols="12" sm="10" md="7">
        <v-card class="mx-auto">
          <v-img height="100%" :src="this.placeholders.company_bg">
            <v-row class="fill-height">
              <v-col cols="12">
                <v-avatar class="profile ma-2" size="164" tile>
                  <v-img :src="profilePictureUrl"></v-img>
                  <app-photo-uploader 
                    v-model="updateProfilePicture" 
                    :photo="uploaderPictureUrl" 
                    :storeAction="this.storeProjectPhoto" 
                    :destroyAction="this.destroyProjectPhoto" 
                    placeholderUrl="/" 
                  />
                </v-avatar>
              </v-col>
              <v-col class="py-0">
                <v-list-item color="rgba(0, 0, 0, .4)" dark>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6">
                      {{
                                                    project ? project.attributes.name : ''
                                                }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-img>
        </v-card>
        <app-project-create :title="$t('company')" :subtitle="$t('company_msg')" :project="project"></app-project-create>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';
import AppBreadcrumbs from '@/components/organisms/Breadcrumbs';
import AppProjectCrumb from '@/components/molecules/Breadcrumbs/Project';
import AppProjectCreate from '@/components/projects/project/Create';
import AppPhotoUploader from '@/components/molecules/PhotoUploader';

export default {
  data() {
    return {
      updateProfilePicture: false,
      id: this.$route.params.project,
      breadcrumbs: [{
          text: this.$t('home'),
          disabled: false,
          exact: true,
          to: {
            name: 'home'
          },
        },
        {
          text: this.$t('dashboard'),
          disabled: false,
          exact: true,
          to: {
            name: 'dashboard'
          },
        },
        {
          text: 'Mis Proyectos',
          disabled: false,
          exact: true,
          to: {
            name: 'admin.projects.index'
          },
        },
        {
          text: '',
          disabled: true,
          to: {
            name: 'admin.projects.index'
          },
        },
      ],
    };
  },
  mounted() {
    this.prepareComponent();
  },
  computed: {
    ...mapGetters({
      placeholders: "placeholders",
    }),
    ...mapGetters('projects', {
      project: 'project',
    }),
    profilePictureUrl() {
      try {
        if (this.project.attributes.logo) {
          return this.project.attributes.logo
        }
      } catch (e) {
        return this.placeholders.company;
      }
      return this.placeholders.company;
    },
    uploaderPictureUrl() {
      try {
        if (this.project.attributes.logo) {
          return this.project.attributes.logo
        }
      } catch (e) {
        return null;
      }

      return null
    },
  },
  methods: {
    async prepareComponent() {
      await this.fetchById(this.id);
    },
    ...mapActions('projects', {
      fetchById: 'fetchById',
      storeLogo: 'storeLogo',
      destroyLogo: 'destroyLogo',
    }),
    storeProjectPhoto(file) {
      return this.storeLogo({
        id: this.id,
        file,
      });
    },
    destroyProjectPhoto() {
      return this.destroyLogo(this.id);
    },
  },
  components: {
    AppBreadcrumbs,
    AppProjectCreate,
    AppProjectCrumb,
    AppPhotoUploader,
  },
};
</script>
