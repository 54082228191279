<template>
    <div>
        <app-button-photo-upload v-model="buttonUpload" />
        <v-dialog v-model="dialogPreviewer" width="400">
            <v-card class="mx-auto" tile>
                <v-card-title>
                    <span class="text-h5">{{ previewText }}</span>
                    <v-spacer></v-spacer>
                    <v-btn text small @click="onCancelPreview">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-list-item>
                    <v-list-item-avatar
                        tile
                        class="profile ma-2 mx-auto"
                        height="164"
                        width="auto"
                    >
                        <v-img contain :src="imageUrl"></v-img
                    ></v-list-item-avatar>
                </v-list-item>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="onOpenUploader">
                        {{ $t('upload_photo') }}
                    </v-btn>
                    <v-btn color="primary" text @click="onDestroy">
                        {{ $t('delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogUploader" width="400">
            <v-card class="mx-auto" tile>
                <v-card-title>
                    <span class="text-h5">{{ uploaderText }}</span>
                    <v-spacer></v-spacer>
                    <v-btn text small @click="onCancelUploader">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-list-item>
                    <v-list-item-avatar
                        tile
                        class="profile ma-2 mx-auto"
                        height="164"
                        width="auto"
                        style="cursor: pointer"
                    >
                        <v-icon x-large v-if="!imagePreviewURL">
                            mdi-file-upload
                        </v-icon>
                        <v-img contain :src="imagePreviewURL" v-else></v-img>
                    </v-list-item-avatar>
                </v-list-item>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="launchFilePicker">
                        {{ $t('upload_photo') }}
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="onStore"
                        :disabled="!imagePreviewURL"
                    >
                        {{ $t('save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-file-input
            v-model="file"
            ref="file"
            class="d-none"
            @change="onFileChange($event)"
        >
        </v-file-input>
    </div>
</template>
<script>
import {
    mapGetters
} from "vuex";
import AppButtonPhotoUpload from '@/components/atoms/ButtonPhotoUpload';
export default {
    props: [
        'titlePreview',
        'titleUploader',
        'photo',
        'storeAction',
        'destroyAction',
        'placeholderUrl',
    ],
    data() {
        return {
            file: null,
            dialogPreviewer: false,
            dialogUploader: false,
            buttonUpload: false,
            imagePreviewURL: null,
        };
    },
    watch: {
        buttonUpload(newValue) {
            if (newValue === false) {
                return;
            }

            if (!this.photo) {
                this.onOpenUploader();
            } else {
                this.dialogPreviewer = true;
            }

            this.buttonUpload = false;
        },
    },
    computed: {
        ...mapGetters({
            placeholders: "placeholders",
        }),
        previewText() {
            return this.titlePreview || this.$t('profile_photo');
        },
        uploaderText() {
            return this.titleUploader || this.$t('upload_photo');
        },
        imageUrl() {
            return this.photo || this.placeholders.profile;
        },
    },
    methods: {
        onCancelPreview() {
            this.dialogPreviewer = false;
        },
        async onDestroy() {
            await this.destroyAction();
            this.onCancelUploader();
            this.onCancelPreview();
        },
        /* Uploader */
        launchFilePicker() {
            this.$refs.file.$refs.input.click();
        },
        onFileChange(file) {
            this.imagePreviewURL = URL.createObjectURL(file);
        },
        onOpenUploader() {
            this.dialogUploader = true;
            this.$refs.file.$refs.input.click();
        },
        onCancelUploader() {
            this.dialogUploader = false;
            this.file = null;
            this.imagePreviewURL = null;
        },
        async onStore() {
            this.isProcessing = true;
            let formDataFiles = new FormData();
            formDataFiles.append('photo', this.file);
            await this.storeAction(formDataFiles);
            this.isProcessing = false;
            this.onCancelUploader();
            this.onCancelPreview();
        },
    },
    components: {
        AppButtonPhotoUpload,
    },
};
</script>
