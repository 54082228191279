<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs">
        <app-title-crumb :title="$t('jobs_management')"></app-title-crumb>
    </app-breadcrumbs>
    <v-container fluid>
        <v-row align="center" justify="center" dense>
            <v-col cols="12" sm="10" md="12">
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :loading="isLoading" :headers="headers" :items="jobs || []" :items-per-page="5" class="elevation-1">
                        <template v-slot:item.title="{ item }">
                            <router-link :to="{
                                        name: 'jobs.show',
                                        params: { id: item.id }
                                    }" target="_blank">
                                {{ item.title }}
                            </router-link>
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            <span>{{
                                    new Date(item.created_at).toLocaleString()
                                }}</span>
                        </template>
                        <template v-slot:item.salary_starting="{ item }">
                            {{ item.salary_starting | currency }} -
                            {{ item.salary_final | currency }}
                        </template>
                        <template v-slot:item.percent="{ item }">
                            {{ item.percent | percent }}
                        </template>
                        <template v-slot:item.id="{ item }">
                            <v-btn class="ma-2" outlined x-small fab color="indigo" :to="{
                                        name: 'linkbridge.jobs.show',
                                        params: { id: item.id }
                                    }">
                                <v-icon>mdi-details</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";
export default {
    data() {
        return {
            search: "",
            isLoading: true,
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "home"
                    }
                },
                {
                    text: this.$t('dashboard'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "linkbridge.dashboard.show"
                    }
                },
                {
                    text: "",
                    disabled: true
                }
            ],
            headers: [{
                    text: this.$t("job"),
                    value: "title"
                },
                {
                    text: this.$t("location"),
                    value: "location"
                },
                {
                    text: this.$t("salary_range"),
                    value: "salary_starting"
                },
                {
                    text: this.$t("share_percent"),
                    value: "percent"
                },
                {
                    text: this.$t("status"),
                    value: "status.name"
                },
                {
                    text: this.$t("detail"),
                    value: "id"
                }
            ]
        };
    },
    mounted() {
        this.prepareComponent();
    },
    computed: {
        ...mapGetters("jobs", {
            jobs: "jobs"
        })
    },
    methods: {
        ...mapActions("jobs", {
            fetch: "lbFetch"
        }),
        prepareComponent() {
            this.fetchPosition();
        },
        async fetchPosition() {
            this.isLoading = true;
            await this.fetch();
            this.isLoading = false;
        }
    },
    components: {
        AppBreadcrumbs,
        AppTitleCrumb
    }
};
</script>
