<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs">
        <app-job-crumb :job="job" subtitle="Perfil de candidato" v-if="application" />
    </app-breadcrumbs>
    <v-container fluid>
        <v-row align="center" justify="center" dense>
            <v-col cols="12" sm="10" md="7" v-if="isLoading">
                <v-skeleton-loader class="mx-auto" type="card-heading, card, actions"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="10" md="7" v-else>
                <v-card class="mx-auto" outlined tile>
                    <v-img height="100%" :src="this.placeholders.profile_bg">
                        <v-row class="fill-height">
                            <v-col cols="12">
                                <v-avatar class="profile ma-2" size="164" tile>
                                    <v-img :src="personPictureUrl"></v-img>
                                </v-avatar>
                                <div class="ml-2 text-center" style="width: 164px">
                                    <v-btn small icon color="white" v-if="githubUrl" :href="githubUrl" target="_blank">
                                        <v-icon>mdi-github</v-icon>
                                    </v-btn>
                                    <v-btn small icon color="white" v-if="linkedInUrl" :href="linkedInUrl" target="_blank">
                                        <v-icon>mdi-linkedin</v-icon>
                                    </v-btn>
                                    <v-btn small icon color="white" v-if="websiteUrl" :href="websiteUrl" target="_blank">
                                        <v-icon>mdi-web</v-icon>
                                    </v-btn>
                                    <v-btn small icon color="white" v-if="facebookUrl" :href="facebookUrl" target="_blank">
                                        <v-icon>mdi-facebook</v-icon>
                                    </v-btn>
                                    <v-btn small icon color="white" v-if="twitterUrl" :href="twitterUrl" target="_blank">
                                        <v-icon>mdi-twitter</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col class="py-0">
                                <v-list-item color="rgba(0, 0, 0, .4)" dark>
                                    <template v-slot:default="{  }">
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">
                                                {{ person.attributes.name }} {{ person.attributes.middle_name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ primaryProfile }}
                                                {{ secondaryProfile ? ' | ' + secondaryProfile : '' }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                {{ address }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action class="black--text" v-if="false">
                                            <v-btn color="white" class="black--text">
                                                <v-icon>mdi-download</v-icon>
                                                Descargar CV
                                            </v-btn>
                                        </v-list-item-action>
                                        <v-list-item-action>
                                            <v-btn color="accent" :href="mailTo()">
                                                <v-icon>mdi-email</v-icon>
                                                Contactar
                                            </v-btn>
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-img>
                </v-card>
                <v-card flat :loading="isLoading">
                    <!--About-->
                    <template v-if="about">
                        <v-card-title>{{ $t('about') }}</v-card-title>
                        <v-card-text>
                            {{ about}}
                        </v-card-text>
                    </template>
                    <v-list-item two-line v-if="gender">
                        <v-list-item-content>
                            <v-list-item-title> {{ $t('gender') }} </v-list-item-title>
                            <v-list-item-subtitle> {{ gender }} </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <!--End: About-->
                    <!--Professional data-->
                    <v-card-title>{{ $t('professional_data') }}</v-card-title>
                    <v-card-subtitle></v-card-subtitle>
                    <v-row>
                        <v-col>
                            <v-list-item two-line v-if="educationLevel">
                                <v-list-item-content>
                                    <v-list-item-title> {{ $t('educational_level') }} </v-list-item-title>
                                    <v-list-item-subtitle> {{ educationLevel }} </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col>
                            <v-list-item two-line v-if="currentJob">
                                <v-list-item-content>
                                    <v-list-item-title> {{ $t('current_job') }} </v-list-item-title>
                                    <v-list-item-subtitle> {{ currentJob }} </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-list-item two-line v-if="startupExperience">
                                <v-list-item-content>
                                    <v-list-item-title> {{ $t('startup_experience') }} </v-list-item-title>
                                    <v-list-item-subtitle> {{ startupExperience }} </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col>
                            <v-list-item two-line v-if="availableToTravel">
                                <v-list-item-content>
                                    <v-list-item-title> {{ $t('available_to_travel') }} </v-list-item-title>
                                    <v-list-item-subtitle> {{ availableToTravel }} </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                    <v-list-item two-line v-if="workForShares">
                        <v-list-item-content>
                            <v-list-item-title> {{ $t('working_for_shares') }} </v-list-item-title>
                            <v-list-item-subtitle> {{ workForShares }} </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <!--End: Professional data-->
                    <!--Desired employee-->
                    <v-card-title
                        v-if="employmentTypes.length > 0 || contractTypes.length > 0 || scheduleTypes.length > 0"
                    >
                        {{ $t('desired_job') }}
                    </v-card-title>
                    <v-list-item two-line v-if="employmentTypes.length > 0">
                        <v-list-item-content>
                            <v-list-item-title> {{ $t('types_of_employment') }} </v-list-item-title>
                            <v-list-item-subtitle>
                                <v-chip v-for="(item, i) in employmentTypes" :key="i" class="mr-1 mb-1">
                                    {{ item.attributes.description }}
                                </v-chip>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <div v-if="contractTypes.length > 0">
                        <v-list-item>
                            <v-list-item-content class="pb-0">
                                <v-list-item-title> {{ $t('types_of_contract') }} </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-card-text class="pt-0">
                            <v-chip v-for="(item, i) in contractTypes" :key="i" class="mr-1 mb-1">
                                {{ item.attributes.name }}
                            </v-chip>
                        </v-card-text>
                    </div>

                    <div v-if="scheduleTypes.length > 0">
                        <v-list-item>
                            <v-list-item-content class="pb-0">
                                <v-list-item-title> {{ $t('types_of_schedule') }} </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-card-text class="pt-0">
                            <v-chip v-for="(item, i) in scheduleTypes" :key="i" class="mr-1 mb-1">
                                {{ item.attributes.description }}
                            </v-chip>
                        </v-card-text>
                    </div>
                    <v-row>
                        <v-col>
                            <v-list-item two-line v-if="hourlyRate">
                                <v-list-item-content>
                                    <v-list-item-title> {{ $t('hourly_rate') }} </v-list-item-title>
                                    <v-list-item-subtitle> {{ hourlyRate | currency }} MXN</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col>
                            <v-list-item two-line v-if="monthlyRate">
                                <v-list-item-content>
                                    <v-list-item-title> {{ $t('monthly_salary') }} </v-list-item-title>
                                    <v-list-item-subtitle> {{ monthlyRate  | currency }} MXN </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                    <!--End: Desired employee-->
                    <!--Workspace-->
                    <template v-if="workspaceTypes.length > 0">
                        <v-card-title>{{ $t('workspace') }}</v-card-title>
                        <div v-if="workspaceTypes">
                            <v-card-text class="pt-0">
                                <v-chip v-for="(item, i) in workspaceTypes" :key="i" class="mr-1 mb-1">
                                    {{ item.attributes.description }}
                                </v-chip>
                            </v-card-text>
                        </div>
                    </template>
                    <!--End: Workspace-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppJobCrumb from '@/components/molecules/Breadcrumbs/Job';
// import AppContinue from "@/components/molecules/Continue";

export default {
    data() {
        return {
            id: this.$route.params.id,
            isLoading: true,
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    to: {
                        name: "home"
                    },
                    exact: true
                },
                {
                    text: "",
                    disabled: true,
                    to: {
                        name: "dashboard"
                    }
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            placeholders: "placeholders",
            education_levels: "education_levels",
            employment_types: "employment_types",
            schedule_types: "schedule_types",
            contract_types: "contract_types",
            workspace_types: "workspace_types",

        }),
        ...mapGetters("applications", {
            application: "application"
        }),
        job() {
            return this.application.relationships.job;
        },
        user() {
            return this.application.relationships.user;
        },
        status() {
            return this.application.relationships.status;
        },
        person() {
            return this.user.relationships.person;
        },
        professionalProfile() {
            return this.user.relationships.professional_profile;
        },
        socialNetworks() {
            return this.user.relationships.social_networks;
        },
        employmentTypes() {
            const employmentTypes = this.user.relationships.employment_types;
            return employmentTypes.map(employmentType => {
                return this.employment_types.find(type => type.id == employmentType.id);
            });
        },
        contractTypes() {
            const contractTypes = this.user.relationships.contract_types;
            return contractTypes.map(contractType => {
                return this.contract_types.find(type => type.id == contractType.id);
            });
        },
        scheduleTypes() {
            const scheduleTypes = this.user.relationships.schedule_types;
            return scheduleTypes.map(scheduleType => {
                return this.schedule_types.find(type => type.id == scheduleType.id);
            });
        },
        workspaceTypes() {
            const workspaceTypes = this.user.relationships.workspace_types;
            return workspaceTypes.map(workspaceType => {
                return this.workspace_types.find(type => type.id == workspaceType.id);
            });
        },
        linkedInUrl() {
            return this.getSocialNetwork("linkedin");
        },
        websiteUrl() {
            return this.getSocialNetwork("website");
        },
        twitterUrl() {
            return this.getSocialNetwork("twitter");
        },
        facebookUrl() {
            return this.getSocialNetwork("facebook");
        },
        githubUrl() {
            return this.getSocialNetwork("github");
        },
        personPictureUrl() {
            try {
                return this.person.attributes.photo === null ?
                    this.placeholders.profile :
                    this.person.attributes.photo;
            } catch (e) {
                return this.placeholders.profile;
            }
        },
        about() {
            try {
                return this.professionalProfile.attributes.about;
            } catch (e) {
                return "";
            }
        },
        gender() {
            const genders = {
                "1": this.$t('man'),
                "2": this.$t('woman'),
                "3": this.$t('other'),
            }

            return genders[this.person.attributes.gender];
        },
        educationLevel() {
            try {
                const id = this.professionalProfile.attributes.education_level_id
                return this.education_levels.find(level => level.id === parseInt(id)).attributes.description;
            } catch (e) {
                return null;
            }
        },
        currentJob() {
            try {
                if (parseInt(this.professionalProfile.attributes.is_currently_employed) === 1) {
                    return this.professionalProfile.attributes.employer_company;
                }
                return "No";
            } catch (e) {
                return null;
            }
        },
        startupExperience() {
            try {
                if (parseInt(this.professionalProfile.attributes.has_startup_experience) === 1) {
                    return this.professionalProfile.attributes.startup_yoe;
                }
                return 0;
            } catch (e) {
                return null;
            }
        },
        availableToTravel() {
            try {
                if (parseInt(this.professionalProfile.attributes.has_travel_availability) === 1) {
                    return this.$t("yes");
                }
                return "No";
            } catch (e) {
                return null;
            }
            
        },
        workForShares() {
            try {
                if (parseInt(this.professionalProfile.attributes.has_interest_actions) === 1) {
                    return this.$t("yes");
                }
                return "No";
            } catch (e) {
                return null;
            }
        },
        hourlyRate() {
            try {
                return this.professionalProfile.attributes.hourly_rate;
            } catch (e) {
                return null;
            }
        },
        monthlyRate() {
            try {
                return this.professionalProfile.attributes.monthly_rate;
            } catch (e) {
                return null;
            }
        },
        primaryProfile() {
            try {
                return this.professionalProfile.attributes.primary_profile
            } catch (e) {
                return null;
            }
        },
        secondaryProfile() {
            try {
                return this.professionalProfile.attributes.secondary_profile
            } catch (e) {
                return null;
            }
        },
        address() {
            try {
                let add = `${this.person.attributes.location.address.locality} `;
                add += `${this.person.attributes.location.address.administrative_area_level_1} `;
                add += `${this.person.attributes.location.address.country} `;
                return add;
            } catch (e) {
                return null;
            }
        },
    },
    mounted() {
        this.prepareComponent();
    },
    methods: {
        ...mapActions({
            fetchEducationLevels: "fetchEducationLevels",
            fetchEmploymentTypes: "fetchEmploymentTypes",
            fetchScheduleTypes: "fetchScheduleTypes",
            fetchContractTypes: "fetchContractTypes",
            fetchWorkspaceTypes: "fetchWorkspaceTypes"
        }),
        ...mapActions("applications", {
            fetchById: "adminFetchById"
        }),
        async prepareComponent() {
            await Promise.allSettled([
                await this.fetchEducationLevels(),
                await this.fetchEmploymentTypes(),
                await this.fetchScheduleTypes(),
                await this.fetchContractTypes(),
                await this.fetchWorkspaceTypes()
            ]);
            
            await this.fetchById(this.id);
            this.isLoading = false;
        },
        mailTo() {
            const jobTitle = this.job.attributes.title;
            const companyName = this.job.relationships.project.attributes.name;
            const subject = `${jobTitle} @ ${companyName}`;
            const body = `${this.$t('applicant_mail_body')} ${jobTitle}`;
            const uri = (`subject=${subject}&body=${body}`);
            return `mailto:${this.user.attributes.email}?${uri}`;
        },
        getSocialNetwork(name) {
            try {
                return this.socialNetworks.find(network => network.attributes.name === name).attributes.url;
            } catch (e) {
                return null;
            }
        },
    },
    components: {
        AppBreadcrumbs,
        AppJobCrumb
        // AppContinue,
    }
};
</script>
