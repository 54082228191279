import * as storage from "../storage";

/**
 * Remember
 * Commit: calls a mutation to update the state.
 * Dispatch: call an action to proccess data and pass the result to a commit.
 */

// Initial state
const state = {
    plan_id: null,
    subs_plan_id: null,
    plans: null
};

// Actions
const actions = {
    fetchPlans({ commit }) {
        const plans = [
            {
                id: 1,
                slug: "basic",
                name: "BÁSICO",
                items: [
                    { desc: "Perfil LinkBridge" },
                    { desc: "Invertir en empresas" },
                    { desc: "Aplicar a vacantes" },
                    { desc: "Tutoriales para inversionistas" }
                ],
                price: 0
            },
            {
                id: 2,
                slug: "pro",
                name: "PROFESIONAL",
                items: [
                    { desc: "Perfil LinkBridge" },
                    { desc: "Publicar proyectos" },
                    { desc: "Publicar vacantes" },
                    { desc: "Publicar rondas de inversión" },
                    { desc: "Invertir en empresas" },
                    { desc: "Aplicar a vacantes" },
                    { desc: "Acceso temprano a inversiones" },
                    { desc: "Tutoriales para inversionistas" },
                    { desc: "Tutoriales para emprendedores" }
                ],
                price: 150
            }
        ];

        commit("setPlans", plans);
    },
    setPlan({ commit }, data) {
        commit("setPlan", data);
    }
};

// Mutations
const mutations = {
    // Backend
    setPlans(state, plans) {
        state.plans = plans;
    },
    setPlan(state, data) {
        storage.local.setItem("plan_id", data.plan_id);
        state.plan_id = data.plan_id;
    }
};

// Getters
const getters = {
    selectedPlan: state => {
        if (!state.plans) {
            return null;
        }

        let plan_id =
            state.plan_id === null
                ? storage.local.getItem("plan_id")
                : state.plan_id;

        let plans = state.plans.filter(plan => plan.id === plan_id);

        if (plans.length === 0) {
            return null;
        }

        return plans[0];
    },
    subscribedPlan: state => {
        let plans = state.plans.filter(plan => plan.id === state.subs_plan_id);

        if (plans.length === 0) {
            return null;
        }

        return plans[0];
    },
    planById: state => id => {
        if (state.plans) {
            return state.plans.find(plan => plan.id === id);
        } else {
            return undefined;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
