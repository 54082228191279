<template>
<v-carousel cycle height="450" class="mb-5" :show-arrows="false" hide-delimiters>
    <v-carousel-item :src="images.banner">
        <v-row class="fill-height mx-10" align="center">
            <v-col cols="12" lg="6" sm="12">
                <h1 class="text-h4 mb-5 white--text">
                    LinkBridge
                </h1>
                <h4 class="subheading white--text mb-5">
                    {{ $t('banner_subtitle_1') }}
                </h4>
                <p class="white--text">
                    {{ $t('banner_subtitle_2') }}
                </p>
                <v-btn class="white--text" :to="{ name: 'signup.show' }" color="accent">
                    <v-icon left>mdi-account-plus</v-icon>
                    {{ $t('signup') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-carousel-item>
</v-carousel>
</template>

<script>
import {
    mapGetters
} from "vuex";

export default {
    computed: {
        ...mapGetters("home", {
            images: "images"
        }),
    }
};
</script>
