<style scoped>
a,
.footer--color-white {
  color: white !important;
}

.footer--active-class {
  color: white !important;
}
</style>
<template>
<div>
  <v-footer dark padless>
    <v-card flat tile class="lighten-1 white--text text-center" color="#2A2A2A" width="100%">
      <v-row class="pa-5">
        <v-col class="my-auto">
          <img :src="placeholders.logo_footer" alt="LinkBridge Logo" height="135">
          <v-list dense dark color="#2A2A2A" class="pt-10">
            <v-btn v-for="network in social" :key="network.icon" class="mx-2 white--text" icon :href="network.href" target="_blank">
              <v-icon size="24px">
                {{ network.icon }}
              </v-icon>
            </v-btn>
          </v-list>
        </v-col>
        <v-spacer></v-spacer>
        <!--Account-->
        <v-col class="text-left" v-for="column in columns" :key="column.title">
          <v-list dense dark color="#2A2A2A">
            <v-subheader class="ml-4">{{ $t(column.title) | uppercase }}</v-subheader>
            <v-list-item v-for="child in column.children" :key="child.title">
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn :to="child.to" :href="child.href" text :target="child.target ? child.target : ''" active-class="footer--active-class" x-small exact exact-path>
                    {{ $t(child.title) }}
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <!--Token-->
      </v-row>
      <v-divider></v-divider>
      <v-card-text>
        {{ $t('all_rights_reserved', {year: year}) }}
      </v-card-text>
    </v-card>
  </v-footer>
</div>
</template>

<script>
import {
  mapGetters
} from "vuex";
export default {
  data() {
    return {
      columns: [{
          title: "company",
          children: [{
              title: "home",
              to: {
                name: "home"
              }
            },
            {
              title: "jobs",
              to: {
                name: "jobs.index"
              }
            },
            {
              title: "pricing",
              to: {
                name: "plans.index"
              }
            },
            {
              title: "contact_us",
              href: "mailto:team@linkbridge.app",
            },
            {
              title: "terms_and_conditions",
              to: {
                name: "terms.show"
              }
            }
          ]
        },
        {
          title: "token",
          children: [{
              title: "whitepaper",
              to: {
                name: "whitepaper.show"
              }
            },
            {
              title: "airdrop",
              to: {
                name: "airdrop.show"
              }
            },
            {
              title: "Tokpie",
              href: "https://tokpie.com/view_exchange/lnkb-eth/",
              target: "_blank"
            },
            {
              title: "support_lnkb",
              to: {
                name: "support.show"
              }
            },
            {
              title: "UniSwap",
              href: "https://app.uniswap.org/#/swap?inputCurrency=0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64&outputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7&chain=mainnet",
              target: "_blank"
            },
            {
              title: "ParaSwap",
              href: " https://app.paraswap.io/#/?network=ethereum",
              target: "_blank"
            },
            {
              title: "Flooz Trade",
              href: "https://flooz.trade/trade/0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64?network=eth",
              target: "_blank"
            },
            {
              title: "Arken Finance",
              href: "https://swap.arken.finance/tokens/ethereum/0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64",
              target: "_blank"
            },
            {
              title: "Apple pay & Bank cards",
              href: "https://tokpie.zendesk.com/hc/en-us/articles/7027567813649--The-LinkBridge-LNKB-Token-Listed-on-Tokpie-#h_01G4XD4R5FSMWDTYNHTM4Z449B",
              target: "_blank"
            },
          ]
        },
        {
          title: "account",
          children: [{
              title: "signup",
              to: {
                name: "signup.show"
              }
            },
            {
              title: "login",
              to: {
                name: "login.show"
              }
            }
          ]
        },
      ],
      social: [{
          icon: 'mdi-facebook',
          href: "https://www.facebook.com/LNKBridge/"
        },
        {
          icon: 'mdi-twitter',
          href: "https://twitter.com/LinkBridgeTeam"
        },
        {
          icon: 'mdi-linkedin',
          href: "https://www.linkedin.com/company/lnkbridge/"
        },
        {
          icon: 'mdi-instagram',
          href: "https://www.instagram.com/lnkbridge/"
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      placeholders: "placeholders",
    }),
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>
