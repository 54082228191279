<template>
<v-container fluid class="my-5">
    <v-row class="mx-10">
        <v-col>
            <h1 class="text-h5 mb-4">
                {{ $t('faq') }}
            </h1>
            <p>
                {{ $t('faq_msg') }} {{ $t('help_center') }}. 
            </p>
        </v-col>
    </v-row>
    <v-row class="mx-10">
        <v-col>
            <v-expansion-panels accordion>
                <v-expansion-panel v-for="(item,i) in questions" :key="i" class="my-1">
                    <v-expansion-panel-header>
                        <h1 class="text-h6"> {{ item.title }} </h1>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div v-html="item.content"></div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    data() {
        return {
            questions: [{
                    title: this.$t('faq_q1'),
                    content: this.$t('faq_a1')
                },
                {
                    title: this.$t('faq_q2'),
                    content: this.$t('faq_a2')
                },
                {
                    title: this.$t('faq_q3'),
                    content: this.$t('faq_a3')
                },
                {
                    title: this.$t('faq_q4'),
                    content: this.$t('faq_a4')
                },
                {
                    title: this.$t('faq_q5'),
                    content: this.$t('faq_a5')
                },
            ]
        };
    },
};
</script>
