/**
 * Remember
 * Commit: calls a mutation to update the state.
 * Dispatch: call an action to proccess data and pass the result to a commit.
 */

// Initial state
const state = {
    drawer: null,
    images: {
        banner: require("../../assets/images/main_banner.jpg")
    },
};

// Actions - Backend
const actions = {


};

// Mutations - State
const mutations = {

};

// Getters - Return
const getters = {
    images: (state) => {
        return state.images;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
