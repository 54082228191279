/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vue from 'vue';
import axios from 'axios';
window.axios = axios;
window.Vue = Vue;

import App from './App.vue';
import store from './store/store';
import router from './router';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';

// https://medium.com/hypefactors/add-i18n-and-manage-translations-of-a-vue-js-powered-website-73b4511ca69c
// https://kazupon.github.io/vue-i18n/started.html#javascript

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.filter('currency', (value) => {
    if (value === null || value === undefined) {
        return '';
    }

    return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
});

Vue.filter('currency_mx', (value) => {
    if (value === null || value === undefined) {
        return '';
    }

    return '$' + value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' MXN'
});

Vue.filter('numeric', (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
});

Vue.filter('percent', (value) => {
    if (!value) {
        return '';
    }

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%';
});

Vue.filter('date', (value) => {
    const date = new Date(value);
    let month = +date.getMonth() + 1;
    let day = date.getDate();
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return `${day}/${month}/${date.getFullYear()}`;
});

Vue.filter('timestamp', (value) => {
    const date = new Date(value * 1000);
    let month = +date.getMonth() + 1;
    let day = date.getDate();
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return `${day}/${month}/${date.getFullYear()}`;
});

Vue.filter('dateToLocale', (value) => {
    return value.toLocaleString();
});

Vue.filter('capitalize', (value) => {
    if (!value) {
        return '';
    }

    return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('uppercase', (value) => {
    return value.toUpperCase();
});

new Vue({
    vuetify,
    router,
    store,
    i18n,
    el: '#app',
    render: (h) => h(App),
});
