import axios from "../../plugins/axios";

// Initial state
const state = {
    economic_activities: [],
    economic_activities_user: [],
    monthly_amounts: [],
    profile: {}
};

// Actions
const actions = {
    async fetchEconomicActivities({ commit }) {
        try {
            const response = await axios.get(
                `/api/catalog/economic-activities`
            );
            commit("setEconomicActivities", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async fetchEconomicActivitiesUser({ commit }) {
        try {
            const response = await axios.get(
                `/api/profile/economic-activities`
            );
            commit("setEconomicActivitiesUser", response.data);
        } catch (error) {
            const { response } = error;
            if (response.status === 404) {
                console.log(error);
            }
        }
    },
    // eslint-disable-next-line no-empty-pattern
    async storeEconomicActivitiesUser({  }, formData) {
        try {
            await axios.post(
                `/api/profile/economic-activities`,
                formData
            );
        } catch (error) {
            console.log(error);
        }
    },
    // eslint-disable-next-line no-empty-pattern
    async storeInvestmentIndustriesUser({ }, formData) {
        try {
            await axios.post(
                `/api/profile/investment-industries`,
                formData
            );
        } catch (error) {
            console.log(error);
        }
    },
    // eslint-disable-next-line no-empty-pattern
    async storeInvestmentTypesUser({ }, formData) {
        try {
            await axios.post(
                `/api/profile/investment-types`,
                formData
            );
        } catch (error) {
            console.log(error);
        }
    },
    // eslint-disable-next-line no-empty-pattern
    async fetchMonthlyAmounts({ commit }) {
        try {
            const response = await axios.get(`/api/catalog/monthly-amounts`);
            commit("setMonthlyAmounts", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async fetchInvestmentProfile({ commit }) {
        try {
            const response = await axios.get(`/api/profile/investment-profile`);
            commit("setInvestmentProfile", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    // eslint-disable-next-line no-empty-pattern
    async storeInvestmentProfile({ }, formData) {
        try {
            await axios.post(
                `/api/profile/investment-profile`,
                formData
            );
        } catch (error) {
            console.log(error);
        }
    }
};

// Mutations
const mutations = {
    setEconomicActivities(state, economic_activities) {
        state.economic_activities = economic_activities;
    },
    setMonthlyAmounts(state, monthly_amounts) {
        state.monthly_amounts = monthly_amounts;
    },
    setInvestmentProfile(state, profile) {
        state.profile = profile;
    },
    setEconomicActivitiesUser(state, economic_activities_user) {
        state.economic_activities_user = economic_activities_user;
    }
};

// Getters
const getters = {
    activities: state => state.economic_activities,
    activities_user: state => {
        const activities = [];
        state.economic_activities_user.forEach(activity => {
            activities.push(activity.id);
        });

        return activities;
    },
    amounts: state => state.monthly_amounts,
    profile: state => state.profile
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
