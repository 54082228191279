<template>
<v-card flat :loading="isLoading">
    <v-card-title>
        {{ $t('economic_activity') }}
    </v-card-title>
    <v-card-subtitle>
        {{ $t('economic_activity_msg') }}
    </v-card-subtitle>
    <v-card-text>
        <v-radio-group v-model="form.is_public_worker" :error-messages="validateErrors.is_public_worker">
            <template v-slot:label>
                {{ $t('do_you_work_for_any_public_sector_entity') }}
            </template>
            <v-radio :label="$t('yes')" :value="1"></v-radio>
            <v-radio :label="$t('no')" :value="0"></v-radio>
        </v-radio-group>
        <v-radio-group v-model="form.has_public_relative" :error-messages="validateErrors.has_public_relative">
            <template v-slot:label>
                {{ $t('do_your_family_work_for_any_public_sector_entity') }}
            </template>
            <v-radio :label="$t('yes')" :value="1"></v-radio>
            <v-radio :label="$t('no')" :value="0"></v-radio>
        </v-radio-group>
        <v-select :items="activities" v-model="form.activities" chips item-text="name" item-value="id" :label="$t('do_you_do_any_of_the_following_activities')" multiple></v-select>
        <v-radio-group v-model="form.monthly_income" :error-messages="validateErrors.monthly_income">
            <template v-slot:label>
                {{ $t('what_is_your_monthly_income') }}
            </template>
            <v-radio v-for="amount in amounts" :key="amount.amount" :value="amount.amount">
                <template v-slot:label>
                    {{ $t('until') }} {{ amount.amount | currency }}
                </template>
            </v-radio>
        </v-radio-group>
        <v-radio-group v-model="form.monthly_investment" :error-messages="validateErrors.monthly_investment">
            <template v-slot:label>
                {{ $t('what_is_the_amount_you_could_invest_monthly') }}
            </template>
            <v-radio v-for="amount in amounts" :key="amount.amount" :value="amount.amount">
                <template v-slot:label>
                    {{ $t('until') }} {{ amount.amount | currency }}
                </template>
            </v-radio>
        </v-radio-group>
        <v-radio-group>
            <template v-slot:label>
                {{ $t('have_you_made_any_kind_of_investment') }}
            </template>
            <v-checkbox :key="investment.id" :label="investment.attributes.description" hide-details v-for="investment in investment_types" v-model="form.investments" :value="investment.id"></v-checkbox>
        </v-radio-group>
    </v-card-text>
    <v-card-text>
        <v-select :items="industries" v-model="form.industries" attach chips :label="$t('in_which_industries_are_you_interested_in_investing')" item-text="attributes.name" item-value="id" multiple></v-select>
    </v-card-text>
    <v-card-actions>
        <v-btn color="accent" class="mr-4" @click="onSubmit" :loading="isProcessing">
            {{ $t('save') }}
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import {
    validationMixin
} from "vuelidate";
import {
    required
} from "vuelidate/lib/validators";

const apiErrors = (name, errors) => {
    const result = [];
    if (errors.length != 0) {
        if (errors[name] != undefined) {
            result.push(errors[name][0]);
        }
        delete errors[name];
    }
    return result;
};

export default {
    mixins: [validationMixin],
    validations: {
        form: {
            is_public_worker: {
                required
            },
            has_public_relative: {
                required
            },
            monthly_income: {
                required
            },
            monthly_investment: {
                required
            }
        }
    },
    data() {
        return {
            isLoading: false,
            isProcessing: false,
            form: {
                is_public_worker: null,
                has_public_relative: null,
                monthly_income: null,
                monthly_investment: null,
                activities: [],
                industries: [],
                investments: []
            },
            errors: [],
            validateErrors: {
                is_public_worker: [],
                has_public_relative: [],
                monthly_income: [],
                monthly_investment: []
            },
            rules: {
                required: this.$t('validation_required')
            },
            validate: false
        };
    },
    mounted() {
        this.prepareComponent();
    },
    watch: {
        activities_user() {
            this.form.activities = this.activities_user;
        },
        profile() {
            this.form.is_public_worker = this.profile.attributes.is_public_worker;
            this.form.has_public_relative = this.profile.attributes.has_public_relative;
            this.form.monthly_income = this.profile.attributes.monthly_income;
            this.form.monthly_investment = this.profile.attributes.monthly_investment;

            if (this.profile.relationships) {
                if (this.profile.relationships.investment_types) {
                    this.form.investments = this.profile.relationships.investment_types.map(
                        investment_type => {
                            return investment_type.id;
                        }
                    );
                }

                if (this.profile.relationships.investment_industries) {
                    this.form.industries = this.profile.relationships.investment_industries.map(
                        industry => {
                            return industry.id;
                        }
                    );
                }
            }
        },
        validatioForm(newValue) {
            if (newValue === false) {
                return;
            }

            this.validate = false;

            for (const key in this.validateErrors) {
                this.validateErrors[key] = [];
                const errors = apiErrors(key, this.errors);

                if (!this.$v.form[key].$dirty && errors.length == 0) {
                    this.validateErrors[key] = errors;
                    continue;
                }

                for (let rule in this.rules) {
                    !this.$v.form[key][rule] &&
                        this.validateErrors[key].push(this.rules[rule]);
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            investment_types: "investment_types",
            industries: "industries"
        }),
        ...mapGetters("economic_activity", {
            amounts: "amounts",
            activities: "activities",
            activities_user: "activities_user",
            profile: "profile"
        }),
        validatioForm() {
            return this.validate;
        }
    },
    methods: {
        async prepareComponent() {
            this.isLoading = true;
            await Promise.allSettled([
                this.fetchEconomicActivities(),
                this.fetchMonthlyAmounts(),
                this.fetchIndustries(),
                this.fetchInvestmentTypes(),
                this.fetchEconomicActivitiesUser()
            ]);
            await this.fetchInvestmentProfile();
            this.isLoading = false;
        },
        ...mapActions({
            apiErrors: "apiErrors"
        }),
        ...mapActions({
            fetchInvestmentTypes: "fetchInvestmentTypes",
            fetchIndustries: "fetchIndustries"
        }),
        ...mapActions("economic_activity", {
            fetchEconomicActivities: "fetchEconomicActivities",
            fetchMonthlyAmounts: "fetchMonthlyAmounts",
            fetchEconomicActivitiesUser: "fetchEconomicActivitiesUser",
            fetchInvestmentProfile: "fetchInvestmentProfile",
            storeInvestmentProfile: "storeInvestmentProfile",
            storeEconomicActivitiesUser: "storeEconomicActivitiesUser",
            storeInvestmentIndustriesUser: "storeInvestmentIndustriesUser",
            storeInvestmentTypesUser: "storeInvestmentTypesUser"
        }),
        async onSubmit() {
            this.validate = true;
            this.$v.form.$touch();
            let invalid = this.$v.form.$invalid;

            if (invalid) {
                return;
            }

            this.isProcessing = true;
            const formProfile = {
                data: {
                    type: "investment_profile",
                    attributes: {
                        is_public_worker: this.form.is_public_worker,
                        has_public_relative: this.form.has_public_relative,
                        monthly_income: this.form.monthly_income,
                        monthly_investment: this.form.monthly_investment
                    }
                }
            };
            const formActivities = {
                data: {
                    type: "economic_activities",
                    attributes: {
                        activities: this.form.activities
                    }
                }
            };
            const formIndustries = {
                data: {
                    type: "industries",
                    attributes: {
                        industries: this.form.industries
                    }
                }
            };

            const formInvestmentTypes = {
                data: {
                    type: "investment_types",
                    attributes: {
                        investments: this.form.investments
                    }
                }
            };

            await Promise.allSettled([
                this.storeInvestmentProfile(formProfile),
                this.storeEconomicActivitiesUser(formActivities),
                this.storeInvestmentIndustriesUser(formIndustries),
                this.storeInvestmentTypesUser(formInvestmentTypes)
            ]);

            this.isProcessing = false;
        }
    }
};
</script>
