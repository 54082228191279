<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs"></app-breadcrumbs>
    <v-container fluid>
        <v-row align="center" justify="center">
            Project details
        </v-row>
    </v-container>
</div>
</template>

<script>
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
export default {
    data() {
        return {
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    to: {
                        name: "home"
                    },
                    exact: true
                },
                {
                    text: this.$t('dashboard'),
                    disabled: true,
                    to: {
                        name: "dashboard"
                    }
                }
            ],
        };
    },
    computed: {},
    methods: {},
    components: {
        AppBreadcrumbs
    }
};
</script>
