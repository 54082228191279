<style>
.v-image__image--cover_scoped .v-image__image--cover {
    background-size: 80%;
    background-position: center;
}
.html-container {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    height: 133px;
}
</style>
<template>
<v-card :loading="loading" max-width="374" link :to="{
            name: 'projects.show',
            params: { project: data.id, slug: data.attributes.slug },
        }">
    <div class="v-image__image--cover_scoped">
        <v-img height="200" width="200" :src="logo" class="mx-auto"></v-img>
    </div>
    <v-divider></v-divider>
    <v-card-title>{{ data.attributes.name }}</v-card-title>
    <v-card-text >
        <div class="html-container" v-html="data.attributes.description"></div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
        <v-list-item class="grow">
            <v-list-item-avatar color="grey darken-3">
                <v-img class="elevation-6" :src="avatar"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title>
                    {{ personName }}
                </v-list-item-title>
            </v-list-item-content>

            <v-row align="center" justify="end">
                <!-- <v-icon class="mr-1">mdi-new-box</v-icon>
                    <span class="mr-1">·</span>
                    <v-icon class="mr-1">mdi-star-circle-outline</v-icon> -->
            </v-row>
        </v-list-item>
    </v-card-actions>
</v-card>
</template>

<script>
import {
    mapGetters
} from "vuex";

export default {
    props: {
        data: Object,
    },
    computed: {
        ...mapGetters({
            placeholders: "placeholders",
        }),
        logo() {
            try {
                if(this.data.attributes.logo) {
                    return this.data.attributes.logo;
                }
            } catch(e) {
                return this.placeholders.company;
            }

            return this.placeholders.company;
        },
        avatar() {

            try {
                if(this.data.relationships.user.relationships.person.attributes.photo) {
                    return this.data.relationships.user.relationships.person.attributes.photo;
                }
            } catch(e) {
                return this.placeholders.profile
            }

            return this.placeholders.profile
        },
        personName() {
            try {
                if(this.data.relationships.user.relationships.person.attributes.name) {
                    return this.data.relationships.user.relationships.person.attributes.name;
                }
            } catch(e) {
                return "";
            }

            return "";
        },
    },
    data: () => ({
        loading: false,
        link: true,
    }),
};
</script>
