<template>
<div>
    <v-skeleton-loader class="mx-auto" type="card-heading, card, actions" v-if="isLoading"></v-skeleton-loader>
    <div v-bind:style="{
                display: isLoading ? 'none' : 'inherit'
            }">
        <v-radio-group v-model="paymentMethod">
            <v-radio v-for="pm in paymentMethods" :key="pm.id" :value="pm.id">
                <template v-slot:label>
                    {{ pm.card.brand | capitalize }} - {{ pm.card.last4 }} -
                    {{ $t('expires_abbr') }} {{ pm.card.exp_month }}/{{ pm.card.exp_year }} -
                    {{ pm.billing_details.name }}
                </template>
            </v-radio>
            <v-radio :key="0" :value="0" :label="$t('new_payment_method')">
            </v-radio>
        </v-radio-group>
        <div v-bind:style="{
                    display: paymentMethod ? 'none' : 'inherit'
                }">
            <v-text-field :label="$t('name_on_the_card')" v-model="form.name" required></v-text-field>
            <div id="card-element" class="mb-2">
                <!-- A Stripe Element will be inserted here. -->
            </div>
            <v-divider></v-divider>
            <small>
                {{ stripe.error }}
            </small>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";

export default {
    props: ["value"],
    data() {
        return {
            lockSubmit: false,
            isLoading: false,
            stripe: {
                stripe: undefined,
                card: undefined,
                elements: undefined,
                error: null
            },
            form: {
                name: null,
                amount: null
            }
        };
    },
    mounted() {
        this.prepareComponent();
    },
    computed: {
        ...mapGetters("stripe", {
            stripeToken: "token",
            intent: "intent",
            paymentMethods: "paymentMethods",
            newPaymentMethod: "newPaymentMethod",
            defaultPaymentMethod: "defaultPaymentMethod"
        }),
        paymentMethod: {
            // getter
            get: function () {
                return this.value === null ? 0 : this.value;
            },
            // setter
            set: function (newValue) {
                const value = newValue === 0 ? null : newValue;
                this.$emit("input", value);
            }
        }
    },
    methods: {
        ...mapActions("stripe", {
            fetchIntent: "fetchIntent",
            storePaymentMethod: "storePaymentMethod",
            fetchPaymentMethods: "fetchPaymentMethods",
            fetchDefaultPaymentMethod: "fetchDefaultPaymentMethod"
        }),
        async prepareComponent() {
            this.isLoading = true;
            await Promise.all([
                this.fetchDefaultPaymentMethod(),
                this.fetchPaymentMethods(),
                this.initStripe()
            ]);
            this.setDefaultPaymentMethod();
            this.isLoading = false;
            this.$emit("onloaded", true);
        },
        async initStripe() {
            /* eslint-disable */
            this.stripe.stripe = Stripe(this.stripeToken, {
                locale: "es"
            });
            this.stripe.elements = this.stripe.stripe.elements();
            this.stripe.card = this.stripe.elements.create("card");
            this.stripe.card.mount("#card-element");
            this.stripe.card.addEventListener("change", event => {
                if (event.error) {
                    this.stripe.error = event.error.message;
                } else {
                    this.stripe.error = "";
                }
            });
        },
        setDefaultPaymentMethod() {
            this.paymentMethod = 0;

            if (this.defaultPaymentMethod) {
                this.paymentMethod = this.defaultPaymentMethod.id;
            } else if (this.paymentMethods.length > 0) {
                this.paymentMethod = this.paymentMethods[0].id;
            }
        },
        async getPaymentMethod() {
            if (this.paymentMethod !== 0) {
                this.$emit("input", this.paymentMethod);
            } else {
                try {
                    await this.fetchIntent();
                    await this.createCard();
                    this.$emit("input", this.newPaymentMethod.id);
                } catch (error) {
                    this.$emit("input", 0);
                }
            }
        },
        async createCard() {
            const result = await this.stripe.stripe.handleCardSetup(
                this.intent.client_secret,
                this.stripe.card, {
                    payment_method_data: {
                        billing_details: {
                            name: this.form.name
                        }
                    }
                }
            );

            if (result.error) {
                this.stripe.error = result.error.message;
            } else {
                await this.storePaymentMethod(
                    result.setupIntent.payment_method
                );
            }
        }
    }
};
</script>
