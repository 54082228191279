<template>
<v-dialog v-model="dialog" max-width="350">
    <v-card>
        <v-card-title class="headline">{{ $t('login') }}</v-card-title>
        <v-card-text>
            {{ $t('plan_pro_required_msg') }}
        </v-card-text>
        <v-card-actions>
            <v-btn block color="accent" @click="onClickModal('plans.index')">
                {{ $t('continue') }}
            </v-btn>
        </v-card-actions>
        <v-card-actions>
            <v-btn block outlined @click="dialog = false">
                {{ $t('cancel') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    props: ["value"],
    computed: {
        dialog: {
            // getter
            get: function () {
                return this.value;
            },
            // setter
            set: function (newValue) {
                this.$emit("input", newValue);
            }
        }
    },
    methods: {
        onClickModal(route) {
            this.dialog = false;
            this.$router.push({
                name: route
            });
        }
    }
};
</script>
