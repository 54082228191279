<template>
    <div>
        <app-breadcrumbs :breadcrumbs="breadcrumbs">
            <app-title-crumb
                :title="$t('subscription_linkbridge')"
                :instructions="$t('subscription_linkbridge_msg')"
            ></app-title-crumb>
        </app-breadcrumbs>
        <v-container fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="10" md="7">
                    <v-card>
                        <v-card-title>
                            1. {{ $t('payment_method') }}
                        </v-card-title>
                        <v-card-subtitle>
                            {{ $t('payment_method_msg') }}
                        </v-card-subtitle>
                        <v-card-text>
                            <app-payment-methods
                                ref="appPaymentMethods"
                                v-model="paymentMethod"
                            ></app-payment-methods>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="dialog = false"
                                :disabled="lockSubmit"
                                v-if="false"
                            >
                                {{ $t('cancel') }}
                            </v-btn>
                            <v-btn
                                color="accent"
                                @click="onSubscribe"
                                :disabled="lockSubmit"
                                :loading="lockSubmit"
                            >
                                {{ $t('complete_subscription') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
// https://medium.com/javascript-in-plain-english/integrating-with-stripe-client-basics-c9f188329143
// https://serversideup.net/using-stripe-elements-in-a-vuejs-component/
// https://vuejsexamples.com/credit-card-component-for-vue/

import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppPaymentMethods from "@/components/stripe/PaymentMethods";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";

function apiErrors(name, errors) {
    const result = [];
    if (errors.length != 0) {
        if (errors[name] != undefined) {
            result.push(errors[name][0]);
        }
        delete errors[name];
    }
    return result;
}

export default {
    mixins: [validationMixin],
    validations: {
        form: {
            name: { required },
            card: { required },
            date: { required },
            cvv: { required }
        }
    },
    data() {
        return {
            id: this.$route.params.id,
            plan: null,
            paymentMethod: null,
            lockSubmit: false,
            errors: [],
            breadcrumbs: [
                {
                    text: this.$t('home'),
                    disabled: false,
                    to: { name: "home" },
                    exact: true
                },
                {
                    text: "Planes",
                    disabled: false,
                    to: { name: "plans.index" },
                    exact: true
                },
                {
                    text: "Suscripción",
                    disabled: true
                }
            ]
        };
    },
    mounted() {
        this.prepareComponent();
    },
    computed: {
        ...mapGetters("projects", {
            isStashed: "isStashed"
        }),
        nameErrors() {
            const errors = apiErrors("name", this.errors);

            if (!this.$v.form.name.$dirty && errors.length == 0) return errors;

            let rules = {
                required: "Este campo es requerido"
            };

            for (let rule in rules) {
                !this.$v.form.name[rule] && errors.push(rules[rule]);
            }

            return errors;
        }
    },
    methods: {
        ...mapActions("stripe", {
            storeSubscription: "storeSubscription"
        }),
        prepareComponent() {},
        async onSubscribe() {
            this.lockSubmit = true;
            await this.$refs.appPaymentMethods.getPaymentMethod();

            if (this.paymentMethod !== 0) {
                await this.storeSubscription({
                    planId: this.id,
                    data: {
                        payment_method: this.paymentMethod
                    }
                });

                if (this.isStashed) {
                    this.$router.replace({ name: "projects.create" });
                } else {
                    this.$router.replace({ name: "profile.investments.index" });
                }
            }

            this.lockSubmit = false;
        }
    },
    components: {
        AppBreadcrumbs,
        AppPaymentMethods,
        AppTitleCrumb
    }
};
</script>
