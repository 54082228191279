<template>
<v-card v-if="jobs && jobs.length">
    <v-card-title>
        {{ $t('jobs') | uppercase }}
    </v-card-title>
    <v-list two-line subheader>
        <div v-for="(job, index) in jobs" :key="job.id">
            <app-list-item :job="job" :isDateVisible="isDateVisible" :isSalaryVisible="isSalaryVisible"></app-list-item>
            <v-divider v-if="index !== jobs.length - 1"></v-divider>
        </div>
    </v-list>
</v-card>
</template>

<script>
import AppListItem from "./ListItem";
export default {
    components: {
        AppListItem
    },
    props: ["jobs", "isDateVisible", "isSalaryVisible"]
};
</script>
