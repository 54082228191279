import Vue from "vue";
import Vuex from "vuex";

// Guest
import main from "./main";
import address from "./module/address";
import applications from "./module/applications";
import auth from "./module/auth";
import bank from "./module/bank";
import beneficiary from "./module/beneficiary";
import contact from "./module/contact";
import economic_activity from "./module/economic_activity";
import home from "./module/home";
import investments from "./module/investments";
import jobs from "./module/jobs";
import professional from "./module/professional";
import profile from "./module/profile";
import projects from "./module/projects";
import rounds from "./module/rounds";
import stripe from "./module/stripe";
import subs from "./module/subs";
import users from "./module/users";
import onboarding from "./module/onboarding";

Vue.use(Vuex);

export default new Vuex.Store({
    ...main,
    modules: {
        address,
        applications,
        auth,
        bank,
        beneficiary,
        contact,
        economic_activity,
        home,
        investments,
        jobs,
        professional,
        profile,
        projects,
        rounds,
        stripe,
        subs,
        users,
        onboarding
    }
});
