<style scoped>
.app-link {
    color: inherit;
    text-decoration: none;
    display: inherit;
    width: 100%;
}
</style>
<template>
    <div>
        <template v-for="item in itemsFiltered">
            <v-row v-if="item.heading" :key="item.heading" align="center">
                <v-col cols="6">
                    <v-subheader v-if="item.heading">
                        {{ item.heading }}
                    </v-subheader>
                </v-col>
            </v-row>
            <v-list-group
                v-else-if="item.children"
                :key="item.text"
                v-model="item.model"
                :prepend-icon="item.model ? item.icon : item['icon-alt']"
                append-icon=""
            >
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
                <!--Item-->
                <v-list-item
                    v-for="(child, i) in item.children"
                    :key="i"
                    :to="child.to"
                    :href="child.href"
                    link
                    exact
                >
                    <v-list-item-action>
                        <v-icon>{{ child.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ child.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!--/Item-->
            </v-list-group>
            <!--Item-->
            <v-list-item :key="item.text" link :to="item.to" :href="item.href" exact v-else>
                <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.text }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <!--/Item-->
        </template>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        items: Array
    },
    mounted() {
        this.prepareComponent();
    },
    computed: {
        ...mapGetters("auth", {
            isAuth: "isAuth",
            isItemVisible: "isItemVisible"
        }),
        ...mapGetters("stripe", {
            planName: "planName"
        }),
        itemsFiltered() {
            return this.items.filter(item => {
                let isVisible = this.isItemVisible(item);

                if (item.plan === undefined) {
                    return isVisible;
                } else {
                    return isVisible && item.plan === this.planName;
                }
            });
        }
    },
    methods: {
        ...mapActions("stripe", {
            fetchSubscription: "fetchSubscription",
        }),
        async prepareComponent() {
            await this.fetchSubscription();
        }
    }
};
</script>
