<template>
<v-card :loading="!job">
    <v-card-title>
        {{ $t('status') | uppercase }}
    </v-card-title>
    <v-card-text>
        <div v-if="job">
            <v-select v-model="selection" :items="items" :label="$t('select')"></v-select>
        </div>
        <v-skeleton-loader v-else class="mx-auto" type="text"></v-skeleton-loader>
    </v-card-text>
    <v-card-actions>
        <v-btn color="primary" outlined text block @click="onUpdate" :disabled="isUpdated">
            {{ $t('update') }}
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import {
    mapActions
} from 'vuex';

export default {
    props: ['job'],
    data() {
        return {
            isUpdated: true,
            items: [{
                    text: this.$t('pending'),
                    value: 2
                },
                {
                    text: this.$t('approved'),
                    value: 3
                },
                {
                    text: this.$t('rejected'),
                    value: 4
                },
                {
                    text: this.$t('canceled'),
                    value: 5
                },
                {
                    text: this.$t('paused'),
                    value: 6
                },
                {
                    text: this.$t('finished'),
                    value: 7
                },
            ],
        };
    },
    computed: {
        selection: {
            get() {
                try {
                    return this.job ? this.job.relationships.status.attributes.status_id : 0;
                } catch (e) {
                    return 0;
                }
            },
            set(newValue) {
                this.isUpdated = false;
                this.job.relationships.status.attributes.status_id = newValue;
            },
        },
    },
    methods: {
        ...mapActions('jobs', {
            update: 'lbUpdate',
        }),
        async onUpdate() {
            await this.update({
                jobId: this.job.id,
                statusId: this.job.relationships.status.attributes.status_id,
            });
            this.isUpdated = true;
        },
    },
};
</script>
