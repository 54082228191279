<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs">
        <app-title-crumb :title="$t('jobs')" :instructions="$t('jobs_msg')">
        </app-title-crumb>
    </app-breadcrumbs>
    <v-container fluid>
        <v-skeleton-loader class="mx-auto" type="card-heading, card, actions" v-if="jobs === null"></v-skeleton-loader>
        <app-empty-state v-else-if="jobs.length === 0"></app-empty-state>
        <v-row align="center" justify="center" dense>
            <v-col v-for="job in jobs" :key="job.id" :cols="12">
                <v-card outlined :to="{
                            name: 'jobs.show',
                            params: { id: job.id },
                        }">
                    <v-list-item three-line>
                        <app-list-item-logo :logo="companyLogo(job)"></app-list-item-logo>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5">
                                {{ job.attributes.title }}
                            </v-list-item-title>
                            <div class="text-overline">
                                <a :to="companyRoute(job)" @click.stop="" style="text-decoration: none">
                                    {{ companyName(job) }}
                                </a>
                            </div>
                            <v-list-item-subtitle class="mb-2">
                                {{ job.attributes.location }} |
                                {{ $t('posted_on') }} {{ job.attributes.created_at | date }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="my-auto">
                            <v-btn color="accent" :to="{
                                        name: 'jobs.show',
                                        params: { id: job.id },
                                    }">
                                {{ $t('view_job') }}
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex';
import AppBreadcrumbs from '@/components/organisms/Breadcrumbs';
import AppTitleCrumb from '@/components/molecules/Breadcrumbs/Title';
import AppEmptyState from '@/components/atoms/EmptyState';
import AppListItemLogo from '@/components/atoms/ListItemLogo';

export default {
    components: {
        AppBreadcrumbs,
        AppTitleCrumb,
        AppEmptyState,
        AppListItemLogo,
    },
    data() {
        return {
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: 'home',
                    },
                },
                {
                    text: '',
                    disabled: true,
                },
            ],
        };
    },
    mounted() {
        this.prepareComponent();
    },
    computed: {
        ...mapGetters({
            placeholders: "placeholders",
        }),
        ...mapGetters('jobs', {
            jobs: 'jobs',
        }),
    },
    methods: {
        ...mapActions('jobs', {
            fetch: 'fetch',
        }),
        prepareComponent() {
            this.fetchPositions();
        },
        async fetchPositions() {
            this.isLoading = true;
            await this.fetch();
            this.isLoading = false;
        },
        companyName(job) {
            try {
                return job.relationships.project.attributes.name;
            } catch (e) {
                return '';
            }
        },
        companyLogo(job) {
            try {
                let logo = job.relationships.project.attributes.logo;
                return logo === null ? this.placeholders.company : logo;
            } catch (error) {
                return this.placeholders.company;
            }
        },
        companyRoute(job) {
            try {
                return {
                    name: 'projects.show',
                    params: {
                        project: job.attributes.project_id,
                    },
                };
            } catch (e) {
                return '';
            }
        },
    },
};
</script>
