<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs"></app-breadcrumbs>
    <v-container fluid>
        <v-row align="center" justify="center">
            {{ $t('job_details') }}
        </v-row>
    </v-container>
</div>
</template>

<script>
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
export default {
    data() {
        return {
            isLoading: false,
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "home"
                    }
                },
                {
                    text: this.$t('dashboard'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "dashboard"
                    }
                },
                {
                    text: this.$t('my_applications'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "profile.jobs.index"
                    }
                },
                {
                    text: "",
                    disabled: true,
                    to: {
                        name: "home"
                    }
                }
            ]
        };
    },
    computed: {},
    methods: {},
    components: {
        AppBreadcrumbs
    }
};
</script>
