<template>
    <div>
        <app-breadcrumbs :breadcrumbs="breadcrumbs">
            <app-title-crumb title="Whitepaper" instructions="">
                <template v-slot:action>
                    <v-btn color="accent" dark @click="onDownloadPdf">
                        {{ $t('download') }}
                    </v-btn>
                </template>
            </app-title-crumb>
        </app-breadcrumbs>
        <v-container fluid>
            <v-row justify="center" alig="center">
                <v-col cols="8">
                    <app-pdf
                        v-for="i in 21"
                        :key="i"
                        :src="src"
                        :page="i"
                        @num-pages="21"
                    ></app-pdf>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import AppBreadcrumbs from '@/components/organisms/Breadcrumbs';
import AppTitleCrumb from '@/components/molecules/Breadcrumbs/Title';
import AppPdf from 'vue-pdf';
// https://pspdfkit.com/blog/2021/how-to-build-a-vuejs-pdf-viewer-with-pdfjs/

export default {
    data() {
        return {
            id: this.$route.params.project,
            isDateVisible: true,
            isSalaryVisible: false,
            dialog: false,
            rounded: false,
            flat: false,
            breadcrumbs: [
                {
                    text: this.$t('home'),
                    disabled: false,
                    to: {
                        name: 'home',
                    },
                    exact: true,
                },
                {
                    text: '',
                    disabled: true,
                    to: {
                        name: 'dashboard',
                    },
                },
            ],
        };
    },

    computed: {
        src() {
            return AppPdf.createLoadingTask('/pdf/LinkBridge-Whitepaper.pdf');
        },
    },
    methods: {
        onDownloadPdf() {
            window.open('/pdf/LinkBridge-Whitepaper.pdf', '_blank');
        },
    },
    components: {
        AppBreadcrumbs,
        AppTitleCrumb,
        AppPdf,
    },
};
</script>
