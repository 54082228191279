<template>
    <v-list-item-avatar tile width="100" height="auto" color="grey">
        <v-img :src="getLogo(logo)" width="auto" height="80"></v-img>
    </v-list-item-avatar>
</template>
<script>
import {
    mapGetters
} from "vuex";
export default {
    props: ["logo"],
    computed: {
        ...mapGetters({
            placeholders: "placeholders",
        }),
    },
    methods: {
        getLogo(logo) {
            return logo ? logo : this.placeholders.company;
        }
    }
};
</script>
