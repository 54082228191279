<style>
.avatar--button-margin {
    margin-top: 2em;
    margin-right: -1em;
}
</style>
<template>
    <v-btn
        absolute
        class="avatar--button-margin"
        fab
        height="30px"
        width="30px"
        right
        top
        x-small
        @click="onClicked"
    >
        <v-icon>mdi-camera</v-icon>
    </v-btn>
</template>
<script>
export default {
    methods: {
        onClicked() {
            this.$emit('input', true);
        },
    },
};
</script>
