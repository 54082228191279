<template>
<v-skeleton-loader class="mx-auto" type="card-heading, card, actions" v-if="!project"></v-skeleton-loader>
<v-card class="mx-auto" v-else>
    <v-img height="100%" :src="this.placeholders.company_bg">
        <v-row class="fill-height">
            <v-col cols="12">
                <v-avatar class="profile ma-2" size="164" tile>
                    <v-img :src="profilePictureUrl"></v-img>
                </v-avatar>
            </v-col>
            <v-col class="py-0">
                <v-list-item color="rgba(0, 0, 0, .4)" dark>
                    <v-list-item-content>
                        <v-list-item-title class="text-h6">
                            {{ project.attributes.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ project.attributes.location }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-col>
        </v-row>
    </v-img>
    <v-list-item three-line v-if="false">
        <v-list-item-content>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined @click="onDownload">
                    <v-icon left> mdi-download </v-icon>
                    {{ $t('download_info') }}
                </v-btn>
                <v-btn color="accent" @click="onContact">
                    <v-icon left>
                        mdi-email
                    </v-icon>
                    {{ $t('contact_us') }}
                </v-btn>
            </v-card-actions>
        </v-list-item-content>
    </v-list-item>
    <v-card-title>
        {{ $t('description') | uppercase }}
    </v-card-title>
    <v-card-text>
        <div v-html="project.attributes.description" class="my-5"></div>
    </v-card-text>
    <div v-if="project.attributes.problem">
        <v-card-title>
            {{ $t('identified_problem') | uppercase }}
        </v-card-title>
        <v-card-text style="white-space: pre-line;">
            {{ project.attributes.problem }}
        </v-card-text>
    </div>
    <div v-if="project.attributes.solution">
        <v-card-title>
            {{ $t('proposed_solution') | uppercase }}
        </v-card-title>
        <v-card-text style="white-space: pre-line;">
            {{ project.attributes.solution }}
        </v-card-text>
    </div>
</v-card>
</template>

<script>
import {
    mapGetters
} from "vuex";

export default {
    props: ["project"],
    data() {
        return {

        };
    },
    computed: {
        ...mapGetters({
            placeholders: "placeholders",
        }),
        logo() {
            try {
                if(this.project.attributes.logo) {
                    return this.project.attributes.logo;                    
                }
            } catch(e) {
                return this.placeholders.company;
            }

            return this.placeholders.company;
        },
        profilePictureUrl() {
            try {
                return this.project.attributes.logo;
            } catch (e) {
                return this.placeholders.company;
            }
        },
    }
};
</script>
