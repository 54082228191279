<style>
.avatar--button-margin {
    margin-top: 2em;
    margin-right: -1em;
}
</style>
<template>
<div>
    <v-card class="mx-auto" outlined tile>
        <v-img height="100%" :src="placeholders.profile_bg">
            <v-row class="fill-height">
                <v-col cols="12">
                    <v-avatar class="profile ma-2" size="164" tile>
                        <v-img :src="profilePictureUrl"></v-img>
                        <app-photo-uploader v-model="updateProfilePicture" :photo="uploaderPictureUrl" :storeAction="this.storeProfilePhoto" :destroyAction="this.destroyProfilePhoto" placeholderUrl="/" />
                    </v-avatar>
                </v-col>
                <v-col class="py-0">
                    <v-list-item color="rgba(0, 0, 0, .4)" dark>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6">
                                {{ form.name }} {{ form.middle_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="false">
                                Network Engineer
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
        </v-img>
    </v-card>
    <v-card flat :loading="isLoading">
        <v-card-title>{{ $t('official_name') }}</v-card-title>
        <v-card-subtitle>
            {{ $t('official_name_msg') }}
        </v-card-subtitle>
        <v-card-text>
            <v-text-field :label="$t('name')" v-model="form.name" :error-messages="errors['form.name']"></v-text-field>
            <v-text-field :label="$t('middle_name')" v-model="form.middle_name" required :error-messages="errors['form.middle_name']"></v-text-field>
            <v-text-field :label="`${$t('last_name')} (${$t('optional')})`" v-model="form.last_name"></v-text-field>
        </v-card-text>
        <v-card-title>{{ $t('place_and_birthdate') }}</v-card-title>
        <v-card-text>
            <v-select :items="countries" v-model="form.country" :error-messages="errors['form.country']" item-text="attributes.name" item-value="id" return-object required :label="$t('country_of_birth')"></v-select>
            <v-select :items="states" v-model="form.state" :error-messages="errors['form.state']" item-text="attributes.name" item-value="id" return-object :label="$t('state_of_birth')" v-if="isMexico"></v-select>
            <v-text-field v-model="form.state" :error-messages="errors['form.state']" :label="$t('state_of_birth')" autocomplete="off" v-else></v-text-field>
            <v-text-field :label="$t('nationality')" v-model="form.nationality" :error-messages="errors['form.nationality']"></v-text-field>
            <v-dialog ref="dialog" v-model="modal" :return-value.sync="form.birthdate" persistent width="290px">
                <template v-slot:activator="{ on }">
                    <v-text-field v-model="form.birthdate" :label="$t('birthdate')" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="form.birthdate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.dialog.save(form.birthdate)">OK</v-btn>
                </v-date-picker>
            </v-dialog>
        </v-card-text>
        <v-card-title> {{ $t('residence') }} </v-card-title>
        <v-card-text>
            <vuetify-google-autocomplete id="map" v-model="form.location" v-bind:disabled="false" placeholder="Ubicación" v-on:placechanged="getAddressData" required :error-messages="errors['form.location']" />
        </v-card-text>
        <v-card-title> {{ $t('gender') }} </v-card-title>
        <v-card-text>
            <v-radio-group v-model="form.gender" row>
                <v-radio :label="$t('man')" :value="1"></v-radio>
                <v-radio :label="$t('woman')" :value="2"></v-radio>
                <v-radio :label="$t('other')" :value="3"></v-radio>
            </v-radio-group>
        </v-card-text>
        <v-card-title> {{ $t('id_type') }} </v-card-title>
        <v-card-subtitle>
            {{ $t('documentation_msg') }}
        </v-card-subtitle>
        <v-card-text>
            <v-select :items="documentTypes" :label="$t('id_type')" item-text="label" item-value="id" v-model="form.id_type"></v-select>
            <v-row>
                <v-col cols="12" lg="6">
                    <v-file-input accept="image/*" :label="$t('front_side')" @change="getFrontIDFile($event)" v-model="front_id"></v-file-input>
                </v-col>
                <v-col cols="12" lg="6">
                    <v-file-input accept="image/*" :label="$t('back_side')" @change="getBackIDFile($event)" v-model="back_id"></v-file-input>
                </v-col>
            </v-row>
            <v-row class="mb-2">
                <v-col cols="12" lg="6">
                    <v-img height="120" :src="form.url_front_id" class="mx-auto" contain></v-img>
                </v-col>
                <v-col cols="12" lg="6">
                    <v-img height="120" :src="form.url_back_id" class="mx-auto" contain></v-img>
                </v-col>
            </v-row>

            <v-dialog ref="dialogValidity" v-model="modalValidity" :return-value.sync="form.id_type_date" persistent width="290px">
                <template v-slot:activator="{ on }">
                    <v-text-field v-model="form.id_type_date" :label="$t('document_validity')" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="form.id_type_date" type="month" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modalValidity = false">{{ $t('cancel') }}</v-btn>
                    <v-btn text color="primary" @click="
                                $refs.dialogValidity.save(form.id_type_date)
                            ">{{ $t('ok') }}</v-btn>
                </v-date-picker>
            </v-dialog>

            <v-text-field :label="$t('electoral_key')" v-model="form.ine_key" :error-messages="errors['form.ine_key']"></v-text-field>
            <v-text-field :label="$t('curp')" v-model="form.curp" :error-messages="curpErrors" @input="$v.form.curp.$touch()" @blur="$v.form.curp.$touch()"></v-text-field>
            <v-text-field :label="$t('rfc_with_homoclave')" v-model="form.rfc" :error-messages="rfcErrors" @input="$v.form.rfc.$touch()" @blur="$v.form.rfc.$touch()"></v-text-field>
            <v-text-field :label="$t('phone')" number v-model="form.phone" :error-messages="errors['form.phone']"></v-text-field>
        </v-card-text>

        <v-card-actions>
            <v-btn color="accent" class="mr-4" @click="onUpdateProfile" :loading="isProcessing">
                {{ $t('save') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex';
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import {
    validationMixin
} from 'vuelidate';
import {
    required
} from 'vuelidate/lib/validators';
import {
    vueFrontErrors,
    vueRulesMessages,
    curpCustomValidation,
    rfcCustomValidation,
    vueApiErrors,
} from '../../plugins/vuelidate';
import AppPhotoUploader from '@/components/molecules/PhotoUploader';

export default {
    mixins: [validationMixin],
    validations: {
        form: {
            name: {
                required
            },
            middle_name: {
                required
            },
            country: {
                required
            },
            state: {
                required
            },
            nationality: {
                required
            },
            birthdate: {
                required
            },
            location: {
                required
            },
            id_type: {
                required
            },
            id_type_date: {
                required
            },
            ine_key: {
                required
            },
            curp: {
                curpCustomValidation
            },
            rfc: {
                rfcCustomValidation
            },
            phone: {
                required
            },
        },
    },
    data() {
        return {
            updateProfilePicture: false,
            isLoading: false,
            isProcessing: false,
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: 'home'
                    },
                },
                {
                    text: this.$t('dashboard'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: 'dashboard'
                    },
                },
                {
                    text: '',
                    disabled: true,
                    to: {
                        name: 'home'
                    },
                },
            ],
            documentTypes: [{
                    id: 1,
                    label: this.$t('official_id'),
                },
                {
                    id: 2,
                    label: this.$t('passport'),
                },
            ],
            form: {
                name: '',
                middle_name: '',
                last_name: '',
                country: '',
                state: '',
                nationality: '',
                birthdate: '',
                location: '',
                gender: '',
                id_type: '',
                id_type_date: '',
                ine_key: '',
                curp: '',
                rfc: '',
                phone: '',
            },
            modal: false,
            modalValidity: false,
            address: null,
            back_id: null,
            front_id: null,
            date: new Date().toISOString().substr(0, 10),
            errorsCustom: [],
            errors: {
                'form.name': [],
                'form.middle_name': [],
                'form.country': [],
                'form.state': [],
                'form.nationality': [],
                'form.birthdate': [],
                'form.location': [],
                'form.ine_key': [],
                'form.phone': [],
                'form.id_type': [],
                'form.rfc': [],
                'form.curp': [],
                'form.id_type_date': [],
            },
            rules: {
                ...vueRulesMessages,
            },
            validate: false,
        };
    },
    mounted() {
        this.prepareComponent();
    },
    watch: {
        form: {
            handler() {
                this.$v.form.$touch();
                this.validate = true;
            },
            deep: true,
        },
        apiErrors: {
            handler() {
                this.validate = true;
            },
            deep: true,
        },
        profile() {
            if (this.profile.attributes) {
                this.form.name = this.profile.attributes.name;
                this.form.middle_name = this.profile.attributes.middle_name;
                this.form.last_name = this.profile.attributes.last_name;

                this.form.nationality = this.profile.attributes.nationality;
                this.form.birthdate = this.profile.attributes.birthdate;
                this.form.gender = this.profile.attributes.gender;
                this.form.id_type = this.profile.attributes.id_type;
                this.form.id_type_date = this.profile.attributes.id_type_date;
                this.form.ine_key = this.profile.attributes.id_key;
                this.form.curp = this.profile.attributes.curp;
                this.form.rfc = this.profile.attributes.rfc;
                this.form.phone = this.profile.attributes.phone;
                this.form.url_back_id = this.profile.attributes.url_back_id;
                this.form.url_front_id = this.profile.attributes.url_front_id;

                if (this.profile.attributes.location) {
                    this.form.location =
                        this.profile.attributes.location.location;
                    this.address = this.profile.attributes.location.address;
                }

                this.form.country = this.countries.find(
                    (country) =>
                    country.attributes.code ===
                    this.profile.attributes.country
                );
                if (this.profile.attributes.country === 'MX') {
                    this.form.state = this.states.find(
                        (state) => state.id == this.profile.attributes.state
                    );
                } else {
                    this.form.state = this.profile.attributes.state;
                }
            }
        },
        documents() {
            this.form.url_back_id = this.documents.attributes.url_back_id;
            this.form.url_front_id = this.documents.attributes.url_front_id;
        },
        validationForm(newValue) {
            if (newValue === false) {
                return;
            }

            this.validate = false;
            this.vueFrontErrors();
        },
        isMexico(newValue, OldValue) {
            if (newValue === true) {
                if (this.form.state && !this.form.state.attributes) {
                    this.form.state = null;
                }
            }

            if (newValue === false && OldValue === true) {
                this.form.state = null;
            }
        },
    },
    computed: {
        ...mapGetters({
            placeholders: "placeholders",
        }),
        ...mapGetters('address', {
            countries: 'countries',
            states: 'states',
        }),
        ...mapGetters('profile', {
            profile: 'profile',
            documents: 'documents',
            apiErrors: 'errors',
        }),
        validationForm() {
            return this.validate;
        },
        isMexico() {
            return (
                this.form.country && this.form.country.attributes.code === 'MX'
            );
        },
        curpErrors() {
            return this.createErrors('curp', {
                curpCustomValidation: this.$t('validation_invalid_format'),
            });
        },
        rfcErrors() {
            return this.createErrors('rfc', {
                rfcCustomValidation: this.$t('validation_invalid_format'),
            });
        },
        attrCountry() {
            return this.form.country ? this.form.country.attributes.code : null;
        },
        attrState() {
            return this.form.state && this.form.state.attributes ?
                this.form.state.id :
                this.form.state;
        },
        profilePictureUrl() {
            try {
                return this.profile.attributes.photo === null ?
                    this.placeholders.profile :
                    this.profile.attributes.photo;
            } catch (e) {
                return this.placeholders.profile;
            }
        },
        uploaderPictureUrl() {
            try {
                return this.profile.attributes.photo
            } catch (e) {
                return null;
            }
        },
    },
    methods: {
        async prepareComponent() {
            this.isLoading = true;
            await Promise.allSettled([
                this.fetchCountries(),
                this.fetchStates(),
            ]);
            await this.fetchProfile(true);
            this.isLoading = false;
        },
        ...mapActions('profile', {
            storeProfilePhoto: 'storeProfilePhoto',
            destroyProfilePhoto: 'destroyProfilePhoto',
        }),
        ...mapActions('address', {
            fetchCountries: 'fetchCountries',
            fetchStates: 'fetchStates',
        }),
        ...mapActions('profile', {
            fetchProfile: 'fetch',
            update: 'update',
            storeIDFiles: 'storeIDFiles',
        }),
        vueFrontErrors: vueFrontErrors,
        createErrors(key, rules) {
            const errors = vueApiErrors(key, this.errorsCustom);
            if (!this.$v.form[key].$dirty && errors.length == 0) return errors;
            for (let rule in rules) {
                !this.$v.form[key][rule] && errors.push(rules[rule]);
            }
            return errors;
        },
        getFrontIDFile(event) {
            this.front_id = event;
        },
        getBackIDFile(event) {
            this.back_id = event;
        },
        async onUpdateProfile() {
            this.$v.form.$touch();
            let invalid = this.$v.form.$invalid;
            this.validate = true;

            if (invalid) {
                return;
            }

            this.isProcessing = true;

            let formData = {
                address: this.address,
                ...this.form,
                state: this.attrState,
                country: this.attrCountry,
            };

            let formDataFiles = new FormData();

            if (this.front_id != null) {
                formDataFiles.append('id_type_front', this.front_id);
            }

            if (this.back_id != null) {
                formDataFiles.append('id_type_back', this.back_id);
            }

            await Promise.allSettled([
                this.update(formData),
                this.storeIDFiles({
                    dataFiles: formDataFiles
                }),
            ]);

            this.isProcessing = false;
        },
        /**
         * When the location found
         * @param {Object} addressData Data of the found location
         * @param {Object} placeResultData PlaceResult object
         * @param {String} id Input container ID
         */
        getAddressData: function (addressData) {
            this.address = addressData;
        },
    },
    components: {
        VuetifyGoogleAutocomplete,
        AppPhotoUploader,
    },
};
</script>
