<template>
    <h2 class="mb-4">
        {{ title }}
        <span v-bind:class="actionClass">
            <slot name="action">
                <app-anchor
                    v-if="link"
                    class="mt-1"
                    :title="link"
                    :route="route"
                ></app-anchor>
            </slot>
        </span>
    </h2>
</template>

<script>
import AppAnchor from "@/components/atoms/Anchor";
export default {
    props: ["title", "link", "route", "classes"],
    computed: {
        actionClass() {
            let classes =
                this.classes && this.classes.anchor ? this.classes.anchor : [];
            return {
                "float-right": true,
                ...classes
            };
        }
    },
    components: {
        AppAnchor
    }
};
</script>
