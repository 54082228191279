import axios from '../../plugins/axios';

const state = {
    rounds: [],
    roundReports: [],
    round: null,
    isFetching: false,
    isSaving: false,
    errors: [],
};

const actions = {
    async fetch({ commit }) {
        try {
            const response = await axios.get(`/api/admin/rounds`);
            commit('setRounds', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async store({ commit, dispatch }, { project_id, data }) {
        commit('setSaving', true);
        try {
            await axios.post(`/api/admin/projects/${project_id}/rounds`, data);
            commit('setSaving', false);
            dispatch('adminFetchByProjectId', project_id);
        } catch (error) {
            if (error.response.status === 422) {
                commit('setErrors', error.response.data.errors);
            } else {
                console.log(error);
            }
            commit('setSaving', false);
            return false;
        }

        return true;
    },
    async update({ commit }, roundData) {
        commit('setSaving', true);
        try {
            await axios.post(
                `/api/admin/rounds/${roundData.id}/update`,
                roundData
            );
            commit('setSaving', false);
        } catch (error) {
            commit('setSaving', false);
        }
    },
    async destroy({ commit }, roundId) {
        commit('setFetching', true);
        try {
            await axios.post(`/api/admin/rounds/${roundId}/destroy`);
            commit('setRound', null);
            commit('setFetching', false);
        } catch (error) {
            commit('setFetching', false);
        }
    },
    
    // Round reports
    async reportsFetch({ commit }, round_id) {
        try {
            const response = await axios.get(`/api/admin/rounds/${round_id}/reports`);
            commit('setRoundReports', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async reportsStore({ commit, dispatch }, { round_id, data }) {
        commit('setSaving', true);
        try {
            await axios.post(`/api/admin/rounds/${round_id}/reports`, data);
            commit('setSaving', false);
            dispatch('reportsFetch', round_id);
        } catch (error) {
            if (error.response.status === 422) {
                commit('setErrors', error.response.data.errors);
            } else {
                console.log(error);
            }
            commit('setSaving', false);
            return false;
        }

        return true;
    },

    async reportsDestroy({ commit, dispatch }, { round_id, report_id }) {
        commit('setFetching', true);
        try {
            await axios.post(`/api/admin/rounds/${round_id}/reports/${report_id}/destroy`);
            commit('setFetching', false);
            dispatch('reportsFetch', round_id);
        } catch (error) {
            commit('setFetching', false);
        }
    },
    async reportsDownload({ commit }, round_id, report_id) {
        commit('setFetching', true);
        try {
            await axios.get(`/api/admin/rounds/${round_id}/reports/${report_id}`);
            commit('setRound', null);
            commit('setFetching', false);
        } catch (error) {
            commit('setFetching', false);
        }
    },
    async fetchByProjectId({ commit }, projectId) {
        try {
            const response = await axios.get(
                `/api/projects/${projectId}/rounds`
            );
            commit('setRounds', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async lbFetch({ commit }) {
        try {
            const response = await axios.get(`/api/linkbridge/rounds`);
            commit('setRounds', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async lbFetchById({ commit }, roundId) {
        commit('setRound', null);
        try {
            const response = await axios.get(
                `/api/linkbridge/rounds/${roundId}`
            );
            commit('setRound', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async lbUpdate({ commit }, { roundId, statusId }) {
        try {
            const response = await axios.post(
                `/api/linkbridge/rounds/${roundId}`,
                {
                    status_id: statusId,
                }
            );
            commit('setRound', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async lbRaise({ commit }, { roundId }) {
        try {
            const response = await axios.post(
                `/api/linkbridge/rounds/${roundId}/raise`
            );
            commit('setRound', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async lbFetchByProjectId({ commit }, projectId) {
        try {
            const response = await axios.get(
                `/api/linkbridge/projects/${projectId}/rounds`
            );
            commit('setRounds', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async adminFetchByProjectId({ commit }, projectId) {
        try {
            const response = await axios.get(
                `/api/admin/projects/${projectId}/rounds`
            );
            commit('setRounds', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async fetchById({ commit }, roundId) {
        commit('setFetching', true);
        commit('setRound', null);
        try {
            const response = await axios.get(`/api/rounds/${roundId}`);
            commit('setRound', response.data);
            commit('setFetching', false);
        } catch (error) {
            console.log(error);
        }
    },
};

const mutations = {
    setRounds(state, rounds) {
        state.rounds = rounds;
    },
    setRoundReports(state, reports) {
        state.roundReports = reports;
    },
    setRound(state, round) {
        state.round = round;
    },
    setFetching(state, isFetching) {
        state.isFetching = isFetching;
    },
    setSaving(state, isSaving) {
        state.isSaving = isSaving;
    },
    setErrors(state, errors) {
        state.errors = errors;
    },
};

const getters = {
    errors: (state) => {
        return state.errors;
    },
    rounds: (state) => {
        return state.rounds;
    },
    round: (state) => {
        return state.round;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
