var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"float-right"},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onClose()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('publication_details')))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"text":"","disabled":_vm.isProcessing},on:{"click":function($event){return _vm.onClose()}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"color":"accent","loading":_vm.isProcessing,"disabled":!_vm.form.project_id},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('publish'))+" ")])],1)],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"7"}},[(_vm.job)?_c('v-alert',{attrs:{"border":"bottom","colored-border":"","type":"warning","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.$t('vacancy_edition_warning'))+" ")]):_vm._e(),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('lets_start'))+" ")]),_c('v-card-subtitle'),_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.projects,"item-text":"attributes.name","disabled":this.job !== null,"item-value":"id","error-messages":_vm.errors['form.project_id'],"label":((_vm.$t(
                                                'vacancy_company'
                                            )) + " *")},on:{"input":function($event){return _vm.$v.form.project_id.$touch()},"blur":function($event){return _vm.$v.form.project_id.$touch()}},model:{value:(_vm.form.project_id),callback:function ($$v) {_vm.$set(_vm.form, "project_id", $$v)},expression:"form.project_id"}}),_c('v-autocomplete',{attrs:{"items":_vm.industries,"item-text":"attributes.name","item-value":"id","error-messages":_vm.errors['form.industry_id'],"label":((_vm.$t('industry')) + " *")},on:{"input":function($event){return _vm.$v.form.industry_id.$touch()},"blur":function($event){return _vm.$v.form.industry_id.$touch()}},model:{value:(_vm.form.industry_id),callback:function ($$v) {_vm.$set(_vm.form, "industry_id", $$v)},expression:"form.industry_id"}}),_c('v-text-field',{attrs:{"error-messages":_vm.errors['form.title'],"label":((_vm.$t(
                                                'vacancy_title'
                                            )) + " *")},on:{"input":function($event){return _vm.$v.form.title.$touch()},"blur":function($event){return _vm.$v.form.title.$touch()}},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('v-text-field',{attrs:{"error-messages":_vm.errors['form.location'],"label":((_vm.$t(
                                                'vacancy_location'
                                            )) + " *")},on:{"input":function($event){return _vm.$v.form.location.$touch()},"blur":function($event){return _vm.$v.form.location.$touch()}},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}})],1),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('job_details'))+" ")]),_c('v-card-subtitle'),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.employment_types,"item-text":"attributes.description","item-value":"id","error-messages":_vm.errors['form.type_id'],"label":((_vm.$t(
                                                'vacancy_employment_type_question'
                                            )) + " *")},on:{"input":function($event){return _vm.$v.form.type_id.$touch()},"blur":function($event){return _vm.$v.form.type_id.$touch()}},model:{value:(_vm.form.type_id),callback:function ($$v) {_vm.$set(_vm.form, "type_id", $$v)},expression:"form.type_id"}}),_c('v-select',{attrs:{"items":_vm.contract_types,"item-text":"attributes.name","item-value":"id","error-messages":_vm.errors['form.contract_id'],"label":((_vm.$t(
                                                'vacancy_contract_type_question'
                                            )) + " *")},on:{"input":function($event){return _vm.$v.form.contract_id.$touch()},"blur":function($event){return _vm.$v.form.contract_id.$touch()}},model:{value:(_vm.form.contract_id),callback:function ($$v) {_vm.$set(_vm.form, "contract_id", $$v)},expression:"form.contract_id"}}),(_vm.isBySpecificTime)?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"value":"1","type":"number","label":("" + (_vm.$t('time')))},on:{"input":function($event){return _vm.$v.form.contract_time.$touch()},"blur":function($event){return _vm.$v.form.contract_time.$touch()}},model:{value:(
                                                        _vm.form.contract_time
                                                    ),callback:function ($$v) {_vm.$set(_vm.form, "contract_time", $$v)},expression:"\n                                                        form.contract_time\n                                                    "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"value":"0","items":_vm.contracts_periods,"label":("" + (_vm.$t('type')))},on:{"input":function($event){return _vm.$v.form.contract_period.$touch()},"blur":function($event){return _vm.$v.form.contract_period.$touch()}},model:{value:(
                                                        _vm.form.contract_period
                                                    ),callback:function ($$v) {_vm.$set(_vm.form, "contract_period", $$v)},expression:"\n                                                        form.contract_period\n                                                    "}})],1)],1):_vm._e(),_c('v-select',{attrs:{"items":_vm.schedule_types,"item-text":"attributes.description","item-value":"id","error-messages":_vm.errors[
                                                    'form.schedule_types_id'
                                                ],"label":((_vm.$t(
                                                'vacancy_schedule_type_question'
                                            )) + " *"),"multiple":"","chips":""},on:{"input":function($event){return _vm.$v.form.schedule_types_id.$touch()},"blur":function($event){return _vm.$v.form.schedule_types_id.$touch()}},model:{value:(_vm.form.schedule_types_id),callback:function ($$v) {_vm.$set(_vm.form, "schedule_types_id", $$v)},expression:"form.schedule_types_id"}})],1),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('salary_benefits'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t('salary_benefits_subtitle'))+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('salary_staring'),"prefix":_vm.$t(
                                                            'currency_prefix'
                                                        ),"suffix":_vm.$t(
                                                            'currency_suffix'
                                                        )},on:{"input":function($event){return _vm.$v.form.salary_starting.$touch()},"blur":function($event){return _vm.$v.form.salary_starting.$touch()}},model:{value:(
                                                        _vm.form.salary_starting
                                                    ),callback:function ($$v) {_vm.$set(_vm.form, "salary_starting", $$v)},expression:"\n                                                        form.salary_starting\n                                                    "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('salary_ending'),"prefix":_vm.$t(
                                                            'currency_prefix'
                                                        ),"suffix":_vm.$t(
                                                            'currency_suffix'
                                                        )},on:{"input":function($event){return _vm.$v.form.salary_final.$touch()},"blur":function($event){return _vm.$v.form.salary_final.$touch()}},model:{value:(
                                                        _vm.form.salary_final
                                                    ),callback:function ($$v) {_vm.$set(_vm.form, "salary_final", $$v)},expression:"\n                                                        form.salary_final\n                                                    "}})],1)],1),_c('v-text-field',{attrs:{"error-messages":_vm.errors['form.percent'],"type":"number","label":_vm.$t(
                                                    'vacancy_stock_options_question'
                                                ),"suffix":"%"},on:{"input":function($event){return _vm.$v.form.percent.$touch()},"blur":function($event){return _vm.$v.form.percent.$touch()}},model:{value:(_vm.form.percent),callback:function ($$v) {_vm.$set(_vm.form, "percent", $$v)},expression:"form.percent"}}),_c('v-select',{attrs:{"items":_vm.benefits,"item-text":"attributes.description","item-value":"id","label":((_vm.$t(
                                                'vacancy_benefit_type_question'
                                            )) + " *"),"error-messages":_vm.errors[
                                                    'form.benefit_types_id'
                                                ],"multiple":"","chips":""},on:{"input":function($event){return _vm.$v.form.benefit_types_id.$touch()},"blur":function($event){return _vm.$v.form.benefit_types_id.$touch()}},model:{value:(_vm.form.benefit_types_id),callback:function ($$v) {_vm.$set(_vm.form, "benefit_types_id", $$v)},expression:"form.benefit_types_id"}})],1),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('job_description'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t('job_description_subtitle'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('job_description_subtitle2'))+" ")]),_c('v-card-text',[_c('v-textarea',{attrs:{"solo":"","name":"input-7-4","error-messages":_vm.errors['form.description'],"auto-grow":true,"label":((_vm.$t(
                                                'job_description'
                                            )) + " *")},on:{"input":function($event){return _vm.$v.form.description.$touch()},"blur":function($event){return _vm.$v.form.description.$touch()}},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('v-card-text',[_c('small',[_vm._v(_vm._s(_vm.$t('required_tooltip')))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.isProcessing},on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"color":"accent","loading":_vm.isProcessing},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('publish'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }