import axios from "axios";
import * as storage from "../store/storage";

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL || "https://api.linkbridge.app",  // Set the base URL here
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(async (request) => {
    const token = storage.local.getItem("token");
    
    if (token && !request.headers.common["Authorization"]) {
        request.headers.common["Authorization"] = `Bearer ${token}`;
    }

    return request;
});

export default instance;
