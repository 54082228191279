<template>
<div>
  <v-row align="center" justify="center">
    <v-col cols="9">
      <v-card>
        <v-card-title>
          {{ $t('report') }}
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field v-model="form.name" :label="$t('title')" type="text"></v-text-field>
            <v-text-field v-model="form.date" :label="$t('date')" type="date"></v-text-field>
            <tiptap-vuetify v-model="form.description" :extensions="extensions" class="my-5" />
            <v-file-input v-model="form.file" :label="$t('attach_file')"></v-file-input>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" @click="onStoreReport" class="ma-5" :loading="isProcessing" :disabled="!isValid">
            {{ $t('add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="9">
      <app-reports-list :reports="roundReports" :btnDelete="true" />
    </v-col>
  </v-row>
</div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex';

import AppReportsList from '@/components/admin/rounds/ReportsList';

import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'

export default {
  props: ["round"],
  components: {
    AppReportsList,
    TiptapVuetify
  },
  data() {
    return {
      isProcessing: false,
      form: {
        name: "",
        date: "",
        description: "",
        file: null
      },
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
    };
  },
  computed: {
    ...mapState("rounds", ["roundReports"]),
    isValid() {
      return this.form.name && this.form.date && this.form.file;
    }
  },
  mounted() {
    this.reportsFetch(this.round.id);
  },
  watch: {
    round() {
      this.reportsFetch(this.round.id);
    },
  },
  methods: {
    ...mapActions("rounds", [
      "reportsFetch",
      "reportsStore"
    ]),
    async onStoreReport() {
      this.isProcessing = true;

      const formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("description", this.form.description);
      formData.append("date", this.form.date);
      formData.append("file", this.form.file);
      await this.reportsStore({
        round_id: this.round.id,
        data: formData
      })

      this.form = {
        name: "",
        date: "",
        description: "",
        file: null
      };

      this.isProcessing = false;
    }
  }
};
</script>
