<template>
<div>
    <v-row>
        <v-col cols="12" sm="6" md="6" class="pa-0 d-none d-md-block">
            <v-img style="width: 100%" :src="placeholders.login_bg"></v-img>
        </v-col>
        <v-col cols="12" sm="12" md="6" class="mt-10">
            <div class="pa-10">
                <v-row>
                    <v-col>
                        <v-img :src="placeholders.logo_login" width="60"></v-img>
                        <h1 class="title">
                            {{ $t('signup') }}
                        </h1>
                    </v-col>
                </v-row>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <p>{{ commonError }}</p>
                    <v-text-field :label="$t('email')" v-model.trim="form.email" required :error-messages="emailErrors" @input="$v.form.email.$touch()" @blur="$v.form.email.$touch()" autocomplete="off" value="" placeholder="username@email.com"></v-text-field>
                    <v-text-field :label="$t('password')" v-model="form.password" :error-messages="passwordErrors" type="password" required value="" :hint="$t('password_hint')" autocomplete="off" @input="$v.form.password.$touch()" @blur="$v.form.password.$touch()"></v-text-field>
                    <v-text-field :label="$t('name')" v-model="form.name" required :error-messages="nameErrors" @input="$v.form.name.$touch()" @blur="$v.form.name.$touch()"></v-text-field>
                    <v-text-field :label="$t('middle_name')" v-model="form.middle_name" required :error-messages="middle_nameErrors" @input="$v.form.middle_name.$touch()" @blur="$v.form.middle_name.$touch()"></v-text-field>
                    <v-text-field :label="`${$t('last_name')} ${$t('optional_placeholder')}`" v-model="form.last_name"></v-text-field>
                    <v-text-field :label="$t('phone')" v-model="form.phone" required :error-messages="phoneErrors" @input="$v.form.phone.$touch()" @blur="$v.form.phone.$touch()"></v-text-field>
                    <!-- <v-text-field v-model="form.ethereum_address" counter="42" :label="`Ethereum address ${$t('optional_placeholder')}`" :error-messages="ethErrors"></v-text-field> -->
                    <v-checkbox v-model="form.terms">
                        <template v-slot:label>
                            <div>
                                {{ $t('i_accept_the') }}
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a target="_blank" href="/terms-and-conditions" @click.stop v-on="on">
                                            {{ $t('terms_and_conditions') }}
                                        </a>
                                    </template>
                                    {{ $t('open_in_a_new_tab') }}
                                </v-tooltip>
                                {{ $t('of_use') }}
                            </div>
                        </template>
                    </v-checkbox>
                    <v-btn color="accent" @click="onSubmit" :loading="isProcessing" :disabled="false === form.terms">
                        {{ $t('signup') }}
                    </v-btn>
                    <v-btn class="ml-5" text :to="{ name: 'login.show' }" :disabled="isProcessing">
                        {{ $t('login') }}
                    </v-btn>
                </v-form>
            </div>
        </v-col>
    </v-row>
</div>
</template>

<script>
import {
    mapState,
    mapGetters
} from "vuex";
import {
    validationMixin
} from "vuelidate";
import {
    required,
    email,
    minLength,
    maxLength
} from "vuelidate/lib/validators";
import {
    apiErrors
} from "../../util";

export default {
    mixins: [validationMixin],
    validations: {
        form: {
            email: {
                required: required,
                email: email,
                async isUnique(value) {
                    if (value === "") return true;

                    var mailformat = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\w{2,3})+$/;
                    if (!value.match(mailformat)) {
                        return true;
                    }

                    let response = await fetch(`/api/unique?email=${value}`);
                    let responseJson = await response.json();
                    return Boolean(responseJson.unique);
                }
            },
            password: {
                required: required,
                minLength: minLength(8)
            },
            name: {
                required
            },
            middle_name: {
                required
            },
            // last_name: { required },
            phone: {
                required
            },
            ethereum_address: {
                minLength: minLength(42),
                maxLength: maxLength(42)
            }
        }
    },
    data: () => ({
        valid: true,
        form: {
            email: "",
            password: "",
            name: "",
            middle_name: "",
            last_name: "",
            phone: "",
            terms: false,
            ethereum_address: ""
        },
        isProcessing: false
    }),
    computed: {
        ...mapGetters({
            placeholders: "placeholders",
        }),
        ...mapState("auth", {
            errors: "errors"
        }),
        ...mapGetters("subs", {
            selectedPlan: "selectedPlan"
        }),
        commonError() {
            const errors = apiErrors("common", this.errors);
            return errors.length === 0 ? "" : errors[0];
        },
        ethErrors() {
            const errors = apiErrors("ethereum_address", this.errors);

            if (!this.$v.form.ethereum_address.$dirty && errors.length == 0) return errors;

            let rules = {
                minLength: this.$t('validation_length', {
                    length: 42
                }),
                maxLength: this.$t('validation_length', {
                    length: 42
                })
            };

            for (let rule in rules) {
                !this.$v.form.ethereum_address[rule] && errors.push(rules[rule]);
            }

            return errors;
        },
        emailErrors() {
            const errors = apiErrors("email", this.errors);

            if (!this.$v.form.email.$dirty && errors.length == 0) return errors;

            let rules = {
                required: this.$t('validation_required'),
                email: this.$t('validation_email'),
                isUnique: this.$t('validation_unique')
            };

            for (let rule in rules) {
                !this.$v.form.email[rule] && errors.push(rules[rule]);
            }

            return errors;
        },
        passwordErrors() {
            const errors = apiErrors("password", this.errors);

            if (!this.$v.form.password.$dirty && errors.length == 0)
                return errors;

            let rules = {
                required: this.$t('validation_required'),
                minLength: this.$t('validation_length', {
                    length: 8
                })
            };

            for (let rule in rules) {
                !this.$v.form.password[rule] && errors.push(rules[rule]);
            }

            return errors;
        },
        nameErrors() {
            const errors = apiErrors("name", this.errors);

            if (!this.$v.form.name.$dirty && errors.length == 0) return errors;

            let rules = {
                required: this.$t('validation_required')
            };

            for (let rule in rules) {
                !this.$v.form.name[rule] && errors.push(rules[rule]);
            }

            return errors;
        },
        middle_nameErrors() {
            const errors = apiErrors("middle_name", this.errors);

            if (!this.$v.form.middle_name.$dirty && errors.length == 0)
                return errors;

            let rules = {
                required: this.$t('validation_required')
            };

            for (let rule in rules) {
                !this.$v.form.middle_name[rule] && errors.push(rules[rule]);
            }

            return errors;
        },
        phoneErrors() {
            const errors = apiErrors("phone", this.errors);

            if (!this.$v.form.phone.$dirty && errors.length == 0) return errors;

            let rules = {
                required: this.$t('validation_required')
            };

            for (let rule in rules) {
                !this.$v.form.phone[rule] && errors.push(rules[rule]);
            }

            return errors;
        }
    },
    methods: {
        createErrors(key, rules) {
            const errors = apiErrors(key, this.errors);
            if (!this.$v[key].$dirty && errors.length == 0) return errors;
            for (let rule in rules) {
                !this.$v[key][rule] && errors.push(rules[rule]);
            }
            return errors;
        },
        onSubmit() {
            let formData = {
                ...this.form
            };
            formData.terms = +formData.terms;

            this.isProcessing = true;
            this.$store.dispatch("auth/signup", formData).then(() => {
                if (this.errors.length === 0) {
                    // Disable onboarding for now
                    // this.$router.push({
                    //     name: "onboarding"
                    // });
                    this.$router.push({
                        name: 'dashboard',
                    });
                }
                
                this.isProcessing = false;
            });
        }
    }
};
</script>
