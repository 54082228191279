<template>
<v-app id="inspire">
    <app-navbar></app-navbar>
    <v-main style="background-color: #F7F7F7;">
        <router-view></router-view>
    </v-main>
    <app-footer></app-footer>
</v-app>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import AppNavbar from "./components/organisms/Navbar";
import AppFooter from "./components/organisms/Footer";
export default {
    data: () => ({
        drawer: null
    }),
    /**
     * Prepare the component (Vue 2.x).
     */
    mounted() {},
    computed: {
        ...mapGetters([
            "language"
        ])
    },
    methods: {
        ...mapActions("auth", {
            tryAutoLogin: "tryAutoLogin"
        }),
        setLanguage() {
            this.$i18n.locale = this.language;
        }
    },
    components: {
        AppNavbar,
        AppFooter
    },
    created() {
        this.setLanguage();
        this.tryAutoLogin();
    }
};
</script>
