<template>
<v-card flat :loading="isLoading">
    <v-card-title>
        {{ $t('address') }}
    </v-card-title>
    <v-card-subtitle> </v-card-subtitle>
    <v-card-text>
        <v-form>
            <v-row align="center">
                <v-col class="d-flex py-0" cols="12" sm="12">
                    <v-text-field v-model="form.line_1" :error-messages="errors['form.line_1']" :label="$t('street')" :placeholder="$t('street_placeholder')"></v-text-field>
                </v-col>
                <v-col class="d-flex py-0" cols="12" sm="6">
                    <v-text-field v-model="form.line_2" :error-messages="errors['form.line_2']" :label="$t('street_number')" :placeholder="$t('street_number_placeholder')"></v-text-field>
                </v-col>
                <v-col class="d-flex py-0" cols="12" sm="6">
                    <v-text-field v-model="form.line_3" :error-messages="errors['form.line_3']" :label="$t('internal_number')" :placeholder="$t('optional_placeholder')"></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12">
                    <v-text-field v-model="form.line_4" :error-messages="errors['form.line_4']" name="suburb" :label="$t('suburb')"></v-text-field>
                </v-col>
                <v-col class="d-flex py-0" cols="12" sm="6">
                    <v-text-field v-model="form.cp" :error-messages="errors['form.cp']" :label="$t('postal_code')" :placeholder="$t('postal_code_placeholder')"></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12">
                    <v-text-field v-model="form.city" :error-messages="errors['form.city']" :label="$t('city')"></v-text-field>
                </v-col>
                <v-col class="d-flex pt-0" cols="12" sm="12">
                    <v-select :items="countries" v-model="form.country" :error-messages="errors['form.country']" item-text="attributes.name" item-value="id" return-object :label="$t('country')"></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12" v-if="isMexico">
                    <v-select :items="states" v-model="form.state" :error-messages="errors['form.state']" item-text="attributes.name" item-value="id" return-object :label="$t('state')"></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12" v-else>
                    <v-text-field v-model="form.state" :error-messages="errors['form.state']" :label="$t('state')" autocomplete="off"></v-text-field>
                </v-col>
            </v-row>
        </v-form>
    </v-card-text>
    <v-card-actions>
        <v-btn color="accent" class="mr-4" @click="onSubmit" :loading="isProcessing">
            {{ $t('save') }}
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import {
    validationMixin
} from "vuelidate";
import {
    required,
    minLength,
    maxLength,
    numeric
} from "vuelidate/lib/validators";
import {
    vueFrontErrors,
    vueRulesMessages
} from "../../plugins/vuelidate";

export default {
    mixins: [validationMixin],
    validations: {
        form: {
            line_1: {
                required
            },
            line_2: {
                numeric,
                required
            },
            line_3: {
                numeric
            },
            line_4: {
                required
            },
            city: {
                required
            },
            state: {
                required
            },
            country: {
                required
            },
            cp: {
                required: required,
                numeric: numeric,
                minLength: minLength(5),
                maxLength: maxLength(5)
            }
        }
    },
    data() {
        return {
            isLoading: false,
            isProcessing: false,
            form: {
                line_1: null,
                line_2: null,
                line_3: null,
                line_4: null,
                city: null,
                state: null,
                country: null,
                cp: null
            },
            errors: {
                "form.line_1": [],
                "form.line_2": [],
                "form.line_3": [],
                "form.line_4": [],
                "form.city": [],
                "form.state": [],
                "form.country": [],
                "form.cp": []
            },
            rules: {
                ...vueRulesMessages
            },
            validate: false
        };
    },
    mounted() {
        this.prepareComponent();
    },
    watch: {
        form: {
            handler() {
                this.validate = true;
            },
            deep: true
        },
        apiErrors: {
            handler() {
                this.validate = true;
            },
            deep: true
        },
        address() {
            if (this.address.attributes) {
                this.form.line_1 = this.address.attributes.line_1;
                this.form.line_2 = this.address.attributes.line_2;
                this.form.line_3 = this.address.attributes.line_3;
                this.form.line_4 = this.address.attributes.line_4;
                this.form.cp = this.address.attributes.cp;
                this.form.city = this.address.attributes.city;
                this.form.country = this.countries.find(
                    country =>
                    country.attributes.code ===
                    this.address.attributes.country
                );

                if (this.address.attributes.country === "MX") {
                    this.form.state = this.states.find(
                        state => state.id == this.address.attributes.state
                    );
                } else {
                    this.form.state = this.address.attributes.state;
                }
            } else {
                this.form.country = this.countries.find(
                    country => country.attributes.code === "MX"
                );
            }
        },
        validationForm(newValue) {
            if (newValue === false) {
                return;
            }
            this.validate = false;
            this.vueFrontErrors();
        },
        isMexico(newValue, OldValue) {
            if (newValue === true) {
                if (this.form.state && !this.form.state.attributes) {
                    this.form.state = null;
                }
            }

            if (newValue === false && OldValue === true) {
                this.form.state = null;
            }
        }
    },
    computed: {
        ...mapGetters("address", {
            countries: "countries",
            states: "states",
            address: "address",
            apiErrors: "errors"
        }),
        validationForm() {
            return this.validate;
        },
        isMexico() {
            return (
                this.form.country && this.form.country.attributes.code === "MX"
            );
        },
        attrCountry() {
            return this.form.country ? this.form.country.attributes.code : null;
        },
        attrState() {
            return this.form.state && this.form.state.attributes ?
                this.form.state.id :
                this.form.state;
        }
    },
    methods: {
        async prepareComponent() {
            this.isLoading = true;
            await Promise.allSettled([
                this.fetchCountries(),
                this.fetchStates()
            ]);
            await this.fetchAddress();
            this.isLoading = false;
        },
        ...mapActions("address", {
            fetchCountries: "fetchCountries",
            fetchStates: "fetchStates",
            fetchAddress: "fetchAddress",
            storeAddress: "storeAddress"
        }),
        vueFrontErrors: vueFrontErrors,
        async onSubmit() {
            this.$v.form.$touch();
            let invalid = this.$v.form.$invalid;
            this.validate = true;
            if (invalid) {
                return;
            }
            this.isProcessing = true;
            const formProfile = {
                data: {
                    type: "personal_address",
                    attributes: {
                        line_1: this.form.line_1,
                        line_2: this.form.line_2,
                        line_3: this.form.line_3,
                        line_4: this.form.line_4,
                        city: this.form.city,
                        state: this.attrState,
                        country: this.attrCountry,
                        cp: this.form.cp
                    }
                }
            };
            await Promise.allSettled([this.storeAddress(formProfile)]);
            this.isProcessing = false;
        }
    }
};
</script>
