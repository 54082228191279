// import Vue from 'vue';
// import Vuetify from 'vuetify'
// import es from 'vuetify/es5/locale/es'

// Vue.use(Vuetify);

// export default new Vuetify(
//     {
//         lang: {
//             locales: { es },
//             current: 'es'
//         },
//         theme: {
//             themes: {
//                 light: {
//                     primary: "#f44336",
//                     secondary: "#F43838",
//                     accent: "#00bcd4",
//                     error: "#f44336",
//                     warning: "#ff5722",
//                     info: "#03a9f4",
//                     success: "#4caf50",
//                     footer: "#2A2A2A"
//                 },
//             },
//         },
//     });

// import Vue from 'vue';
// import Vuetify from 'vuetify/lib';
// import es from 'vuetify/es5/locale/es';

import Vue from 'vue';
import Vuetify from 'vuetify';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'

import es from 'vuetify/es5/locale/es';

import 'tiptap-vuetify/dist/main.css';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);
// use this package's plugin

const vuetify = new Vuetify({
    //   base: string
    //   lighten5: string
    //   lighten4: string
    //   lighten3: string
    //   lighten2: string
    //   lighten1: string
    //   darken1: string
    //   darken2: string
    //   darken3: string
    //   darken4: string
    theme: {
        themes: {
            dark: {
                primary: '#21cff3',
                accent: '#FF4081',
                secondary: '#ffe18d',
                success: '#4CAF50',
                info: '#2196F3',
                warning: '#FB8C00',
                error: '#FF5252',
            },
            light: {
                primary: '#6D3AFF', // primary: '#1976D2',
                accent: '#F43838', // accent: '#e91e63',
                secondary: '#30b1dc',
                success: '#4CAF50',
                info: '#2196F3',
                warning: '#FB8C00',
                error: '#FF5252',
                toolbar: '#f5f5f5',
            },
        },
    },
    lang: {
        locales: { es },
        current: 'es',
    },
});

Vue.use(TiptapVuetifyPlugin, {
    // the next line is important! You need to provide the Vuetify Object to this place.
    vuetify, // same as "vuetify: vuetify"
    // optional, default to 'md' (default vuetify icons before v2.0.0)
    iconsGroup: 'md',
});

export default vuetify;
