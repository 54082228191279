<template>
<v-row>
  <v-col cols="12" sm="6" md="6" class="pa-0 d-none d-md-block">
    <v-img style="width: 100%" :src="placeholders.login_bg"></v-img>
  </v-col>
  <v-col cols="12" sm="12" md="6" class="mt-10">
    <div class="pa-10">
      <v-row>
        <v-col>
          <v-img :src="placeholders.logo_login" width="60"></v-img>
          <h1 class="title">
            {{ $t('recover_password') }}
          </h1>
        </v-col>
      </v-row>
      <p v-if="succeeded">
        {{ $t('recover_email_sent') }}
      </p>
      <p v-else-if="errors.error">{{errors.error}}</p>
      <p v-else-if="message">{{message}}</p>
      <v-form ref="form" v-model="valid" v-else>
        <v-text-field v-model="email" required @input="$v.email.$touch()" @blur="$v.email.$touch()" :error-messages="emailErrors" :label="$t('email')"></v-text-field>
        <div class="text-center">
          <v-btn block color="accent" :loading="isLoading" @click="onSubmit" :disabled="isDisabled">
            {{ $t('send_recover_email') }}
          </v-btn>
        </div>
      </v-form>
    </div>
  </v-col>
</v-row>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters
} from 'vuex';
import {
  validationMixin
} from 'vuelidate';
import {
  required,
  email
} from 'vuelidate/lib/validators';
import {
  apiErrors
} from '../../../util.js';

export default {
  mixins: [validationMixin],
  validations: {
    email: {
      required: required,
      email: email,
    },
    password: {
      required: required,
    },
  },
  data: () => ({
    isLoading: false,
    valid: true,
    email: '',
    succeeded: false,
    message: '',
  }),
  mounted() {
    this.getResetEmail();
  },
  methods: {
    ...mapActions('auth', {
      getResetEmail: 'getResetEmail',
      sendResetLinkEmail: 'sendResetLinkEmail',
    }),
    createErrors(key, rules) {
      const errors = apiErrors(key, this.errors);
      if (!this.$v[key].$dirty && errors.length == 0) return errors;
      for (let rule in rules) {
        !this.$v[key][rule] && errors.push(rules[rule]);
      }
      return errors;
    },
    async onSubmit() {
      this.isLoading = true;

      this.succeeded = await this.sendResetLinkEmail({
        email: this.email,
      });

      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters({
      placeholders: 'placeholders',
    }),
    ...mapState('auth', {
      isAuth: 'isAuth',
      errors: 'errors',
    }),
    emailErrors() {
      return this.createErrors('email', {
        required: this.$t('validation_required'),
        email: this.$t('validation_email'),
      });
    },
    isDisabled() {
      return !this.valid || this.email == '';
    },
  },
};
</script>
