<template>
<v-card flat :loading="isLoading">
    <v-card-title>
        {{ $t('user_data') }}
    </v-card-title>
    <v-card-subtitle>
        {{ $t('user_data_msg') }}
    </v-card-subtitle>
    <v-card-text>
        <v-form>
            <v-text-field v-model="form.name" :error-messages="errors['form.name']" label="Nombre de usuario" disabled></v-text-field>
            <v-text-field v-model="form.email" :error-messages="errors['form.email']" label="Correo"></v-text-field>
            <v-text-field type="password" v-model="form.current_password" :error-messages="errors['form.current_password']" :label="$t('current_password')" autocomplete="off" value="" :hint="$t('current_password_hint')"></v-text-field>
            <v-text-field type="password" v-model="form.password" :error-messages="errors['form.password']" :label="`${$t('new_password')} ${$t('optional')}`"></v-text-field>
            <v-text-field type="password" v-model="form.password_confirmation" :error-messages="errors['form.password_confirmation']" :label="$t('confirm_new_password')" v-if="hasNewPassword"></v-text-field>
            <v-switch v-model="form.is_notified" :error-messages="errors['form.is_notified']" inset label="Recibir notificaciones"></v-switch>
        </v-form>
    </v-card-text>
    <v-card-actions>
        <v-btn color="accent" class="mr-4" @click="onSubmit" :loading="isProcessing" :disabled="isLoading">
            {{ $t('save') }}
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex';
import {
    validationMixin
} from 'vuelidate';
import {
    required,
    minLength,
    maxLength,
    sameAs,
    email,
} from 'vuelidate/lib/validators';
import {
    vueFrontErrors,
    vueRulesMessages
} from '../../plugins/vuelidate';
export default {
    mixins: [validationMixin],
    validations: {
        form: {
            name: {},
            email: {
                required,
                email,
            },
            current_password: {
                minLength: minLength(8),
                maxLength: maxLength(255),
                required: required,
            },
            password_confirmation: {
                sameAsPassword: sameAs('password'),
            },
            password: {
                minLength: minLength(8),
                maxLength: maxLength(255),
            },
            is_notified: {
                required,
            },
        },
    },
    data() {
        return {
            isLoading: false,
            isProcessing: false,
            form: {
                email: null,
                name: 'link001',
                current_password: null,
                password: null,
                password_confirmation: null,
                is_notified: true,
            },
            errors: {
                'form.email': [],
                'form.current_password': [],
                'form.password_confirmation': [],
                'form.password': [],
            },
            rules: {
                ...vueRulesMessages,
            },
            validate: false,
        };
    },
    mounted() {
        this.prepareComponent();
    },
    watch: {
        form: {
            handler() {
                this.validate = true;

                if (!this.form.password) {
                    this.form.password_confirmation = this.form.password;
                }
            },
            deep: true,
        },
        apiErrors: {
            handler() {
                this.validate = true;
            },
            deep: true,
        },
        user() {
            if (this.user.attributes) {
                this.form.email = this.user.attributes.email;
                this.form.name = this.user.attributes.name;
                this.form.is_notified = this.user.attributes.is_notified;
            }
        },
        validatioForm(newValue) {
            if (newValue === false) {
                return;
            }

            this.validate = false;
            this.vueFrontErrors();
        },
    },
    computed: {
        ...mapGetters('users', {
            user: 'current',
            apiErrors: 'errors',
        }),
        hasNewPassword() {
            return this.form.password;
        },
        validatioForm() {
            return this.validate;
        },
    },
    methods: {
        vueFrontErrors: vueFrontErrors,
        async prepareComponent() {
            this.isLoading = true;
            await this.fetch();
            this.isLoading = false;
        },
        ...mapActions('users', {
            fetch: 'currentFetch',
            destroy: 'currentDestroy',
            update: 'currentUpdate',
        }),
        async onSubmit() {
            this.$v.form.$touch();
            let invalid = this.$v.form.$invalid;
            this.validate = true;

            if (invalid) {
                return;
            }

            this.isProcessing = true;

            const formUser = {
                data: {
                    type: 'users',
                    attributes: {
                        is_notified: this.form.is_notified,
                        // name: this.form.name,
                        email: this.form.email,
                        password: this.form.password,
                        password_confirmation: this.form.password_confirmation,
                        current_password: this.form.current_password,
                    },
                },
            };
            await Promise.allSettled([this.update(formUser)]);

            this.isProcessing = false;
        },
    },
};
</script>
