<template>
<div>
  <span class="float-right">
    <v-dialog v-model="value" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar>
          <v-btn icon @click="onClose()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{
                            $t('round_details')
                        }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="onClose()" :disabled="isProcessing">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn color="accent" @click="onSubmit" :loading="isProcessing" :disabled="!form.project_id">
              {{ $t('round_create') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row align="center" justify="center">
              <v-col cols="7">
                <v-alert border="bottom" colored-border type="warning" elevation="2" v-if="round">
                  {{ $t('vacancy_edition_warning') }}
                </v-alert>
                <v-card>
                  <!--Comencemos-->
                  <v-card-title>
                    {{ $t('lets_start') }}
                  </v-card-title>
                  <v-card-subtitle> </v-card-subtitle>
                  <v-card-text>
                    <!--Company-->
                    <v-autocomplete 
                        :items="projects" 
                        v-model="form.project_id" 
                        item-text="attributes.name" 
                        :disabled="this.round !== null" 
                        item-value="id" 
                        :error-messages="errors['form.project_id']" 
                        @input="$v.form.project_id.$touch()" 
                        @blur="$v.form.project_id.$touch()" 
                        :label="`${$t('vacancy_company')} *`">
                    </v-autocomplete>
                    <!--/Company-->
                    <!--Amount-->
                    <v-text-field 
                        v-model="form.amount" 
                        @input="$v.form.amount.$touch()" 
                        @blur="$v.form.amount.$touch()" 
                        :error-messages="errors['form.amount']" 
                        type="number" 
                        label="Monto" 
                        :prefix="$t('currency_prefix')" 
                        :suffix="$t('currency_suffix')">
                    </v-text-field>
                    <!--/Amount-->
                    <!--Minimum-->
                    <v-text-field 
                        v-model="form.minimum" 
                        @input="$v.form.minimum.$touch()" 
                        @blur="$v.form.minimum.$touch()" 
                        :error-messages="errors['form.minimum']" 
                        type="number" 
                        label="Inversión mínima" 
                        :prefix="$t('currency_prefix')" 
                        :suffix="$t('currency_suffix')">
                    </v-text-field>
                    <!--/Minimum-->
                    <!--/Type-->
                    <p>{{ $t('round_type') }}</p>
                    <v-radio-group v-model="form.type_id" row>
                      <v-radio
                        label="Acciones"
                        :value="1"
                      ></v-radio>
                      <v-radio
                        label="Rendimiento"
                        :value="2"
                      ></v-radio>
                    </v-radio-group>
                    <!--/Type-->
                    <!--Percent-->
                    <v-text-field 
                        v-model="form.percent" 
                        @input="$v.form.percent.$touch()" 
                        @blur="$v.form.percent.$touch()" 
                        :error-messages="errors['form.percent']" 
                        type="number" 
                        :label='$t("percent")'
                        suffix="%">
                    </v-text-field>
                    <!--/Percent-->    
                    <!--Start_date-->
                    <v-text-field 
                        v-model="form.start_date" 
                        @input="$v.form.start_date.$touch()" 
                        @blur="$v.form.start_date.$touch()" 
                        :error-messages="errors['form.start_date']" 
                        type="date" 
                        label="Fecha inicial">
                    </v-text-field>
                    <!--/Start_date-->
                    <!--End_date-->
                    <v-text-field 
                        v-model="form.end_date" 
                        @input="$v.form.end_date.$touch()" 
                        @blur="$v.form.end_date.$touch()" 
                        :error-messages="errors['form.end_date']" 
                        type="date" 
                        label="Fecha final">
                    </v-text-field>
                    <!--/End_date-->
                    <tiptap-vuetify 
                        v-model="form.description" 
                        :extensions="extensions" 
                    />
                  </v-card-text>

                  <!--Actions-->
                  <v-card-text>
                    <small>{{ $t('required_tooltip') }}</small>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="onClose" :disabled="isProcessing">
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn color="accent" @click="onSubmit" :loading="isProcessing" :disabled="!form.project_id">
                      {{ $t('round_create') }}
                    </v-btn>
                  </v-card-actions>
                  <!--/Actions-->
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';
import {
  validationMixin
} from 'vuelidate';
import {
  required,
  numeric,
  minValue
} from 'vuelidate/lib/validators';
import {
  vueFrontErrors,
  vueRulesMessages
} from '@/plugins/vuelidate';

import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'

export default {
  components: {
    TiptapVuetify
  },
  props: ['round', 'value'],
  mixins: [validationMixin],
  validations: {
    form: {
      project_id: {
        required: required,
      },
      amount: {
        minValue: minValue(1000),
        numeric: numeric,
        required: required,
      },
      minimum: {
        minValue: minValue(1000),
        numeric: numeric,
        required: required,
      },
      type_id: {
        required: required,
      },
      percent: {
        numeric: numeric,
        minValue: minValue(1),
      },
      start_date: {
        required: required,
      },
      end_date: {
        required: required,
      },
    },
  },
  data() {
    return {
      errors: {
        'form.id': [],
        'form.project_id': [],
        'form.amount': [],
        'form.minimum': [],
        'form.type_id': [],
        'form.percent': [],
        'form.start_date': [],
        'form.end_date': []
      },
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      datepickerStart: false,
      datepickerEnd: false,
      form: {
        id: null,
        project_id: null,
        amount: null,
        minimum: null,
        type_id: 1,
        percent: null,
        start_date: null,
        end_date: null,
        description: '<h2>Introducción</h2><p>Escribir breve introducción del estado actual de la empresa</p><p><br class="ProseMirror-trailingBreak"></p><h2>Objetivos de la ronda</h2><p>Escribir brevemente los el objetivo general del la ronda</p><ul><li><p>Objetivo 1</p></li><li><p>Objetivo 2</p></li></ul><p><br class="ProseMirror-trailingBreak"></p><h2>Objetivos del equipo</h2><p>Escribir brevemente los objetivos que cumplirá de cada miembro del equipo</p><ul><li><p>Team member 1</p><ul><li><p>Objetivo 1</p></li><li><p>Objetivo 2</p></li></ul></li><li><p>Team member 2</p><ul><li><p>Objetivo 1</p></li></ul></li></ul><p><br class="ProseMirror-trailingBreak"></p><h2>Burn rate</h2><p>Escribir el burn rate de la empresa, cuanto van a requerir gastar mes a mes y el desglose</p>',
      },
      baseForm: {
        id: null,
        project_id: null,
        amount: null,
        minimum: null,
        type_id: 1,
        percent: null,
        start_date: null,
        end_date: null,
        description: '<h2>Introducción</h2><p>Escribir breve introducción del estado actual de la empresa</p><p><br class="ProseMirror-trailingBreak"></p><h2>Objetivos de la ronda</h2><p>Escribir brevemente los el objetivo general del la ronda</p><ul><li><p>Objetivo 1</p></li><li><p>Objetivo 2</p></li></ul><p><br class="ProseMirror-trailingBreak"></p><h2>Objetivos del equipo</h2><p>Escribir brevemente los objetivos que cumplirá de cada miembro del equipo</p><ul><li><p>Team member 1</p><ul><li><p>Objetivo 1</p></li><li><p>Objetivo 2</p></li></ul></li><li><p>Team member 2</p><ul><li><p>Objetivo 1</p></li></ul></li></ul><p><br class="ProseMirror-trailingBreak"></p><h2>Burn rate</h2><p>Escribir el burn rate de la empresa, cuanto van a requerir gastar mes a mes y el desglose</p>',
      },
      isProcessing: false,
      isLoading: true,
      rules: {
        ...vueRulesMessages,
      },
      validate: false,
    };
  },
  mounted() {
    this.prepareComponent();
  },
  watch: {
    form: {
      handler() {
        this.validate = true;
      },
      deep: true,
    },
    apiErrors: {
      handler() {
        this.validate = true;
      },
      deep: true,
    },
    round() {
      this.form = {
        ...this.baseForm,
      };

      if (this.round) {
        if (this.round.attributes) {
          this.form = {
            ...this.round.attributes,
          };
        }
        if (this.round.relationships) {
          if (this.round.relationships.contract_types) {
            this.form.contract_type_id =
              this.round.relationships.contract_types.map(
                (contract_type) => {
                  return contract_type.id;
                }
              );
          }
        }
      }
    },
    validationForm(newValue) {
      if (newValue === false) {
        return;
      }

      this.validate = false;
      this.vueFrontErrors();
    },
  },
  computed: {
    ...mapGetters('rounds', {
      apiErrors: 'errors',
    }),
    ...mapGetters('projects', {
      projects: 'projectsAdmin',
    }),
    ...mapGetters({
      contract_types: 'contract_types',
      employment_types: 'employment_types',
      schedule_types: 'schedule_types',
      education_levels: 'education_levels',
      workspace_types: 'workspace_types',
      industries: 'industries',
      benefits: 'benefits',
      contracts_periods: 'contracts_periods',
    }),
    title() {
      return this.round === null ? 'Nueva ronda' : 'Editar ronda';
    },
    validationForm() {
      return this.validate;
    },
  },
  methods: {
    vueFrontErrors: vueFrontErrors,
    async prepareComponent() {
      this.isLoading = true;
      await this.fetchProjects();
      this.isLoading = false;
    },
    ...mapActions('rounds', {
      store: 'store',
      update: 'update',
    }),
    ...mapActions('projects', {
      fetchProjects: 'fetchAdmin',
    }),
    onClose() {
      this.$emit('input', false);
    },
    async onSubmit() {
      let success;
      this.isProcessing = true;
      if (this.round) {
        success = await this.update({
          id: this.round.id,
          data: {
            id: this.round.id,
            ...this.form,
          },
        });
      } else {
        success = await this.store({
          project_id: this.form.project_id,
          data: {
            ...this.form,
          },
        });
      }
      this.isProcessing = false;

      this.$emit('input', !success);
      this.$emit('stored', success);
    },
  },
};
</script>
