<template>
<div>
    <v-list-item @click="onClicked">
        <template>
            <app-list-item-logo :logo="item.attributes.logo"></app-list-item-logo>
            <v-list-item-content>
                <v-list-item-title v-text="item.attributes.name" class="text-h5"></v-list-item-title>
                <v-list-item-subtitle class="text--primary mb-2">
                    <a :href="item.attributes.website" target="_blank" @click.stop="" style="text-decoration: none">
                        {{ item.attributes.website }}
                    </a>
                </v-list-item-subtitle>
                <v-list-item-subtitle v-text="item.attributes.description"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn text color="primary" @click="onClicked">
                    {{ $t('detail') }}
                </v-btn>
            </v-list-item-action>
        </template>
    </v-list-item>
    <v-divider v-if="last"></v-divider>
</div>
</template>

<script>
import AppListItemLogo from '@/components/atoms/ListItemLogo';

export default {
    props: ['item', 'route', 'last'],
    data() {
        return {};
    },
    methods: {
        onClicked() {
            this.$router.push({
                name: this.route,
                params: {
                    project: this.item.id
                },
            });
        },
    },
    components: {
        AppListItemLogo,
    },
};
</script>
