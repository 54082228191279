<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs">
        <app-title-crumb :title="$t('my_investments')" :instructions="$t('my_investments_msg')"></app-title-crumb>
    </app-breadcrumbs>
    <v-container fluid>
        <v-skeleton-loader class="mx-auto" type="card-heading, card, actions" v-if="investments === null"></v-skeleton-loader>
        <app-empty-state v-else-if="investments.length === 0"></app-empty-state>
        <v-row dense v-else>
            <v-col v-for="investment in investments" :key="investment.id" :cols="4">
                <v-card class="mx-auto" outlined>
                    <v-list-item three-line>
                        <v-list-item-content>
                            <v-list-item-title class="headline mb-1">
                                {{ investmentTitle(investment) }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ investmentAmount(investment) | currency }} |
                                {{ investmentCreatedAt(investment) | date }} |
                                {{ investmentStatus(investment) }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <app-list-item-logo :logo="investmentLogo(investment)"></app-list-item-logo>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn text :to="{
                                    name: 'profile.investments.show',
                                    params: { investment: investment.id }
                                }">
                            <v-icon left>
                                mdi-details
                            </v-icon>
                            {{ $t('details') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
import AppTitleCrumb from "@/components/molecules/Breadcrumbs/Title";
import AppListItemLogo from "@/components/atoms/ListItemLogo";
import AppEmptyState from "@/components/atoms/EmptyState";

export default {
    data() {
        return {
            isLoading: false,
            breadcrumbs: [{
                    text: this.$t('home'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "home"
                    }
                },
                {
                    text: this.$t('dashboard'),
                    disabled: false,
                    exact: true,
                    to: {
                        name: "dashboard"
                    }
                },
                {
                    text: "",
                    disabled: true,
                    to: {
                        name: "home"
                    }
                }
            ]
        };
    },
    computed: {
        ...mapGetters("investments", {
            investments: "investments"
        })
    },
    mounted() {
        this.prepareComponent();
    },
    methods: {
        async prepareComponent() {
            this.isLoading = true;
            await this.fetchInvestments();
            this.isLoading = false;
        },
        ...mapActions("investments", {
            fetchInvestments: "fetch"
        }),
        investmentLogo(investment) {
            try {
                let logo = investment.relationships.round.relationships.project.attributes.logo;
                return logo ? logo: this.placeholders.company;
            } catch (error) {
                return this.placeholders.company;
            }
        },
        investmentTitle(investment) {
            return investment.relationships.round.relationships.project.attributes.name;
        },
        investmentProject(investment) {
            return investment.relationships.round.relationships.project.attributes.name;
        },
        investmentLocation(investment) {
            return investment.relationships.round.attributes.location;
        },
        investmentCreatedAt(investment) {
            return investment.relationships.round.attributes.created_at;
        },
        investmentAmount(investment) {
            return investment.attributes.amount;
        },
        investmentStatus(investment) {
            return investment.relationships.status.attributes.name;
        },
    },
    components: {
        AppBreadcrumbs,
        AppTitleCrumb,
        AppListItemLogo,
        AppEmptyState
    }
};
</script>
