<template>
<div>
    <app-breadcrumbs :breadcrumbs="breadcrumbs"></app-breadcrumbs>
    <v-container fluid>
        <v-row align="center" justify="center">
            <v-col md="3">{{ $t('logging_out') }}</v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import AppBreadcrumbs from "@/components/organisms/Breadcrumbs";
export default {
    data() {
        return {
            breadcrumbs: [{
                text: this.$t('home'),
                disabled: false,
                to: {
                    name: "home"
                },
                exact: true
            }]
        };
    },
    mounted() {
        this.logout();
    },
    methods: {
        ...mapActions("auth", {
            logout: "logout"
        })
    },
    components: {
        AppBreadcrumbs
    }
};
</script>
