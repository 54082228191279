<template>
<v-card>
  <v-card-title class="headline">
    {{ title }}
  </v-card-title>
  <v-card-subtitle>
    {{ subtitle }}
  </v-card-subtitle>
  <v-card-text>
    <v-skeleton-loader v-if="skeleton" class="mx-auto" type="card-heading, card, actions"></v-skeleton-loader>
    <form v-else>
      <v-text-field v-model="form.name" @input="$v.form.name.$touch()" @blur="$v.form.name.$touch()" :error-messages="nameErrors" :label="$t('name')"></v-text-field>
      <v-text-field v-model="form.website" @input="$v.form.website.$touch()" @blur="$v.form.website.$touch()" :error-messages="websiteErrors" :label="$t('web_site')"></v-text-field>
      <v-select :items="categoriesOptions" v-model="form.business_area" @input="$v.form.business_area.$touch()" @blur="$v.form.business_area.$touch()" :error-messages="businessAreaErrors" :label="$t('line_of_business')"></v-select>
      <vuetify-google-autocomplete id="map" v-model="form.location" @input="$v.form.location.$touch()" @blur="$v.form.location.$touch()" :error-messages="locationErrors" :label="$t('location')" v-bind:disabled="false" v-on:placechanged="getAddressData">
      </vuetify-google-autocomplete>

      <tiptap-vuetify v-model="form.description" :extensions="extensions" class="my-5"
        @input="$v.form.description.$touch()" @blur="$v.form.description.$touch()" :error-messages="descriptionErrors" :label="$t('briefly_describe_the_company')"
      />

      <div class="text-right">
        <v-btn color="accent" :loading="isSaving" :disabled="isProcessing" @click="onSave">
          {{ $t('save') }}
        </v-btn>
      </div>
    </form>
    <app-continue v-model="dialog"></app-continue>
    <app-continue-payment v-model="dialogPayment"></app-continue-payment>
  </v-card-text>
</v-card>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters
} from 'vuex';
import {
  validationMixin
} from 'vuelidate';
import {
  required
} from 'vuelidate/lib/validators';
import {
  apiErrors
} from '@/util.js';

import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import AppContinue from '@/components/molecules/Continue';
import AppContinuePayment from '@/components/molecules/ContinuePayment';

import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'

export default {
  props: ['project', 'title', 'subtitle'],
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required: required,
      },
      website: {
        required: required,
      },
      business_area: {
        required: required,
      },
      location: {
        required: required,
      },
      description: {
        required: required,
      },
    },
  },
  data() {
    return {
      categoriesOptions: ['Cargando...'],
      dialog: false,
      dialogPayment: false,
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
    };
  },
  mounted() {
    this.prepareComponent();
  },
  watch: {
    categories: {
      handler: function () {
        this.categoriesOptions = this.categories.map((cat) => {
          return {
            value: cat.id,
            text: cat.attributes.name,
          };
        });
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      categories: 'industries',
    }),
    ...mapGetters('auth', {
      isAuth: 'isAuth',
    }),
    ...mapState('projects', {
      stash: 'stash',
      errors: 'errors',
    }),
    ...mapGetters('projects', {
      isSaving: 'isSaving',
      isProcessing: 'isProcessing',
    }),
    ...mapGetters('stripe', {
      isSubscribed: 'subscribed',
    }),
    nameErrors() {
      return this.createErrors('name', {
        required: this.$t('validation_required'),
      });
    },
    websiteErrors() {
      return this.createErrors('website', {
        required: this.$t('validation_required'),
      });
    },
    businessAreaErrors() {
      return this.createErrors('business_area', {
        required: this.$t('validation_required'),
      });
    },
    locationErrors() {
      return this.createErrors('location', {
        required: this.$t('validation_required'),
      });
    },
    descriptionErrors() {
      return this.createErrors('description', {
        required: this.$t('validation_required'),
      });
    },
    form() {
      if (this.stash) {
        return this.stash;
      }

      if (this.project) {
        return this.project.attributes;
      }

      return {
        address: '',
        business_area: '',
        capital: '',
        description: '',
        help: '',
        location: '',
        name: '',
        problem: '',
        share: 30,
        solution: '',
        website: '',
      };
    },
    skeleton() {
      return this.project === null;
    },
  },
  methods: {
    prepareComponent() {
      this.fetchIndustries();
    },
    ...mapActions({
      fetchIndustries: 'fetchIndustries',
    }),
    ...mapActions('projects', {
      store: 'store',
      update: 'update',
      stashProject: 'stash',
    }),
    createErrors(key, rules) {
      const errors = apiErrors(key, this.errors);
      if (!this.$v.form[key].$dirty && errors.length == 0) return errors;
      for (let rule in rules) {
        !this.$v.form[key][rule] && errors.push(rules[rule]);
      }
      return errors;
    },
    async onSave() {
      if (this.isAuth) {
        if (this.project) {
          await this.update({
            id: this.project.id,
            ...this.form,
          });
        } else {
          const project = await this.store(this.form);
          this.$router
            .replace({
              name: 'admin.projects.show',
              params: {
                project: project.id,
              },
            })
            .catch((err) => console.log(err.message));
        }
      } else {
        this.dialog = true;
        this.stashProject(this.form);
      }
    },
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData: function (addressData) {
      this.form.address = addressData;
    },
  },
  components: {
    VuetifyGoogleAutocomplete,
    AppContinue,
    AppContinuePayment,
    TiptapVuetify
  },
};
</script>
