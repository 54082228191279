<template>
<v-card flat :loading="isLoading">
    <v-card-title>
        {{ $t('beneficiary_data') }}
    </v-card-title>
    <v-card-subtitle>
        {{ $t('beneficiary_data_msg') }}
    </v-card-subtitle>
    <v-form>
        <v-card-text>
            <v-row align="center">
                <v-col class="d-flex py-0" cols="12" sm="12">
                    <v-text-field v-model="form.name" :error-messages="errors['form.name']" :label="$t('names')"></v-text-field>
                </v-col>
                <v-col class="d-flex py-0" cols="12" sm="12">
                    <v-text-field v-model="form.last_name" :error-messages="errors['form.last_name']" :label="$t('middle_name')"></v-text-field>
                </v-col>
                <v-col class="d-flex py-0" cols="12" sm="12">
                    <v-text-field v-model="form.second_last_name" :error-messages="errors['form.second_last_name']" :label="$t('last_name')"></v-text-field>
                </v-col>
                <v-col class="d-flex py-0" cols="12" sm="12">
                    <v-text-field v-model="form.email" :error-messages="errors['form.email']" :label="$t('email')"></v-text-field>
                </v-col>
                <v-col class="d-flex py-0" cols="12" sm="12">
                    <v-dialog ref="dialog" v-model="modal" :return-value.sync="form.birthdate" persistent width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="form.birthdate" :label="$t('birthdate')" readonly v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="form.birthdate" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="modal = false">{{ $t('cancel') }}</v-btn>
                            <v-btn text color="primary" @click="$refs.dialog.save(form.birthdate)">{{ $t('ok') }}</v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col class="d-flex py-0" cols="12" sm="12">
                    <v-select :items="beneficiary_types" v-model="form.beneficiary_type_id" :error-messages="errors['form.beneficiary_type_id']" item-text="attributes.description" item-value="id" return-object :label="$t('beneficiary_relationship')"></v-select>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-title>
            {{ $t('beneficiary_address') }}
        </v-card-title>
        <v-card-subtitle> </v-card-subtitle>
        <v-card-text>
            <v-row align="center">
                <v-col class="d-flex py-0" cols="12" sm="12">
                    <v-text-field v-model="form.line_1" :error-messages="errors['form.line_1']" :label="$t('street')" :placeholder="$t('street_placeholder')"></v-text-field>
                </v-col>
                <v-col class="d-flex py-0" cols="12" sm="6">
                    <v-text-field v-model="form.line_2" :error-messages="errors['form.line_2']" :label="$t('street_number')" :placeholder="$t('street_number_placeholder')"></v-text-field>
                </v-col>
                <v-col class="d-flex py-0" cols="12" sm="6">
                    <v-text-field v-model="form.line_3" :error-messages="errors['form.line_3']" :label="$t('internal_number')" :placeholder="$t('optional_placeholder')"></v-text-field>
                </v-col>
                <v-col class="d-flex pt-0" cols="12" sm="12">
                    <v-text-field v-model="form.line_4" :error-messages="errors['form.line_4']" :label="$t('suburb')"></v-text-field>
                </v-col>
                <v-col class="d-flex py-0" cols="12" sm="6">
                    <v-text-field v-model="form.cp" :error-messages="errors['form.cp']" :label="$t('postal_code')" :placeholder="$t('postal_code_placeholder')"></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12">
                    <v-text-field v-model="form.city" :error-messages="errors['form.city']" :label="$t('city')" :placeholder="$t('city_placeholder')"></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12">
                    <v-select :items="states" v-model="form.state" :error-messages="errors['form.state']" item-text="attributes.name" item-value="id" return-object :label="$t('state')"></v-select>
                </v-col>
            </v-row>
        </v-card-text>
    </v-form>
    <v-card-text>
        <v-btn color="accent" class="mr-4" @click="onSubmit" :loading="isProcessing">
            {{ $t('save') }}
        </v-btn>
    </v-card-text>
</v-card>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex';
import {
    validationMixin
} from 'vuelidate';
import {
    required,
    minLength,
    maxLength,
    numeric,
} from 'vuelidate/lib/validators';
import {
    vueFrontErrors,
    vueRulesMessages
} from '../../plugins/vuelidate';

export default {
    mixins: [validationMixin],
    validations: {
        form: {
            name: {
                required,
            },
            last_name: {
                required,
            },
            second_last_name: {
                required,
            },
            email: {
                required,
            },
            birthdate: {
                required,
            },
            beneficiary_type_id: {
                required,
            },
            line_1: {
                required,
            },
            line_2: {
                numeric,
                required,
            },
            line_3: {
                numeric,
            },
            line_4: {
                required,
            },
            city: {
                required,
            },
            state: {
                required,
            },
            cp: {
                required: required,
                numeric: numeric,
                minLength: minLength(5),
                maxLength: maxLength(5),
            },
        },
    },
    data() {
        return {
            isLoading: false,
            isProcessing: false,
            modal: false,
            form: {
                name: null,
                last_name: null,
                second_last_name: null,
                email: null,
                birthdate: null,
                beneficiary_type_id: null,
                line_1: null,
                line_2: null,
                line_3: null,
                line_4: null,
                cp: null,
                city: null,
                state: null,
            },
            errors: {
                'form.name': [],
                'form.last_name': [],
                'form.second_last_name': [],
                'form.email': [],
                'form.birthdate': [],
                'form.beneficiary_type_id': [],
                'form.line_1': [],
                'form.line_2': [],
                'form.line_3': [],
                'form.line_4': [],
                'form.cp': [],
                'form.city': [],
                'form.state': [],
            },
            rules: {
                ...vueRulesMessages,
            },
            validate: false,
        };
    },
    mounted() {
        this.prepareComponent();
    },
    watch: {
        form: {
            handler() {
                this.validate = true;
            },
            deep: true,
        },
        apiErrors: {
            handler() {
                this.validate = true;
            },
            deep: true,
        },
        beneficiary() {
            if (this.beneficiary.attributes) {
                this.form.name = this.beneficiary.attributes.name;
                this.form.last_name = this.beneficiary.attributes.last_name;
                this.form.second_last_name =
                    this.beneficiary.attributes.second_last_name;
                this.form.email = this.beneficiary.attributes.email;
                this.form.birthdate = this.beneficiary.attributes.birthdate;
                this.form.beneficiary_type_id = this.beneficiary_types.find(
                    (type) =>
                    type.id ==
                    this.beneficiary.attributes.beneficiary_type_id
                );
                this.form.line_1 = this.beneficiary.attributes.line_1;
                this.form.line_2 = this.beneficiary.attributes.line_2;
                this.form.line_3 = this.beneficiary.attributes.line_3;
                this.form.line_4 = this.beneficiary.attributes.line_4;
                this.form.cp = this.beneficiary.attributes.cp;
                this.form.city = this.beneficiary.attributes.city;
                this.form.state = this.states.find(
                    (state) => state.id == this.beneficiary.attributes.state
                );
            }
        },
        validationForm(newValue) {
            if (newValue === false) {
                return;
            }
            this.validate = false;
            this.vueFrontErrors();
        },
    },
    computed: {
        ...mapGetters('beneficiary', {
            states: 'states',
            beneficiary_types: 'beneficiary_types',
            beneficiary: 'beneficiary',
            apiErrors: 'errors',
        }),
        validationForm() {
            return this.validate;
        },
        attrBeneficiaryTypes() {
            return this.form.beneficiary_type_id &&
                this.form.beneficiary_type_id.attributes ?
                this.form.beneficiary_type_id.id :
                this.form.beneficiary_type_id;
        },
        attrState() {
            return this.form.state && this.form.state.attributes ?
                this.form.state.id :
                this.form.state;
        },
    },
    methods: {
        async prepareComponent() {
            this.isLoading = true;
            await Promise.allSettled([
                this.fetchStates(),
                this.fetchBeneficiaryTypes(),
            ]);
            await this.fetchBeneficiary();
            this.isLoading = false;
        },
        ...mapActions('beneficiary', {
            fetchStates: 'fetchStates',
            fetchBeneficiaryTypes: 'fetchBeneficiaryTypes',
            fetchBeneficiary: 'fetchBeneficiary',
            storeBeneficiary: 'storeBeneficiary',
        }),
        vueFrontErrors: vueFrontErrors,
        async onSubmit() {
            this.$v.form.$touch();
            let invalid = this.$v.form.$invalid;
            this.validate = true;
            if (invalid) {
                return;
            }
            this.isProcessing = true;
            const form = {
                data: {
                    type: 'personal_beneficiary',
                    attributes: {
                        name: this.form.name,
                        last_name: this.form.last_name,
                        second_last_name: this.form.second_last_name,
                        email: this.form.email,
                        birthdate: this.form.birthdate,
                        beneficiary_type_id: this.attrBeneficiaryTypes,
                        line_1: this.form.line_1,
                        line_2: this.form.line_2,
                        line_3: this.form.line_3,
                        line_4: this.form.line_4,
                        cp: this.form.cp,
                        city: this.form.city,
                        state: this.attrState,
                    },
                },
            };
            await Promise.allSettled([this.storeBeneficiary(form)]);
            this.isProcessing = false;
        },
    },
};
</script>
